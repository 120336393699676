import app01 from '../../images/vr/applications/app01.png'
import app02 from '../../images/vr/applications/app02.png'
import app03 from '../../images/vr/applications/app03.png'
import app04 from '../../images/vr/applications/app04.png'
import app05 from '../../images/vr/applications/app05.png'
import app06 from '../../images/vr/applications/app06.png'
import app07 from '../../images/vr/applications/app07.png'
import app08 from '../../images/vr/applications/app08.png'
import app09 from '../../images/vr/applications/app09.png'
import app10 from '../../images/vr/applications/app10.png'
import CMAFBADK3A from '../../images/vr/applications/product/CMA-FBA-DK3A.png'
import CMAFBCAR4A from '../../images/vr/applications/product/CMA-FBC-AR4A.png'
import CMAFBMCO4A from '../../images/vr/applications/product/CMA-FBM-CO4A.png'
import CMDFSCCO1A from '../../images/vr/applications/product/CMD-FSC-CO1A.png'
import CMDFSCCOGA from '../../images/vr/applications/product/CMD-FSC-COGA.png'
import CMDT5CCO1A from '../../images/vr/applications/product/CMD-T5C-CO1A.png'
import CMFARA02 from '../../images/vr/applications/product/CMF-AR-A02.png'
import CMFARA03 from '../../images/vr/applications/product/CMF-AR-A03.png'
import CMWFCCCO1B from '../../images/vr/applications/product/CMW-FCC-CO1B.png'
import CUD7GF1A from '../../images/vr/applications/product/CUD7GF1A.png'
import CUD7GF1B from '../../images/vr/applications/product/CUD7GF1B.png'
import CUN66A1B from '../../images/vr/applications/product/CUN66A1B.png'

const ApplicationItem = {
    common: {
        detail: 'Product Detail',
        btnInfo: '* Check the details by selecting a button.',
        showProduct: 'Show VIOLEDS Products',
        violedsProduct: 'VIOLEDS PRODUCT',
        moduleInfo: '* The product can be customized.'
    },
    product: {
        refrigerator: {
            name: 'Refrigerator',
            type: ['물 살균', '공기 살균', '탈취'],
            img: app05,
            desc: "Violeds technology eliminates odor and bacteria that can put our family and children's health at risk, keeping the refrigerator's interior pleasant and hygienic.",
            popupThumb: '/images/vr/thumb01.png',
            videoPopupDesc: 'Foods stored in the refrigerator generate low-temperature bacteria\n(listeria, blue mold), kimchi odor (methyl mercaptan),\n and fishy odor (trimethylamine).',
            videoPlayWord: 'Sterilizing',
            skipSecond: 33.20,
            slidePosition: [{
                top: '53%',
                left: '7%',
            }, {
                top: '28%',
                left: '14%',
            }, {
                top: '10%',
                left: '40%',
            }],
            slide: [{
                title: 'Refrigerator Ice Maker',
                desc: '99.9% of the various viruses and bacteria that cause food poisoning are disinfected, including Listeria monocytogenes, which grow in the sub-zero environment inside ice makers at a temperature of -20°C.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }, {
                title: 'Refrigerator Dispenser',
                desc: 'Filtered water flowing through the dispenser can easily become contaminated through air contact with viruses and bacteria. Violeds technology disinfects 99.9% of all viruses and bacteria to provide clean water.',
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: 'Refrigerator Interior Deodorization',
                desc: 'Over 90% of Psychrophilic bacteria (Listeria, green mold), kimchi odors (methyl mercaptan), and fish odors (trimethylamine) are eliminated to maintain a pleasant refrigerator environment.',
                pkg: [{
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMF-AR-A02',
                    img: CMFARA02,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A02.pdf'
                }, {
                    name: 'CMF-AR-A03',
                    img: CMFARA03,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A03.pdf'
                }]
            }],
        }, 
        airConditioner: {
            name: 'Air conditioner',
            type: ['공기 살균'],
            img: app02,
            desc: 'With a proven sterilization efficiency of 99.9% per second for the coronavirus, Violeds technology inhibits the growth of viruses and bacteria in the vaporizer through which indoor air is cooled and circulated.',
            popupThumb: '/images/vr/thumb02.png',
            videoPopupDesc: 'Air conditioner while operating the air conditioner can cause harmful bacteria such as Escherichia coli, Staphylococcus aureus,\nand Staphylococcus epidermis in the filter and fan.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 22.20,
            slidePosition: [{
                top: '20%',
                left: '50%',
            }, {
                top: '31%',
                left: '37%',
            }],
            slide: [{
                title: 'Air Conditioner Vaporizer',
                desc: 'When viruses and bacteria carried by air flowing into the air conditioner reach the surface of the vaporizer, Violeds technology almost totally destroys all inflowing viruses and bacteria.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: 'Air Conditioner Fan',
                desc: 'When viruses and bacteria carried by air flowing into the interior of the air conditioner reach the surface of the fan, Violeds destroys over 99% of viruses and bacteria to minimize our contact with harmful air.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        washingMachine: {
            name: 'Washing machine',
            type: ['물 살균', '탈취'],
            img: app01,
            desc: 'Washing machines installed in environments with high humidity easily allow for the growth of bacteria and mold. Once contaminated, bacteria and mold are difficult to eliminate with regular washing machine cleaner products. However, Violeds inhibits the growth of bacteria and mold by disinfecting them before they have a chance to grow, keeping the washing machine clean and free of contamination.',
            popupThumb: '/images/vr/thumb03.png',
            videoPopupDesc: 'After washing, moisture and debris remaining in the outer container of the washing tank cause odors, various bacteria, and fungi to reproduce.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 13.50,
            slidePosition: [{
                top: '26%',
                left: '18%',
            }],
            slide: [{
                title: 'Water Machine Outer Tub',
                desc: 'Violeds technology inhibits the growth of bacteria and mold resulting from accumulations of detergent residue and limescale, keeping you far from the risk of skin irritations and allergies.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        }, 
        airpurifier: {
            name: 'Air Purifier',
            type: ['공기 살균', '탈취'],
            img: app10,
            desc: 'Airborne viruses and bacteria are usually filtered by HEPA filters, but a significant amount remains unsterilized, allowing them to propagate inside the filter. With a proven sterilization efficiency of 99.9% per second for the delta variant coronavirus, Violeds technology disinfects viruses and bacteria that flow inside through droplets on the surface of filters.',
            popupThumb: '/images/vr/thumb04.png',
            videoPopupDesc: 'Viruses in the air introduced into the air purifier from the outside remain in the filter, and are discharged to the outside again.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 16,
            slidePosition: [{
                top: '7%',
                left: '35%',
            }, {
                top: '51%',
                left: '36%',
            }],
            slide: [{
                title: 'Air Purifier Fan',
                desc: "When harmful viruses and bacteria carried by air flowing into the air purifier make contact with the fan's surface, they are destroyed by the powerful sterilization of Violeds.",
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: 'Air Purifier Filter',
                desc: 'To provide cleaner air, viruses and bacteria on the surfaces of filters inside air purifiers are eliminated one additional time with the powerful sterilization of Violeds.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        waterPurifier: {
            name: 'Water Purifier',
            type: ['물 살균'],
            img: app03,
            desc: 'From the water faucet to the ice room, the impeccable sterilization of Violeds technology destroys 99% of bacteria without leaving any persistent pollutants, providing healthy water and ice.',
            popupThumb: '/images/vr/thumb05.png',
            videoPopupDesc: 'Since the inside of the water purifier is always filled with water,\nsecondary contamination can occur due to bacteria in the water.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 13.20,
            slidePosition: [{
                top: '27%',
                left: '14%',
            }, {
                top: '46%',
                left: '46%',
            }, {
                top: '24%',
                left: '69%',
            }],
            slide: [{
                title: 'Water Purifier Filtration Faucet',
                desc: "As soon as filtered water from the water purifier's main body flows out of the faucet and makes contact with air, the water can become contaminated with viruses and bacteria. 99% of these viruses and bacteria are destroyed with Violeds technology.",
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: 'Water Purification Pipe, \nFlowing Water Sterilization',
                desc: "Violeds provides clean water by preventing secondary contamination from bacteria in the filter's stagnant water and disinfecting 99.9% of all viruses and bacteria that were not filtered by the filter.",
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-COGA',
                    img: CMDFSCCOGA,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-COGA.pdf'
                }]
            }, {
                title: 'Ice Room',
                desc: '99.9% of the various viruses and bacteria that cause food poisoning are sterilized, including Listeria monocytogenes, which grow in the sub-zero environment inside ice makers at a temperature of -20°C.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        dishwasher: {
            name: 'Dish wash machine',
            type: ['물 살균', '탈취'],
            img: app06,
            desc: 'Violeds saves more water by disinfecting the water used in dishwashers, prevents the secondary contamination of washed dishes, and eliminates unpleasant odors by preventing the decay of food accumulated during the dish washing process.',
            popupThumb: '/images/vr/thumb06.png',
            videoPopupDesc: 'Food and moisture remaining in the food sieve after dishwashing is a good environment for bacteria to grow.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 20.20,
            slidePosition: [{
                top: '30%',
                left: '20%',
            }, {
                top: '68%',
                left: '40%',
            }],
            slide: [{
                title: 'Dish Tray/Dish Rack',
                desc: 'Violeds technology creates an environment inside dishwashers that is impossible for the survival of viruses and bacteria, keeping the washed dishes clean.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: 'Food Strainer',
                desc: 'Violeds technology eliminates unpleasant odors by preventing the decay of food accumulated during the washing process.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        },
        vacuumCleaner: {
            name: 'Vacuum cleaner',
            type: ['물 살균'],
            img: app08,
            desc: 'Violeds technology disinfects the viruses and bacteria in accumulated dust, preventing secondary infections that can recontaminate the cleaned area.',
            popupThumb: '/images/vr/thumb07.png',
            videoPopupDesc: 'Dust collectors inside vacuum cleaners continue to accumulate dust,\nand brush/roller that directly touch the floor can reproduce bacteria and easily contaminate them.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 17,
            slidePosition: [{
                top: '65%',
                left: '24%',
            }, {
                top: '71%',
                left: '59%',
            }],
            slide: [{
                title: 'Dust Bin',
                desc: "Violeds' sterilization technology prevents secondary contamination by inhibiting 99.9% of viral and bacterial growth inside the dust bin.",
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: 'Vacuum Cleaner Brush/Roller',
                desc: 'Violeds disinfects the surfaces of brushes and rollers that directly contact the floor to prevent secondary contamination, while also removing the viruses and bacteria on floor surfaces, helping to maintain a clean and pleasant environment.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        },
        steamCloset: {
            name: 'Clothes management',
            type: ['탈취', '공기 살균'],
            img: app07,
            desc: 'Violeds keeps clothes fresh and clean by breaking down and removing unpleasant odors that are deeply embedded and difficult to remove with steam cleaning.',
            popupThumb: '/images/vr/thumb08.png',
            videoPopupDesc: 'Bacteria on clothing from outside activities cause odors.\nThis bacterium can be difficult to remove with just the steam of\nthe Clothes management.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 23.6,
            slidePosition: [{
                top: '4%',
                left: '36%',
            }],
            slide: [{
                title: 'Closet Upper Area',
                desc: '99.9% of unpleasant odors such as tobacco odors (pyridine), sweat odors (isovaleric acid), and meat odors (valeraldehyde) are eliminated, keeping clothes fresh and clean.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMA-FBM-CO4A',
                    img: CMAFBMCO4A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBM-CO4A.pdf'
                }, {
                    name: 'CMA-FBA-DK3A',
                    img: CMAFBADK3A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBA-DK3A.pdf'
                }]
            }]
        },
        humidifier: {
            name: 'Humidifier',
            type: ['물 살균'],
            img: app09,
            desc: 'Even the clean, purified water in a humidifier can become contaminated with common bacteria by being exposed to air for more than one day, and these bacteria can be transmitted as the water inside the tank is released into the area. Violeds technology inhibits the growth of common bacteria and helps to keep the water tank clean and free of contamination.',
            popupThumb: '/images/vr/thumb09.png',
            videoPopupDesc: 'The humid environment inside the humidifier can cause biofilm formation, which can lead to the growth of bacteria.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 13.15,
            slidePosition: [{
                top: '38%',
                left: '65%',
            }],
            slide: [{
                title: 'Water Tank',
                desc: 'Free of chemical substances, Violeds technology disinfects 99.9% of viruses and bacteria in the stagnant water of the water tanks of humidifiers, keeping a clean and safely humidified environment, while also allowing for easy cleaning and maintenance by inhibiting biofilm formation.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        bidet: {
            name: 'Bidet',
            type: ['물 살균'],
            img: app04,
            desc: 'Violeds disinfects the primary source of contamination in restrooms, toilets, and the most vulnerable of all to contamination, bidet nozzles, allowing for a clean and carefree use of the restroom.',
            popupThumb: '/images/vr/thumb10.png',
            videoPopupDesc: 'The bidet nozzle is attached to the toilet, where various harmful bacteria can easily grow, and it is difficult to clean it yourself.',
            videoPlayWord: 'Sterilizing',
            skipSecond: 16,
            slidePosition: [{
                top: '53%',
                left: '45%',
            }],
            slide: [{
                title: 'Bidet Nozzle',
                desc: "Violeds' sterilization technology disinfects hard-to-clean bidet nozzles against almost 99.9% of various bacteria through direct irradiation.",
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-T5C-CO1A',
                    img: CMDT5CCO1A,
                    detail: 'http://www.seoulviosys.com/en/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-T5C-CO1A.pdf'
                }]
            }]
        }
    }
}

export default ApplicationItem