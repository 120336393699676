import React, { useRef, useState, useEffect } from 'react'
import styled, {keyframes} from 'styled-components';
import { Link, withRouter } from 'react-router-dom'
import First_Showroom from '../components/First_Showroom';
import Second_Showroom from '../components/Second_Showroom';
import 'react-alice-carousel/lib/alice-carousel.css'

import PrevImg from '../images/showroom/page_prev.png'
import NextImg from '../images/showroom/page_next.png'
import Slider from "react-slick";

import Head from '../components/Head'

import mb_KitchenHome from '../images/showroom/kitchenimg_mb.png'
import mb_laundry from '../images/showroom/laundryimg_mb.png'
import mb_livingroom from '../images/showroom/livingroomimg_mb.png'
import mb_bathroom from '../images/showroom/bathroomimg_mb.png'
import mb_bedroom from '../images/showroom/bedroomimg_mb.png'
import mb_KitchenHall from '../images/showroom/kitchenhallimg_mb.png'
import mb_Hall from '../images/showroom/hallimg_mb.png'
import { useTranslation } from 'react-i18next'
import LogoImg from '../images/common/header_logo.png'


const ShowRoom = ({...props}) => {
    
    const { t } = useTranslation('application_vr')

    const slider = useRef(null);
    const sliderMB = useRef(null);
    const [roomText, setRoomText] = useState('Home');
    const [index, setIndex] = useState(-1);
    const [side, setSide] = useState(1);
    const [show, setShow] = useState(true);

    useEffect(() => {
      setIndex(0)
    }, [])

    const settings = {
      dots : false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows : false,
      draggable : false,
      ref : slider,
      centerMode: true,
      variableWidth: true,
      afterChange: async function(currentSlide) {
        setIndex(currentSlide);
        if(currentSlide === 0) {
          setRoomText('Home');
        } else if (currentSlide === 1) {
          setRoomText('Cafeteria');
        }
      },
      beforeChange : async function(oldIdx, newIdx) {
      }
  }

  const settingsMB = {
    dots : false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : false,
    draggable : false,
    ref : sliderMB,
    afterChange: async function(currentSlide) {
      setIndex(currentSlide)
      if(currentSlide === 0) {
        setRoomText('Home');
      } else if (currentSlide === 1) {
        setRoomText('Cafeteria');
      }
    },
    beforeChange : async function(oldIdx, newIdx) {
    }
}

  const onClickPrev = () => {
    if (slider) {
      slider.current.slickPrev();
    }
    if (sliderMB) {
      sliderMB.current.slickPrev();
    }
  }
  const onClickNext = () => {
    if (slider) {
      slider.current.slickNext();
    }
    if (sliderMB) {
      sliderMB.current.slickNext();
    }
  }

  let laundryRoom = t('data', { returnObjects: true }).home[3]
  let kitchen = t('data', { returnObjects: true }).home[1]
  let livingRoom = t('data', { returnObjects: true }).home[2]
  let bathRoom = t('data', { returnObjects: true }).home[4]
  let bedRoom = t('data', { returnObjects: true }).home[0]

  let rshall = t('data', { returnObjects: true }).cafeteria[0]
  let rskitchen = t('data', { returnObjects: true }).cafeteria[1]

    return (
        <Wrapper>
          <LogoInner>
            <Logoimg src={LogoImg}/>
          </LogoInner>
          <Head
          title={t('header.title')}
          description={t('header.desc')}
          keyword={t('header.keyword')}
          lang={props.currentLocale} />
          <TitleText>VR Showroom</TitleText>
          <SubTitleText>{t('guide.showroom')}</SubTitleText>
          <PlanImgpoint>
            <MapSlider {...settings}>
              <div>
                <SliderWrapper>
                  <First_Showroom index={side} point={index} roomname={roomText}/>
                </SliderWrapper> 
              </div>
              <div>
                <SliderWrapper>
                  <Second_Showroom index={side} point={index} roomname={roomText}/>
                </SliderWrapper>
              </div>
            </MapSlider>
          </PlanImgpoint>
          <LandScapeBox>
            <PlanImgpoint02>
              <Slider {...settingsMB}>
                <First_Showroom />
                <Second_Showroom />
              </Slider>
            </PlanImgpoint02>
            <RoomStatusInner>
              <PrevBtnBox status={index} onClick={e => onClickPrev()}>
                <BtnImg src={PrevImg}/> 
              </PrevBtnBox>
              <TextBox>
                <RomeName>{roomText}</RomeName>
              </TextBox>
              <NextBtnBox status={index} onClick={e => onClickNext()}>
                <BtnImg src={NextImg}/> 
              </NextBtnBox>
            </RoomStatusInner>
            <RoomBtnInner01 status={index}>
              <RoomInner>
                <Link to={kitchen.url}>
                <RoomMbBg01>
                  <RoomText>Kitchen</RoomText>
                </RoomMbBg01>
                </Link>
                <Link to={laundryRoom.url}>
                <RoomMbBg02>
                  <RoomText>Laundry</RoomText>
                </RoomMbBg02>
                </Link>
                <Link to={bedRoom.url}>
                <RoomMbBg03>
                  <RoomText>Bedroom</RoomText>
                </RoomMbBg03>
                </Link>
                <Link to={livingRoom.url}>
                <RoomMbBg04>
                  <RoomText>Livingroom</RoomText>
                </RoomMbBg04>
                </Link>
                <Link to={bathRoom.url}>
                  <RoomMbBg05>
                    <RoomText>Bathroom</RoomText>
                  </RoomMbBg05>
                </Link>
              </RoomInner>
            </RoomBtnInner01>

            <RoomBtnInner02 status={index}>
              <RoomInner>
              <Link to={rskitchen.url}>
                <RoomMbBg06>
                  <RoomText>Kitchen</RoomText>
                </RoomMbBg06>
              </Link>
              <Link to={rshall.url}>
                <RoomMbBg07>
                  <RoomText>Hall</RoomText>
                </RoomMbBg07>
              </Link>
              </RoomInner>
            </RoomBtnInner02>
          </LandScapeBox>
            
        </Wrapper>
    )
}

const MapSlider = styled(Slider)`
  .slick-slide {
    transition : .5s;
    transform : scale(0.6);
    @media screen and (max-width: 900px) {
        transform : scale(1.0);
    }
  }

  .slick-center {
    transition : .5s;
    transform : scale(1.0);
  }
`

const SliderWrapper = styled.div`
`

const Wrapper = styled.div`
  position: relative;
  width : 100%;
  height : 100vh;
  display : flex;
  flex-direction : column;
  align-items : center;
  overflow-x : hidden;
  overflow-y : hidden;
  background-color: #000;

  @media screen and (max-width: 900px) {
    overflow-y : auto;
    height : auto;
    min-height : 100vh;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2f3542;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
      &::-webkit-scrollbar-track {
      background-color: grey;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }

`

const LogoInner = styled.div`
  width : 100%;
  height : 80px;
  display : flex;
  justify-content : center;
  align-items : center;
  @media screen and (max-width: 900px) and (orientation: portrait) {
    height : 68px;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    height : 40px;
  }
`

const Logoimg = styled.img`
   width: 120px;
   height: 32px;
   @media screen and (max-width: 900px) {
            width: 105px;
            height: auto;
   }
`

const TitleText = styled.div`
  margin-top : 40px;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.13;
  color: #fff;

  @media screen and (max-width: 900px) and (orientation: portrait) {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    }
  
    @media screen and (max-width: 900px) and (orientation: landscape) {
      display : none;
    }
`

const SubTitleText = styled.div`
  margin-top : 13px;
  margin-bottom : 23px;
  font-size: 14px;
  line-height: 1.57;
  color: #fff;
  text-align : center;
  white-space: pre-wrap;

  @media screen and (max-width: 900px) and (orientation: portrait) {
    margin-bottom : 48px;
    margin-top : 12px;
    font-size: 10px;
    line-height: 1.5;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
      display : none;
  }
`

const PlanImgpoint = styled.div`
  display : block;
  position : absolute;
  width :100%;
  top : 16%;

  @media screen and (max-width: 900px) {
    display : none;
  }
`

const PlanImgpoint02 = styled.div`
  display : none;
  @media screen and (max-width: 900px) and (orientation: portrait) {
    display : block;
    width : 100%;
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    display : block;
    width : 50%;
    /* background-color : red; */
  }
`

const RoomStatusInner = styled.div`
  @media screen and (max-width: 900px) and (orientation: portrait) {
    width : 90%;
    display : flex;
    justify-content : space-between;
    align-items : center;
    margin-top : 8px;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    position : absolute;
    bottom : 15%;
    left : 3%;
    z-index : 20;
    display : flex;
    align-items : center;
    justify-content : space-between;
    width : 45%;
    /* background-color : green; */
  }
`

const BtnImg = styled.img`
  width : 80px;
  height : 80px;
  @media screen and (max-width: 900px) {
    width: 28px;
    height: 28px;
  }
`
const PrevBtnBox = styled.div`
  position : fixed;
  bottom : 45%;
  left : 24%;
  width: 80px;
  height: 80px;
  background-color : #fff;
  border-radius : 50px;
  display : flex;
  justify-content : center;
  align-items : center;
  cursor: ${props => props.status === 0 ? 'default' : 'pointer' };
  opacity : ${props => props.status === 0 ? 0 : 0.4 };
  transition: .3s;

  &:hover {
    opacity : ${props => props.status === 0 ? 0 : 0.7};
  }
  
  @media screen and (max-width: 900px) {
    position : static;
    width: 28px;
    height: 28px;
  }
`

const NextBtnBox = styled.div`
  position : fixed;
  bottom : 45%;
  right : 24%;
  width: 80px;
  height: 80px;
  background-color : #fff;
  border-radius : 50px;
  display : flex;
  justify-content : center;
  align-items : center;
  cursor: ${props => props.status === 1 ? 'default' : 'pointer' };
  opacity : ${props => props.status === 1 ? 0 : 0.4 };
  transition: .3s;

  &:hover {
    opacity : ${props => props.status === 1 ? 0 : 0.7};
  }
  
  @media screen and (max-width: 900px) {
    position : static;
    width: 28px;
    height: 28px;
  }
`

const NextBtn = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  z-index: 99;

  @media screen and (max-width: 900px) {
    width: 28px;
    height: 28px;
  }
`

const RoomBtnInner01 = styled.div`
  display : none;
  @media screen and (max-width: 900px) and (orientation: portrait) {
    ${props => props.status === 0 ? 'display : flex' : 'display : none'};
    margin-top : 36px;
    width : 100%;
    padding : 0px 12px;
    justify-content : center;
    margin-bottom : 45px;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    ${props => props.status === 0 ? 'display : flex' : 'display : none'};
    margin-top : 36px;
    width : 100%;
    padding : 0px;
    justify-content : center;
    margin-bottom : 45px;
  }
`
const RoomInner = styled.div`
    width : 343px;
    display : flex;
    flex-wrap : wrap;
`
const RoomMbBg01 = styled.div`
  background-image : url(${mb_KitchenHome});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  margin-right : 15px;
  margin-bottom : 16px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomMbBg02 = styled.div`
  background-image : url(${mb_laundry});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  margin-bottom : 16px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomMbBg03 = styled.div`
  background-image : url(${mb_bedroom});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  margin-right : 15px;
  margin-bottom : 16px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomMbBg04 = styled.div`
  background-image : url(${mb_livingroom});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  margin-bottom : 16px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomMbBg05 = styled.div`
  background-image : url(${mb_bathroom});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomText = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
`


const RoomBtnInner02 = styled.div`
  display : none;
  @media screen and (max-width: 900px) {
    ${props => props.status === 1 ? 'display : flex' : 'display : none'};
    margin-top : 36px;
    width : 100%;
    padding : 0px 16px;
    justify-content : center;
    margin-bottom : 45px;
  }
`

const RoomMbBg06 = styled.div`
  background-image : url(${mb_KitchenHall});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  margin-right : 15px;
  margin-bottom : 16px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const RoomMbBg07 = styled.div`
  background-image : url(${mb_Hall});
  background-repeat : no-repeat;
  background-size : cover;
  width : 164px;
  height : 52px;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content: center;
`

const TextBox = styled.div`
  display : none;
  @media screen and (max-width: 900px) and (orientation: portrait) {
    width: 148px;
    height: 36px;
    border-radius: 40px;
    background-color: transparent;
    display : flex;
    justify-content : center;
    align-items : center;
    border: solid 1px #fff;
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
      display : flex;
      align-items : center;
      width: 100px;
      height: 30px;
      border-radius: 40px;
      background-color: transparent;
      display : flex;
      justify-content : center;
      align-items : center;
      border: solid 1px #fff;
  }
`

const RomeName = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
`

const LandScapeBox = styled.div`
    width : 100%;
    display : flex;
 @media screen and (max-width: 900px) and (orientation: portrait) {
    flex-direction : column;
    align-items : center;
    overflow-x : hidden;
    background-color: #000;
    overflow-y : auto;
    height : auto;
    min-height : 100vh;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2f3542;
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
      &::-webkit-scrollbar-track {
      background-color: grey;
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }

  @media screen and (max-width: 900px) and (orientation: landscape) {
    min-height : 85vh;
    display : flex;
    flex-direction : row;
    align-items : center;
    justify-content : center;
    background-color: #000;
    /* background-color : green; */
    overflow : hidden;
    /* height : auto; */
  }
`




export default withRouter(ShowRoom)