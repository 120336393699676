import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import LogoImg from '../images/common/header_logo.png'
import CloseIc from '../images/common/gnb_close_ico.png'
import InquiryIcon from '../images/common/showroom_inquiry_icon.png'
import HamburgerIc from '../images/common/gnb_menu_ico.png'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Dropdown, Select } from 'antd';
import dropdownimg from '../images/common/footer_dropdwon.png'
import icon01 from '../images/common/footer_icon01.png'
import icon02 from '../images/common/footer_icon02.png'
import icon03 from '../images/common/footer_icon03.png'
import { InquiryModal } from './Inquiry'
import { useTranslation } from 'react-i18next'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

const GNB = ({ ...props }) => {
    
    const { t } = useTranslation('common')
    const [showModal, setShowModal] = useState(false);
    
    const onSucces = () => {
        setShowModal(false);
    }

    const menu = (
        <Menu >
          <Menu.Item key="0">
            <a href={t('footer.site.viosys.href')} target='_blank' >{t('footer.site.viosys.title')}</a>
          </Menu.Item>
          <Menu.Item key="1">
            <a href={t('footer.site.seti.href')} target='_blank'>{t('footer.site.seti.title')}</a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href={t('footer.site.semicon.href')} target='_blank'>{t('footer.site.semicon.title')}</a>
          </Menu.Item>
        </Menu>
      );

    const selectLangRef = useRef();

    const [top, setTop] = useState(true);
    const [isMobile, setIsMobile] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [lang, setLang] = useState(null);
    const [focusLang, setFocusLang] = useState(false);
    
    useEffect(() => {
        const handleScroll = (e) => {
            let scrollTop = window.scrollY
            
            if (scrollTop < 3) {
                setTop(true)
            } else {
                setTop(false)
            }
            
        }
        
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        // 언어값 세팅
        if (props.currentLocale === '/en') {
            setLang('ENG');
        } else if (props.currentLocale === '/jp') {
            setLang('JPN');
        } else {
            setLang('KOR');
        }

        // resizing
        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 767) setIsMobile(true)
                else setIsMobile(false)
            }
        }

        if (props.location.pathname === `${props.currentLocale}/showroom`) {
            setShowStatus(true);
        } else {
            setShowStatus(false);
        }

        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)

    }, [])

    useEffect(() => {
        const blurSelectLang = (e) => {
            if (focusLang && (!selectLangRef.current || !selectLangRef.current.contains(e.target))) setFocusLang(false)
        }

        window.addEventListener('click', blurSelectLang)
        return () => window.removeEventListener('click', blurSelectLang)
    }, [focusLang])

    useEffect(() => {
        // 메뉴 모달창 오픈시 스크롤 막기
        const body = document.querySelector('body');
        if (isMobile && showMenu) {
            body.style.overflow = 'hidden'
        } 
        if (!showMenu) body.style.overflow = 'unset'
    }, [showMenu])

    const footerList = t('footer', { returnObjects: true }).menu

    const onClickLangChange = async (language) => {
        window.location.href = language
        
       
    }

    return (
        <Wrapper isTop={props.location.pathname === `${props.currentLocale}/` && top} 
                 isShowRoom={showStatus}>   
            {!isMobile ? 
            <Inner>
                <Logo href={t('footer.logo.href')}>
                    <img src={LogoImg} alt={t('footer.logo.alt')} />
                </Logo>
                <NavArea>
                    <NavItem current={props.location.pathname.indexOf('/company') !== -1}
                             isShowRoom={showStatus}>
                        <a href={`${props.currentLocale}/company`}>ABOUT COMPANY</a>
                    </NavItem>
                    <NavItem current={props.location.pathname.indexOf('/tech') !== -1}
                             isShowRoom={showStatus}>
                        <a href={`${props.currentLocale}/tech/sterilization`}>TECHNOLOGY</a>
                    </NavItem>
                    <NavItem current={props.location.pathname.indexOf('/applications') !== -1}
                             isShowRoom={showStatus}>
                        <a href={`${props.currentLocale}/applications`}>APPLICATIONS</a>
                    </NavItem>
                    <NavItem current={props.location.pathname.indexOf('/media') !== -1} right={'0px'}
                             isShowRoom={showStatus}>
                        <a href={`${props.currentLocale}/media`}>MEDIA</a>
                    </NavItem>
                    <NavItemShowroom isShowRoom={showStatus}>
                        {!showStatus &&
                            <NavItemShowroomBtn id="gnb_showroom" target="_blank" href={`${props.currentLocale}/showroom`}>
                                VIRTUAL SHOWROOM
                            </NavItemShowroomBtn>}
                    </NavItemShowroom>
                    {/* <NavItemRight isShowRoom={showStatus}>
                        <img src={SearchIc} />
                    </NavItemRight> */}
                    <NavItemRight isShowRoom={showStatus}>
                        <LangSelectWrap ref={selectLangRef}>
                            <SelectLangWrap onClick={() => setFocusLang(!focusLang)} on={focusLang}>
                                <CurrentLang on={focusLang}>{lang} <CaretDownOutlined /></CurrentLang>
                            </SelectLangWrap>
                            <DropDownLang on={focusLang}>
                                <SelectOption on={lang === 'KOR' ? true : false} onClick={() => onClickLangChange('/')}>KOR</SelectOption>
                                <SelectOption on={lang === 'ENG' ? true : false} onClick={() => onClickLangChange('/en')}>ENG</SelectOption>
                                <SelectOption on={lang === 'JPN' ? true : false} onClick={() => onClickLangChange('/jp')}>JPN</SelectOption>
                            </DropDownLang>
                        </LangSelectWrap>
                    </NavItemRight>
                </NavArea>
                <InquiryModal onSucces={onSucces} onCancel={() => setShowModal(false)} showModal={showModal}/>
            </Inner>
            :
            <Inner>
                <GnbArea>
                    <Logo href={t('footer.logo.href')}><img src={LogoImg} alt={t('footer.logo.alt')} /></Logo>
                    {!showStatus &&
                        <Hamburger onClick={() => setShowMenu(!showMenu)} showMenu={showMenu} />
                    }
                </GnbArea>
                <HamburgerWrap onClick={() => setShowMenu(!showMenu)} showMenu={showMenu} status={showStatus} />
                <InquiryModal onSucces={onSucces} onCancel={() => setShowModal(false)} showModal={showModal}/>
            </Inner>
            }
            {/* {showMenu && */}
                <MobileNav showMenu={showMenu}>
                    <NavItemShowroomMB>
                        <NavItemShowroomBtnMB target="_blank" href={`${props.currentLocale}/showroom`}>
                            VIRTUAL SHOWROOM
                        </NavItemShowroomBtnMB>
                    </NavItemShowroomMB>
                    <MobileNavItemWrap>
                        <NavItem current={props.location.pathname.indexOf(`${props.currentLocale}/company`) !== -1}><a href={`${props.currentLocale}/company`}>ABOUT COMPANY</a></NavItem>
                        <NavItem current={props.location.pathname.indexOf(`${props.currentLocale}/tech`) !== -1}><a href={`${props.currentLocale}/tech/sterilization`}>TECHNOLOGY</a></NavItem>
                        <NavItem current={props.location.pathname.indexOf(`${props.currentLocale}/applications`) !== -1}><a href={`${props.currentLocale}/applications`}>APPLICATIONS</a></NavItem>
                        <NavItem current={props.location.pathname.indexOf(`${props.currentLocale}/media`) !== -1} right={'0px'}><a href={`${props.currentLocale}/media`}>MEDIA</a></NavItem>
                    </MobileNavItemWrap>

                    <LanguageMobileWrap>
                        <LangOption on={lang === 'KOR' ? true : false} onClick={() => onClickLangChange('/')}>KOR</LangOption>
                        <LangOption on={lang === 'ENG' ? true : false} onClick={() => onClickLangChange('/en')}>ENG</LangOption>
                        <LangOption on={lang === 'JPN' ? true : false} onClick={() => onClickLangChange('/jp')}>JPN</LangOption>
                    </LanguageMobileWrap>

                    <SiteMenu>
                        <DropdownMenu overlay={menu} trigger={['click']} placement="bottomCenter">
                            <DropDownLink className="ant-dropdown-link" 
                                          onClick={e => e.preventDefault()}
                                          >
                                    {t('footer.site.label')}
                                <DropDownimg src={dropdownimg} alt="더보기" />
                            </DropDownLink>
                        </DropdownMenu>
                    </SiteMenu>

                    <SNSIcoWrap>
                        <a href={t('footer.social.facebook.href')} target="_blank">
                            <Icon src={icon02} alt={t('footer.social.facebook.alt')} />
                        </a>
                        <a href={t('footer.social.youtube.href')} target="_blank">
                            <Icon src={icon03} alt={t('footer.social.youtube.alt')} />
                        </a>
                        <a href={t('footer.social.linkedin.href')} target="_blank">
                            <Icon src={icon01} alt={t('footer.social.linkedin.alt')} />
                        </a>
                    </SNSIcoWrap>

                    <ViosysLogo src={t('footer.logo.image', { returnObjects: true })} alt={t('footer.logo.alt')} />
                    <BottomText>Copyright© 2021 Seoul Viosys Co., Ltd. All Right Reserved.</BottomText>
                    <FooterMenu>
                        <FooterUl>
                            {footerList.map( (list, index) => {
                                return (
                                <FooterLi key={String(index)}>
                                    <FooterLink href={list.link} target="_blank">{list.text}</FooterLink>
                                </FooterLi> 
                                )
                            })}
                        </FooterUl>
                    </FooterMenu>


                </MobileNav>
            {/* } */}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${props=>props.isTop ? 'transparent' : '#000'};
    
    position: fixed;
    top: 0;
    z-index : 100;
    display : ${props => props.isShowRoom === true ? 'none' : 'block'};

    @media screen and (max-width: 767px) {
        height: 68px;
    }
`

const Inner = styled.div`
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* padding: 0 32px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color : red; */

    @media screen and (max-width: 767px) {
        padding: 16px 24px 14px;
    }
`

const Logo = styled.a`
    img {
        width: 120px;
        height: 32px;

        @media screen and (max-width: 767px) {
            width: 105px;
            height: auto;
        }
    }
`

const NavArea = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 1199px) {
        justify-content : center;
    }
`

const NavItem = styled.li`
    height: 100%;
    display: ${props => props.isShowRoom ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    margin-right: ${props=>props.right ? props.right : '70px'};

    a {
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: ${props=>props.current ? 'bold' : 'normal'};
        text-align: center;
        color: ${props=>props.current ? '#fff' : '#aaa'};
        transition: .3s;
        line-height: 85px;
        &:hover {
            color: #fff;
        }

        @media screen and (max-width: 1199px) {
            font-size: 11px;
        }

        @media screen and (max-width: 767px) {
            padding: 10px 0;
            text-align: left;
            color: #fff;
            font-size: 20px;
            font-weight: 700 !important;
            line-height: 1.5;
        }
    }

    @media screen and (max-width: 1199px) {
        width: 95px;
        margin-right : 5vw;
    }

    @media screen and (max-width: 767px) {
        display: block;
        width: auto;
        height: auto;
        margin: 32px 0;
    }
`

const NavItemShowroom = styled.li`
    width: ${props => props.isShowRoom ? 'auto' : '160px'};
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 0px 80px;
    padding-top: 5px;
    
    @media screen and (max-width: 1199px) {
        width : 145px;
        margin: 0 1vw;
    }

    @media screen and (max-width: 767px) {
        height: auto;
        margin: 0;
        padding: 0;
    }
`

const NavKeyframes = keyframes`
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 400%;
    }
`

const NavItemShowroomBtn = styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-weight: bold;
    -webkit-box-pack: center;
    -webkit-justify-content: center;        
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    text-align: center;
    border-radius: 18px;
    outline: none;
    background: rgba(51,51,51,0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
    
    &:hover {
        background: -webkit-linear-gradient(left, #9f0588, #ab86ff, #46ff98, #9f0588);
        background: linear-gradient(90deg, #9f0588, #ab86ff, #46ff98, #9f0588);
        background-size: 400%;
        z-index: 1;
        color: #fff;
        -webkit-animation: ${NavKeyframes} 8s linear infinite;
                animation: ${NavKeyframes} 8s linear infinite;
    }

    &::before {
        content: '';
        position: absolute;
        background: inherit;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border-radius: 50px;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="20" /></filter></svg>#filter');
        -webkit-filter: blur(20px);
                filter: blur(20px);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }

    &:hover::before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        z-index: -1;
    }
    

    @media screen and (max-width: 1199px) {
        font-size: 11px;
    }

    @media screen and (max-width: 767px) {
        border-radius: 4px;
    }
`

const NavItemShowroomInquiry = styled.a`
    display : flex;
    align-items : center;
    border-radius: 40px;
    background-color: #8f0a7c;
    padding : 6px 32px 6px 20px;

    @media screen and (max-width: 900px) {
        padding: 4px 20px;
    }
`
const InquiryImg = styled.img`
     width: 20px;
     height: 20px;
     margin-right : 8px;
`

const InquiryTest = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: #fff;

    @media screen and (max-width: 900px) {
        font-size: 10px;
        font-weight: 500;
        line-height: 1.5;
    }
`

const NavItemShowroomMB = styled.div`
    display: flex;
    align-items: center;
`

const NavItemShowroomBtnMB = styled.a`
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-weight: bold;
    line-height: 38px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgba(143, 10, 124, 0.5);
    background-image: linear-gradient(116deg, #46ff98 -58%, #ab86ff 41%, #9f0588 118%);
`

const NavItemRight = styled.li`
    cursor: pointer;
    margin-left: 64px;
    display: ${props => props.isShowRoom ? 'none' : 'block'};

    @media screen and (max-width: 1199px) {
        margin-right: 12px;
    }

    &:last-child {
        margin-right: 0;
    }
`

const LangSelectWrap = styled.div`
    position: relative;
`
const SelectLangWrap = styled.div`
    width: 72px;
    height: 35px;
    padding: 9px 0;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .35s;
    ${props => props.on ? 'border-top-left-radius: 4px;border-top-right-radius: 4px; background-color: #fff' : null};
`
const CurrentLang = styled.div`
    color: ${props => props.on ? '#222' : '#fff'};
    font-size: 12px;
    transition: all .35s;

    span {
        padding-left: 5px;
    }
`
const DropDownLang = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    height: ${props => props.on ? '105px' : '0'};
    transition: all .35s;
    background-color: #fff;
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`
const SelectOption = styled.div`
    height: 35px;
    width: 72px;
    padding: 9px 0;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.on ? 'text-decoration: underline' : null};
`

const GnbArea = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;

    @media screen and (max-width: 900px) {
        justify-content : space-between;
    }
`
const Hamburger = styled.div`
    width: 24px;
    height: 20px;
    margin-left: auto;
    background: url(${props => props.showMenu ? CloseIc : HamburgerIc}) no-repeat center;
    background-size: contain;
    cursor: pointer;
`
const HamburgerWrap = styled.div`
    display : ${props => props.status ? 'none' : 'block'};
    position: absolute;
    width: 100px;
    height: 100%;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index : 100;
`

const MobileNav = styled.div`
    display : none;
    position: fixed;
    top: 68px;
    left: ${props => props.showMenu ? 0 : '102%'};
    width: 100%;
    height: calc(100vh - 68px);
    padding: 32px 35px;
    background: #000;
    overflow: auto;
    transition: all .4s;

    @media screen and (max-width: 767px) {
        display : block;
    }
`

const MobileNavItemWrap = styled.ul`
    padding-top: 16px;
`

const LanguageMobileWrap = styled.div`
    display: flex;
    margin-top: 5px;
`
const LangOption = styled.div`
    color: ${props => props.on ? '#fff' : '#393939'};
    font-size: 12px;
    font-weight: bold;
    ${props => props.on ? 'text-decoration: underline' : null};
    cursor: pointer;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`

const SiteMenu = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    width: 200px;
    height : 33px;
    border : solid 1px #fff;
    padding : 0px 10px 0px 16px;
    margin-top: 45px;
`

const DropdownMenu = styled(Dropdown)`
    &:hover {
        color : white;
    }
`

const DropDownLink = styled.a`
    font-size: 11px;
    line-height: 1.5;
    color: #aaa;
    display : flex;
    align-items : center;
`

const DropDownimg = styled.img`
    margin-left : 8px;
    width : 17px;
    height : 17px;
`

const SNSIcoWrap = styled.div`
    width : 100px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    margin-top: 60px;
`

const Icon = styled.img`
    width : 24px;
    height : 24px;
`

const ViosysLogo = styled.img`
    display: block;
    width: auto;
    height: 19px;
    margin-top: 60px;
`
const BottomText = styled.div`
    margin-top : 20px;
    font-size: 10px;
    color: #aaa;
`


const FooterMenu = styled.div`
    padding: 15px 0;
    text-align: center;
`

const FooterUl = styled.ul`
    color: #aaa;
    display : flex;
    justify-content : flex-start;
    flex-wrap : wrap;
    width : 270px;
`

const FooterLi = styled.li`
    padding: 3px 0px;
    text-decoration : none;
`

const FooterLink = styled.a`
    text-decoration : none;
    color: #777;
    font-size : 10px;
    margin-right : 20px;

    &:hover {
        color : #aaa;
    }
`

export default withRouter(GNB)