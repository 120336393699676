// Images
import Feature_01 from '../../images/tech/tech_feature_01.png'
import Feature_02 from '../../images/tech/tech_feature_02.png'
import Feature_03 from '../../images/tech/tech_feature_03.png'
import Feature_04 from '../../images/tech/tech_feature_04.png'
import Feature_05 from '../../images/tech/tech_feature_05.png'
import Feature_06 from '../../images/tech/tech_feature_06.png'
import Feature_07 from '../../images/tech/tech_feature_07.png'
import Feature_08 from '../../images/tech/tech_feature_08.png'
import Feature_09 from '../../images/tech/tech_feature_09.png'
import Feature_10 from '../../images/tech/tech_feature_10.png'
import Feature_11 from '../../images/tech/tech_feature_11.png'
import Product_01 from '../../images/tech/tech_product_01.png'
import Product_02 from '../../images/tech/tech_product_02.png'
import Product_03 from '../../images/tech/tech_product_03.png'
import Product_04 from '../../images/tech/tech_product_04.png'
// import Product_05 from '../../images/tech/tech_product_05.png'
import Product_06 from '../../images/tech/tech_product_06.png'
import Product_07 from '../../images/tech/tech_product_07.png'
import Product_08 from '../../images/tech/tech_product_08.png'
import Product_09 from '../../images/tech/tech_product_09.png'
import Product_10 from '../../images/tech/tech_product_10.png'
import Product_11 from '../../images/tech/tech_product_11.png'
import Product_12 from '../../images/tech/tech_product_12.png'
import Product_13 from '../../images/tech/tech_product_13.png'
import Example_01 from '../../images/tech/tech_example_01.png'
// import Example_02 from '../../images/tech/tech_example_02.png'
import Example_03 from '../../images/tech/tech_example_03.png'
import Example_04 from '../../images/tech/tech_example_04.png'
import Example_05 from '../../images/tech/tech_example_05.png'
import Example_06 from '../../images/tech/tech_example_06.png'
import Example_07 from '../../images/tech/tech_example_07.png'
import Example_08 from '../../images/tech/tech_example_08.png'
import Example_09 from '../../images/tech/tech_example_09.png'
import Example_10 from '../../images/tech/tech_example_10.png'
import Example_11 from '../../images/tech/tech_example_11.png'
import Example_12 from '../../images/tech/tech_example_12.png'
import Example_13 from '../../images/tech/tech_example_13.png'
import Example_14 from '../../images/tech/tech_example_14.png'

// Data
export default {
    main: {
        tab: ['除菌', '脱臭', '捕虫', '硬化'],
        head: [{
            title: 'violeds | TECHNOLOGY - 除菌',
            desc: 'ソウルバイオシスとSETi社が共同開発したUV LED清浄技術で、水、空気、表面などの除菌および浄化、消臭のための応用製品に活用できます。',
            keyword: 'violeds, Violeds, ソウルバイオシス, テクノロジー, technology, 除菌, 99%除菌力, 有害化学物質, 長寿命, 低消費電力, 低電力, 空気清浄機, 家電, ウォーターサーバー, 移動式空気清浄機, 冷蔵庫, 表面除菌機'
        }, {
            title: 'violeds | TECHNOLOGY - 脱臭',
            desc: 'ソウルバイオシスとSETi社が共同開発したUV LED清浄技術で、水、空気、表面などの除菌および浄化、消臭のための応用製品に活用できます。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、テクノロジー、technology、脱臭、光触媒、有害ガス除去、タバコ煙除去、悪臭除去、エアコン、空気清浄機、食器洗浄機、衣類管理機'
        }, {
            title: 'violeds | TECHNOLOGY - 捕虫',
            desc: 'ソウルバイオシスとSETi社が共同開発したUV LED清浄技術で、水、空気、表面などの除菌および浄化、消臭のための応用製品に活用できます。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、テクノロジー、technology、捕虫、除菌'
        }, {
            title: 'violeds | TECHNOLOGY - 硬化',
            desc: 'ソウルバイオシスとSETi社が共同開発したUV LED清浄技術で、水、空気、表面などの除菌および浄化、消臭のための応用製品に活用できます。',
            keyword: 'violeds、 Violeds、 バイオレッズ、 ソウルバイオシス、 テクノロジー、 technology、 除菌、 102%除菌力、 有害化学物質、 長寿命、 低い消費電力、 低電力、 空気清浄機、 家電製品、 浄水器、 移動式空気清浄機、 冷蔵庫、 表面除菌機'
        }]
    },
    sterilization: {
        category: '除菌',
        header: 'ソウルバイオシス<span style="font-weight: normal">は 世界最高の性能を誇る</span> UVC LED <span style="font-weight: normal">と 有害物質を使用しない</span> 環境にやさしい技術 <span style="font-weight: normal">を利用し、<br/></span>表面除菌や水除菌など <span style="font-weight: normal">のソリューションを提供します。</span>',
        headerMobile: 'ソウルバイオシスは <span style="font-weight: normal">世界最高の性能を誇る</span> UVC LED <span style="font-weight: normal">と 有害物質を使用しない</span> 環境にやさしい技術 <span style="font-weight: normal">を利用し、</span>表面除菌や水除菌など <span style="font-weight: normal">のソリューションを提供します。</span>',
        section: [{
            title: '特徴',
            contents: [{
                alt: '99.9% 除菌力',
                image: Feature_01,
                title: '99.9% 除菌力',
                desc: '汚染指標を表す代表的な細菌である大腸菌と食中毒菌の 黄色ブドウ球菌に、99.9%の除菌効果があります。',
                descMobile: '汚染指標を表す代表的な細菌である大腸菌と食中毒菌の 黄色ブドウ球菌に、99.9%の除菌効果があります。'
            },{
                alt: '有害化学物質ゼロ',
                image: Feature_02,
                title: '有害化学物質ゼロ',
                desc: '水銀や有害化学物質を使用しない技術です。ばく露しない限り、人体に無害ですので、安心して使用できます。',
                descMobile: '水銀や有害化学物質を使用しない技術です。ばく露しない限り、人体に無害ですので、安心して使用できます。'
            },{
                alt: '50,000時間の長寿命',
                image: Feature_03,
                title: '50,000時間の長寿命',
                desc: 'L50基準、50,000時間の長寿命ですので、製品の交換費用を節減できます。',
                descMobile: 'L50基準、50,000時間の長寿命ですので、製品の交換費用を節減できます。'
            },{
                alt: '低い消費電力',
                image: Feature_04,
                title: '低い消費電力',
                desc: 'UV LEDは消費電力低いので、電気代を節減できます',
                descMobile: 'UV LEDは消費電力低いので、電気代を節減できます'
            }]
        }, {
            title: '製品',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                title: 'パッケージ',
                items: [{
                    alt: 'CA PKG(1chip)',
                    image: Product_01,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }, {
                    alt: 'AAP PKG(1chip)',
                    image: Product_02,
                    name: 'AAP PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }, {
                    alt: 'AAP PKG(4chip)',
                    image: Product_03,
                    name: 'AAP PKG',
                    desc: '(4chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }, {
                title: '除菌用モジュール',
                items: [{
                    alt: '除菌用モジュール（防水型）',
                    image: Product_04,
                    name: '除菌用モジュール',
                    desc: '(防水型)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }]
        }, {
            title: '適用事例',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                alt: '空気清浄機',
                image: Example_01,
                name: '空気清浄機',
                link: '/jp/applications/sterilization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: '家電製品',
                image: Example_13,
                name: '家電製品',
                link: '/jp/applications?product=homeAppliance#homeAppliance',
                width: '130'
            }, {
                alt: 'ウォーターサーバー',
                image: Example_03,
                name: 'ウォーターサーバー',
                link: '/jp/applications/sterilization?product=waterPurifier#waterPurifier',
                width: '60'
            }, {
                alt: '移動式空気清浄機',
                image: Example_04,
                name: '移動式空気清浄機',
                link: '/jp/applications/sterilization?product=portableAirpurifier#portableAirpurifier',
                width: '44'
            }, {
                alt: 'エアコン',
                image: Example_07,
                name: 'エアコン',
                link: '/jp/applications/sterilization?product=airConditioner#airConditioner',
                width: '130'
            }, {
                alt: '表面除菌機',
                image: Example_06,
                name: '表面除菌機',
                link: '/jp/applications/sterilization?product=disinfectingSurfaces#disinfectingSurfaces',
                width: '146'
            }]
        }]
    },
    deodorization: {
        category: '脱臭',
        header: 'ソウルバイオシス<span style="font-weight: normal">は 安定した出力と高い信頼性を持つ</span>UV LEDと光触媒技術<span style="font-weight: normal">を利用し、 <br/>従来の脱臭技術より</span>有害ガス除去効率が最大8倍以上高いソリューション</span>を提供します。',
        headerMobile: 'ソウルバイオシス<span style="font-weight: normal">は 安定した出力と高い信頼性を持つ</span>UV LEDと光触媒技術<span style="font-weight: normal">を利用し、 従来の脱臭技術より</span>有害ガス除去効率が最大8倍以上高いソリューション</span>を提供します。',
        section: [{
            title: '特徴',
            contents: [{
                alt: '悪臭、VOCsなど有害化学物質の優れた除去',
                image: Feature_05,
                title: '悪臭、VOCsなど有害化学物質の優れた除去',
                desc: '悪臭、VOCs*など有害化学物質を除去して快適で健康な環境を提供します。\n(*VOCs:揮発性有機化学物の略で、呼吸器系疾患など健康に悪影響を及ぼす物質)',
                descMobile: '悪臭、VOCs*など有害化学物質を除去して快適で健康な環境を提供します。\n(*VOCs:揮発性有機化学物の略で、呼吸器系疾患など健康に悪影響を及ぼす物質)'
            },{
                alt: '有害化学物質ゼロ',
                image: Feature_02,
                title: '有害化学物質ゼロ',
                desc: '水銀及び有害化学物質を使用しない技術で、人体に有害ではなく、安心して使用できます。',
                descMobile: '水銀及び有害化学物質を使用しない技術で、人体に有害ではなく、安心して使用できます。'
            },{
                alt: '経済的な半永久フィルター',
                image: Feature_06,
                title: '経済的な半永久フィルター',
                desc: '光触媒フィルターは半永久的に使用でき、 20分ほどお湯で沸かすだけで新品並みの性能が維持されます。',
                descMobile: '光触媒フィルターは半永久的に使用でき、 20分ほどお湯で沸かすだけで新品並みの性能が維持されます。'
            },{
                alt: '優れた光触媒コーティング',
                image: Feature_07,
                title: '優れた光触媒コーティング',
                desc: '支持体から光触媒物質を脱離しにくく、優れた付着力を保ちます。',
                descMobile: '支持体から光触媒物質を脱離しにくく、優れた付着力を保ちます。'
            }]
        }, {
            title: '製品',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                title: '脱臭用モジュール(Customizing)',
                items: [{
                    alt: 'CMA-FBA-DK3A',
                    image: Product_11,
                    name: 'CMA-FBA-DK3A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }, {
                    alt: 'CMA-FBM-CO4A',
                    image: Product_12,
                    name: 'CMA-FBM-CO4A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }, {
                title: '光触媒フィルター(Customizing)',
                items: [{
                    alt: 'PCF Series',
                    image: Product_13,
                    name: 'PCF Series',
                    desc: '',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }]
        }, {
            title: '適用事例',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                alt: '冷蔵庫',
                image: Example_14,
                name: '冷蔵庫',
                link: '/jp/applications/deodorization?product=refrigerator#refrigerator',
                width: '130'
            }, {
                alt: '空気清浄機',
                image: Example_01,
                name: '空気清浄機',
                link: '/jp/applications/deodorization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: '食器洗浄機',
                image: Example_08,
                name: '食器洗浄機',
                link: '/jp/applications/deodorization?product=dishwasher#dishwasher',
                width: '130'
            }, {
                alt: '衣類管理機',
                image: Example_09,
                name: '衣類管理機',
                link: '/jp/applications/deodorization?product=steamCloset#steamCloset',
                width: '130'
            }]
        }]
    },

    insectAttraction: {
        category: '捕虫',
        header: 'ソウルバイオシス<span style="font-weight: normal">は従来の水銀ランプを利用した害虫誘引技術に比べて、<br/> </span>最大13倍以上の誘因率を持つUV LED技術を 開発し、<br/><span style="font-weight: normal">この技術を利用して顧客のニーズに 最適化された</span>実用的な捕虫ソリューション<span style="font-weight: normal">を提供します。</span>',
        headerMobile: 'ソウルバイオシス<span style="font-weight: normal">は従来の水銀ランプを利用した害虫誘引技術に比べて、 </span>最大13倍以上の誘因率を持つUV LED技術を 開発し、<span style="font-weight: normal">この技術を利用して顧客のニーズに 最適化された</span>実用的な捕虫ソリューション<span style="font-weight: normal">を提供します。</span>',
        section: [{
            title: '特徴',
            contents: [{
                alt: '最大13倍高い蚊捕集性能',
                image: Feature_08,
                title: '最大13倍高い蚊捕集性能',
                desc: 'ジカウィルス伝染の媒介となるネッタイシマカ(やぶ蚊の一種)に対して従来の水銀ランプに比べて 最大13倍高い捕集性能を発揮しました。（米国フロリダ州立大学昆虫研究室の実験結果）',
                descMobile: 'ジカウィルス伝染の媒介となるネッタイシマカ(やぶ蚊の一種)に対して従来の水銀ランプに比べて 最大13倍高い捕集性能を発揮しました。（米国フロリダ州立大学昆虫研究室の実験結果）'
            }, {
                alt: '有害化学物質ゼロ',
                image: Feature_02,
                title: '有害化学物質ゼロ',
                desc: '水銀や有害化学物質を使用しない技術です。ばく露しない限り、人体に無害ですので、安心して使用できます。',
                descMobile: '水銀や有害化学物質を使用しない技術です。ばく露しない限り、人体に無害ですので、安心して使用できます。'
            }, {
                alt: '長寿命',
                image: Feature_03,
                title: '長寿命',
                desc: 'L50基準、20,000時間の長寿命ですので、製品の交換費用を節減できます。(Z5 PKG 基準)',
                descMobile: 'L50基準、20,000時間の長寿命ですので、製品の交換費用を節減できます。(Z5 PKG 基準)'
            }, {
                alt: '低い消費電力',
                image: Feature_04,
                title: '低い消費電力',
                desc: '消費電力低いので、電気代の負担が少ないです。',
                descMobile: '消費電力低いので、電気代の負担が少ないです。'
            }]
        }, {
            title: '製品',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                title: '捕虫用モジュール',
                items: [{
                    alt: 'カスタマイズモジュール(T8-436mm)',
                    image: Product_06,
                    name: 'カスタマイズモジュール',
                    desc: '(T8-436mm)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }]
        }, {
            title: '適用事例 ',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                alt: '捕虫器',
                image: Example_10,
                name: '捕虫器',
                link: '/jp/applications/insect-attraction?product=insectTrap#insectTrap',
                width: '130'
            }]
        }]
    },
    
    curing: {
        category: '硬化',
        header: 'ソウルバイオシス<span style="font-weight: normal">は UV全波長（255~405nm）に対応できる、<br/></span> 世界で唯一のUV LEDメーカーとして<span style="font-weight: normal"> 顧客のニーズに最適化された</span>UV LED硬化ソリューション<span style="font-weight: normal">を提供します。</span>',
        headerMobile: 'ソウルバイオシス<span style="font-weight: normal">は UV全波長（255~405nm）に対応できる、</span> 世界で唯一のUV LEDメーカーとして<span style="font-weight: normal"> 顧客のニーズに最適化された</span>UV LED硬化ソリューション<span style="font-weight: normal">を提供します。</span>',
        section: [{
            title: '特徴',
            contents: [{
                alt: '全UV波長領域帯(255~405nm)の生産及び供給が可能',
                image: Feature_09,
                title: '全UV波長領域帯(255~405nm)の生産及び供給が可能',
                desc: '様々な硬化材料及び装備に最適化されたUV波長に適したChip、 PKG及びModuleをご提供いただけます。',
                descMobile: '様々な硬化材料及び装備に最適化されたUV波長に適したChip、 PKG及びModuleをご提供いただけます。'
            }, {
                alt: '装備デザインの柔軟性',
                image: Feature_10,
                title: '装備デザインの柔軟性',
                desc: '露光キットまたは装備メーカーの用途に合わせたデザインや工程条件に適合するようにChip、PKG、COB、Moduleなど、いかなる形でも供給が可能です。',
                descMobile: '露光キットまたは装備メーカーの用途に合わせたデザインや工程条件に適合するようにChip、PKG、COB、Moduleなど、いかなる形でも供給が可能です。'
            }, {
                alt: '低い消費電力と工程の利便性',
                image: Feature_04,
                title: '低い消費電力と工程の利便性',
                desc: '来の水銀、メタルハライド、またはXeonランプに比べて消費電力が最大30%水準なので、工程コストの削減に効果的でです。 既存の光源の場合、ONの際、待ち時間と出力安定化に相当な時間がかかるのに比べ、リアルタイム(Instantaneous)にON/OFF及び即時駆動が可能です。',
                descMobile: '来の水銀、メタルハライド、またはXeonランプに比べて消費電力が最大30%水準なので、工程コストの削減に効果的でです。 既存の光源の場合、ONの際、待ち時間と出力安定化に相当な時間がかかるのに比べ、リアルタイム(Instantaneous)にON/OFF及び即時駆動が可能です。',
            }, {
                alt: '作業の安定性',
                image: Feature_11,
                title: '作業の安定性',
                desc: '従来のUV Lampに比べて相対的に低い電圧を使用するため、 作業時、感電などの危険が少ないです。',
                descMobile: '従来のUV Lampに比べて相対的に低い電圧を使用するため、 作業時、感電などの危険が少ないです。',
            }, {
                alt: '有害化学物質ゼロ',
                image: Feature_02,
                title: '有害化学物質ゼロ',
                desc: '従来の水銀ランプと異なって、水銀などの 産業有害物質を使用していないので環境にやさしいです。',
                descMobile: '従来の水銀ランプと異なって、水銀などの 産業有害物質を使用していないので環境にやさしいです。',
            }]
        }, {
            title: '製品',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                title: 'PKG',
                items: [{
                    alt: 'Z5 PKG (1chip)',
                    image: Product_07,
                    name: 'Z5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }, {
                    alt: 'nZ5 PKG',
                    image: Product_08,
                    name: 'nZ5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }, {
                    alt: 'CA PKG (1chip)',
                    image: Product_09,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }, {
                title: 'Module',
                items: [{
                    alt: 'NCOB (9chip)',
                    image: Product_10,
                    name: 'NCOB',
                    desc: '(9chip)',
                    link: 'http://www.seoulviosys.com/jp/product/new.asp'
                }]
            }]
        }, {
            title: '適用事例',
            caution: '* 製品を選択すると、詳細をご確認いただけます。',
            readmore: '詳しく',
            contents: [{
                alt: 'プリンター',
                image: Example_11,
                name: 'プリンター',
                link: '/jp/applications/curing?product=printer#printer',
                width: '130'
            }, {
                alt: '産業用硬化機',
                image: Example_12,
                name: '産業用硬化機',
                link: '/jp/applications/curing?product=industrialCuring#industrialCuring',
                width: '130'
            }, {
                alt: 'ネイル硬化機',
                image: Example_05,
                name: 'ネイル硬化機',
                link: '/jp/applications/curing?product=nailCuring#nailCuring',
                width: '82'
            }]
        }]
    }
}