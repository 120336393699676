import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
/* COMMON LANG DATA */
import Main from '../data/Main'
import MainEn from '../data/en/Main'
import MainJp from '../data/jp/Main'
import Common from '../data/Common'
import CommonEn from '../data/en/Common'
import CommonJp from '../data/jp/Common'
import Application from '../data/ApplicationItems'
import ApplicationEn from '../data/en/ApplicationItems'
import ApplicationJp from '../data/jp/ApplicationItems'
import Tech from '../data/Tech'
import TechEn from '../data/en/Tech'
import TechJp from '../data/jp/Tech'
import Company from '../data/Company'
import CompanyEn from '../data/en/Company'
import CompanyJp from '../data/jp/Company'
import Media from '../data/Media'
import MediaEn from '../data/en/Media'
import MediaJp from '../data/jp/Media'
/* VR LANG DATA */
import Application_vr from '../data_vr/Applications'
import ApplicationItem_vr from '../data_vr/ApplicationItems'
import ApplicationEn_vr from '../data_vr/en/Applications'
import ApplicationItemEn_vr from '../data_vr/en/ApplicationItems'
import ApplicationJp_vr from '../data_vr/jp/Applications'
import ApplicationItemJp_vr from '../data_vr/jp/ApplicationItems'


const resources = {
    kr: {
        main: Main,
        common: Common,
        application: Application,
        tech: Tech,
        company: Company,
        media: Media,
        application_vr: Application_vr,
        ApplicationItem_vr: ApplicationItem_vr
    },
    en: {
        main: MainEn,
        common: CommonEn,
        application: ApplicationEn,
        tech: TechEn,
        company: CompanyEn,
        media: MediaEn,
        application_vr: ApplicationEn_vr,
        ApplicationItem_vr: ApplicationItemEn_vr
    },
    jp: {
        main: MainJp,
        common: CommonJp,
        application: ApplicationJp,
        tech: TechJp,
        company: CompanyJp,
        media: MediaJp,
        application_vr: ApplicationJp_vr,
        ApplicationItem_vr: ApplicationItemJp_vr
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        fallbackLng: ['kr', 'en', 'jp'],
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['path', 'navigator']
        },
        keySeparator: '.',
        ns: "translation",
    });

export default i18n