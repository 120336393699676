import app01 from '../images/vr/applications/app01.png'
import app02 from '../images/vr/applications/app02.png'
import app03 from '../images/vr/applications/app03.png'
import app04 from '../images/vr/applications/app04.png'
import app05 from '../images/vr/applications/app05.png'
import app06 from '../images/vr/applications/app06.png'
import app07 from '../images/vr/applications/app07.png'
import app08 from '../images/vr/applications/app08.png'
import app09 from '../images/vr/applications/app09.png'
import app10 from '../images/vr/applications/app10.png'
import CMAFBADK3A from '../images/vr/applications/product/CMA-FBA-DK3A.png'
import CMAFBCAR4A from '../images/vr/applications/product/CMA-FBC-AR4A.png'
import CMAFBMCO4A from '../images/vr/applications/product/CMA-FBM-CO4A.png'
import CMDFSCCO1A from '../images/vr/applications/product/CMD-FSC-CO1A.png'
import CMDFSCCOGA from '../images/vr/applications/product/CMD-FSC-COGA.png'
import CMDT5CCO1A from '../images/vr/applications/product/CMD-T5C-CO1A.png'
import CMFARA02 from '../images/vr/applications/product/CMF-AR-A02.png'
import CMFARA03 from '../images/vr/applications/product/CMF-AR-A03.png'
import CMWFCCCO1B from '../images/vr/applications/product/CMW-FCC-CO1B.png'
import CUD7GF1A from '../images/vr/applications/product/CUD7GF1A.png'
import CUD7GF1B from '../images/vr/applications/product/CUD7GF1B.png'
import CUN66A1B from '../images/vr/applications/product/CUN66A1B.png'

const ApplicationItem = {
    common: {
        detail: '제품상세보기',
        btnInfo: '* 위 버튼을 선택하여 상세 기능들을 확인해보세요.',
        showProduct: '사용된 VIOLEDS 제품 보기',
        violedsProduct: 'VIOLEDS 제품',
        moduleInfo: '* 해당 제품은 커스텀 모듈 제작이 가능합니다.'
    },
    product: {
        refrigerator: {
            name: '냉장고',
            type: ['물 살균', '공기 살균', '탈취'],
            img: app05,
            desc: '우리 가족과 아이들의 건강을 위협하는 냉장고 속 세균과 악취를 바이오레즈 기술로 깨끗이 제거하여 냉장고 내부를 쾌적한 상태로 만듭니다.',
            popupThumb: '/images/vr/thumb01.png',
            videoPopupDesc: '냉장고에서 보관되는 음식물은 저온 세균(리스테리아, 푸른 곰팡이) 및 김치 냄새(메틸메캅탄), 생선 냄새(트리메틸아민)가 발생됩니다.',
            videoPlayWord: '살균하기',
            skipSecond: 33.20,
            slidePosition: [{
                top: '53%',
                left: '7%',
            }, {
                top: '28%',
                left: '14%',
            }, {
                top: '10%',
                left: '40%',
            }],
            slide: [{
                title: '냉장고 아이스 메이커',
                desc: '아이스메이커 내부의 영하 20도 환경에서 증식해 식중독을 일으키는 리스테리아균을 포함한 각종 세균 및 바이러스를 99.9% 살균합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }, {
                title: '냉장고 디스펜서',
                desc: '정수된 물이 코크를 통할때 공기 접촉과 함께 세균과 바이러스에 쉽게 오염됩니다. 이를 바이오레즈는 99.9%까지 살균하여 깨끗한 물을 제공합니다.',
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '냉장고 내부 탈취',
                desc: '냉장고 속 저온 세균 (리스테리아, 푸른 곰팡이) 및 김치 냄새(메틸메캅탄), 생선 냄새(트리메틸아민)를 90% 이상 제거하여 쾌적한 냉장고 환경을 유지합니다.',
                pkg: [{
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMF-AR-A02',
                    img: CMFARA02,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A02.pdf'
                }, {
                    name: 'CMF-AR-A03',
                    img: CMFARA03,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A03.pdf'
                }]
            }],
        }, 
        airConditioner: {
            name: '에어컨',
            type: ['공기 살균'],
            img: app02,
            desc: '1초에 99.9%의 코로나 바이러스 살균력을 입증한 바이오레즈 기술을 이용해 실내 공기를 순환시켜 차갑게 만드는 증발기의 세균 및 바이러스 증식을 억제합니다.',
            popupThumb: '/images/vr/thumb02.png',
            videoPopupDesc: '에어컨을 가동하며 생기는 습기는 필터와 팬에 대장균과 황색 포도상 구균, 표피 포도상 구균 등 유해세균을 발생시킬 수 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 22.20,
            slidePosition: [{
                top: '20%',
                left: '50%',
            }, {
                top: '31%',
                left: '37%',
            }],
            slide: [{
                title: '에어컨 증발기',
                desc: '에어컨 내부로 유입되는 공기에 있는 세균과 바이러스가 증발기 표면에 닿을 때 바이오레즈 기술을 이용해 세균과 바이러스를 완벽에 가깝게 박멸합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: '에어컨 Fan',
                desc: '에어컨 내부로 유입되는 공기에 있는 세균과 바이러스가 Fan 표면에 닿을 때 바이오레즈의 살균력으로 99% 이상 박멸하여 유해한 공기 접촉을 최소화합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        washingMachine: {
            name: '세탁기',
            type: ['물 살균', '탈취'],
            img: app01,
            desc: '습도가 높은 환경에서 사용하는 세탁기는 세균과 곰팡이가 생기기 쉽습니다. 한 번 발생한 곰팡이 및 세균은 세탁조 클리너로 완벽한 제거가 어렵습니다. 그러나 바이오레즈는 발생 초기부터 살균하여 증식을 억제해 깨끗한 상태를 유지하도록 도와줍니다.',
            popupThumb: '/images/vr/thumb03.png',
            videoPopupDesc: '세탁 후 세탁조 외통에 남아있는 습기와 찌꺼기들로 인해 악취와 각종 세균, 곰팡이들을 번식하게 만듭니다.',
            videoPlayWord: '살균하기',
            skipSecond: 13.50,
            slidePosition: [{
                top: '26%',
                left: '18%',
            }],
            slide: [{
                title: '세탁조 외통',
                desc: '바이오레즈 기술로 세제 찌꺼기, 물때가 쌓여 발생할 수 있는 곰팡이와 세균의 증식을 방지하여 피부염과 알레르기로부터 멀어질 수 있습니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        }, 
        airpurifier: {
            name: '공기청정기',
            type: ['공기 살균', '탈취'],
            img: app10,
            desc: '공기 중 부유하는 세균 및 바이러스는 헤파필터에 걸러지는데 필터에 남아 살균되지 않고 증식됩니다. 델타변이 코로나 바이러스도 1초에 99.9% 살균력을 입증한 바이오레즈 기술을 이용해 필터 표면의 비말과 함께 유입된 세균 및 바이러스를 살균합니다.',
            popupThumb: '/images/vr/thumb04.png',
            videoPopupDesc: '외부에서 공기청정기로 유입된 공기중의 바이러스들은 필터 부위에 남아 번식하고, 다시 외부로 배출하게 됩니다.',
            videoPlayWord: '살균하기',
            skipSecond: 16,
            slidePosition: [{
                top: '7%',
                left: '35%',
            }, {
                top: '51%',
                left: '36%',
            }],
            slide: [{
                title: '공기청정기 Fan',
                desc: '공기청정기 내부로 유입되는 공기에 있는 세균과 바이러스가 Fan 표면에 닿을 때 바이오레즈의 강력한 살균력으로 유해한 바이러스들을 박멸합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: '공기청정기 필터',
                desc: '공기청정기 내부의 필터 표면에 붙은 세균과 바이러스를 바이오레즈의 살균력으로 한 번 더 제거함으로써 보다 깨끗한 공기를 제공합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        waterPurifier: {
            name: '정수기',
            type: ['물 살균'],
            img: app03,
            desc: '물이 직접 닿는 코크부터 아이스룸까지 완벽하게 살균할 수 있는 바이오레즈 기술을 이용해 잔류성 물질없이 세균을 99% 이상 박멸하여 건강한 물과 얼음을 제공합니다.',
            popupThumb: '/images/vr/thumb05.png',
            videoPopupDesc: '정수기 내부는 항상 물이 고여있기 때문에 물속의 세균으로 인해 2차 오염이 발생될 수 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 13.20,
            slidePosition: [{
                top: '27%',
                left: '14%',
            }, {
                top: '46%',
                left: '46%',
            }, {
                top: '24%',
                left: '69%',
            }],
            slide: [{
                title: '정수기 정수 코크',
                desc: '정수기 본체에서 정수된 물이 코크를 통해 나오는 순간 공기 접촉으로 인해 세균과 바이러스에 오염됩니다. 이를 바이오레즈 기술력으로 99% 이상 박멸합니다.',
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '정수 배관, 흐르는 물 살균',
                desc: '필터에 고여있는 물 속의 세균으로인한 2차 오염을 방지하고, 필터로 걸러지지 않는 세균 및 바이러스를 99.9%에 가깝게 살균하여 깨끗한 물을 제공합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-COGA',
                    img: CMDFSCCOGA,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-COGA.pdf'
                }]
            }, {
                title: '아이스룸',
                desc: '아이스룸 내부의 영하 20도 환경에서 증식해 식중독을 일으키는 리스테리아균을 포함한 각종 세균 및 바이러스를 99.9% 살균합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        dishwasher: {
            name: '식기세척기',
            type: ['물 살균', '탈취'],
            img: app06,
            desc: '바이오레즈는 식기세척기에 사용되는 물을 살균하여 낭비되는 물을 줄이고, 세척된 식기의 2차 오염을 방지하며 세척 과정 중 모인 음식물의 부패를 방지하여 불쾌한 냄새가 발생하지 않도록 합니다.',
            popupThumb: '/images/vr/thumb06.png',
            videoPopupDesc: '식기 세척 후 음식물 거름망에 남아있는 음식물과 습기는 세균이 번식하기 좋은 환경입니다.',
            videoPlayWord: '살균하기',
            skipSecond: 20.20,
            slidePosition: [{
                top: '30%',
                left: '20%',
            }, {
                top: '68%',
                left: '40%',
            }],
            slide: [{
                title: '식기 보관대',
                desc: '바이오레즈 기술은 식기세척기 내부의 환경을 바이러스나 세균이 살지 못하게하여 세척된 식기의 깨끗한 상태를 유지합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: '음식물 찌꺼기 거름망',
                desc: '바이오레즈 기술을 이용하여 세척 과정 중 모인 음식물의 부패를 방지해 식기세척기 내부의 불쾌한 냄새가 발생하지 않도록 합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        },
        vacuumCleaner: {
            name: '청소기',
            type: ['물 살균'],
            img: app08,
            desc: '바이오레즈 기술을 이용하여 모아진 먼지 속의 바이러스나 세균을 살균하여 청소 후 깨끗해진 공간을 오염시키는 2차 감염을 방지합니다.',
            popupThumb: '/images/vr/thumb07.png',
            videoPopupDesc: '청소기 내부의 먼지 포집통은 계속 먼지가 쌓여있고 바닥에 직접 닿는 브러시/롤러는 먼지와 직접 닿기 때문에 세균 번식 및 쉽게 오염될 수 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 17,
            slidePosition: [{
                top: '65%',
                left: '24%',
            }, {
                top: '71%',
                left: '59%',
            }],
            slide: [{
                title: '먼지 포집통',
                desc: '바이오레즈 살균 처리 기술로 먼지 포집통 내 바이러스나 세균의 증식을 99.9% 까지 억제하여 2차 오염을 방지할 수 있습니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '청소기 헤드 브러시/롤러',
                desc: '바닥과 직접 접촉하는 브러시/롤러의 표면을 살균하여 2차 오염을 예방하며, 바닥 표면의 세균 및 바이러스까지 제거하여 쾌적한 환경을 유지합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        },
        steamCloset: {
            name: '의류관리기',
            type: ['탈취', '공기 살균'],
            img: app07,
            desc: '바이오레즈는 스팀으로 없애기 힘든 의류 속 깊이 배어있는 불쾌한 냄새까지 분해하여 쾌적한 의류 상태를 만듭니다.',
            popupThumb: '/images/vr/thumb08.png',
            videoPopupDesc: '외부 활동으로 인해 의류에 묻은 세균은 냄새를 유발합니다. 이 세균은 의류관리기의 스팀만으로는 제거하기 어려울 수 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 23.6,
            slidePosition: [{
                top: '4%',
                left: '36%',
            }],
            slide: [{
                title: '옷 장 상부',
                desc: '의류속 불쾌한냄새인 담배냄새(피리딘), 땀냄새(이소발레릭애시드) 및 고기냄새(발레르알데하이드)를 99.9% 제거하여 청청한 옷 상태를 유지합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMA-FBM-CO4A',
                    img: CMAFBMCO4A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBM-CO4A.pdf'
                }, {
                    name: 'CMA-FBA-DK3A',
                    img: CMAFBADK3A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBA-DK3A.pdf'
                }]
            }]
        },
        humidifier: {
            name: '가습기',
            type: ['물 살균'],
            img: app09,
            desc: '깨끗하게 정수된 가습기의 물이라도 하루 이상 공기와 접촉하며 노출된 경우 일반세균에 오염될 수 있으며, 수조의 물이 공간에 전파되는 동안 같이 전파될 수 있습니다. 바이오레즈 기술은 일반세균의 번식을 억제하여 수조 내부를 깨끗한 상태로 유지하는데 도움을 줍니다.',
            popupThumb: '/images/vr/thumb09.png',
            videoPopupDesc: '가습기 내부의 습한 환경으로 생물막 생성이 유발될 수 있으며 이로 인해 세균이 번식될 수 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 13.15,
            slidePosition: [{
                top: '38%',
                left: '65%',
            }],
            slide: [{
                title: '수조',
                desc: '가습기 수조에 고여있는 물을 화학물질을 사용하지 않는 바이오레즈 기술로 세균 및 바이러스를 99.9% 살균하고, 깨끗하고 안전한 가습환경을 만들고, biofilm 생성을 억제하여 세척관리가 편해집니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        bidet: {
            name: '비데',
            type: ['물 살균'],
            img: app04,
            desc: '화장실의 주 오염 원인인 변기와 그 중 오염에 가장 취약한 비데의 노즐을 바이오레즈를 활용해 살균하여 걱정없이 쾌적한 화장실 사용이 가능합니다.',
            popupThumb: '/images/vr/thumb10.png',
            videoPopupDesc: '비데 노즐은 각종 유해세균이 번식하기 쉬운 변기에 부착되어 있으며 직접 청소를 하기 어려운 점이 있습니다.',
            videoPlayWord: '살균하기',
            skipSecond: 16,
            slidePosition: [{
                top: '53%',
                left: '45%',
            }],
            slide: [{
                title: '비데 노즐',
                desc: '바이오레즈의 살균 기술은 직접 닦기 힘든 비데노즐에 바이오레즈를 직접 조사해 각종 세균을 99% 가까이 살균합니다.',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-T5C-CO1A',
                    img: CMDT5CCO1A,
                    detail: 'http://www.seoulviosys.com/kr/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-T5C-CO1A.pdf'
                }]
            }]
        }
    }
}

export default ApplicationItem