import MediaMore01 from '../images/main/main_media_more_01.png'
import MediaMore02 from '../images/main/main_media_more_02.png'
import MediaMore03 from '../images/main/main_media_more_03.png'
import MediaMore04 from '../images/main/main_media_more_04.png'
import MediaMoreMobile01 from '../images/main/main_media_more_mobile_01.png'
import MediaMoreMobile02 from '../images/main/main_media_more_mobile_02.png'
import MediaMoreMobile03 from '../images/main/main_media_more_mobile_03.png'
import MediaMoreMobile04 from '../images/main/main_media_more_mobile_04.png'

import ApplicationItems from './ApplicationItems'

export default {
    alt: {
        home: {
            uvled: [
                '99.9% 살균력',
                '유해 화학물질 Zero',
                '50,000hr의 긴 수명',
                '낮은 소비전력'
            ],
            tech: [
                '살균',
                '공기정화',
                '포충',
                '경화'
            ],
            application: {
                refrigerator: '냉장고',
                airConditioner: '에어컨',
                washingMachine: '세탁기',
                airpurifier: '공기청정기',
                waterPurifier: '정수기',
                dishwasher: '식기세척기',
                vacuumCleaner: '청소기',
                steamCloset: '의류관리기',
                humidifier: '가습기',
                homeAppliance: '가전제품',
                portableAirpurifier: '이동식 공기청정기',
                disinfectingSurfaces: '표면 살균기',
                bidet: '비데',
                insectTrap: '포충기',
                printer: '프린터',
                industrialCuring: '산업용경화기',
                nailCuring: '네일큐어 기기',
                counterfeitDetection: '위폐감별',
                medicalDevice: '의료기기',
                blackLightBulb: '블랙라이트'
            },
            media: [
                '소셜콘텐츠',
                '인플루언서',
                '보도자료'
            ],
            inquiry: 'INQUIRY TO BUY'
        }
    },
    brandReview: {
        section1: {
            title: {
                pc: 'Bacterial Disinfection',
                mo: 'Bacterial \nDisinfection'
            },
            desc: {
                pc: 'UVC/A는 미생물의 DNA 및 RNA 구조를 파괴합니다.\nDNA 및 RNA가 파괴된 세균은 신진대사 기능이 불활성화되고\n증식력을 잃게되어 결국 사멸하게 됩니다.',
                mo: 'UVC/A는 미생물의 DNA 및 RNA 구조를 파괴합니다.\nDNA 및 RNA가 파괴된 세균은 신진대사 기능이 불활성화되고 증식력을 잃게되어 결국 사멸하게 됩니다.'
            }
        },
        section2: {
            title: 'UV LED',
            items: [{
                title: '99.9% 살균력',
                desc: '대표적인 오염 지표세균인 대장균과\n대표적인 식중독균인 황색포도상구균\n살균 시 99.9% 살균력을 나타냅니다.'
            }, {
                title: '유해 화학물질 Zero',
                desc: '수은 및 유해 화학물질을 사용하지\n않은 기술로서, 인체에 유해하지 않아 안심하고 사용이 가능합니다.'
            }, {
                title: '50,000hr의 긴 수명',
                desc: 'L50기준 50,000hr의 긴 수명을 통해 제품 교체비용을 절감 가능합니다.'
            }, {
                title: '낮은 소비전력',
                desc: 'UV LED의 낮은 소비전력을 통해\n전기료를 절감 가능합니다.'
            }]
        },
        section3: {
            title: 'Applications',
            desc: {
                pc: '바이오레즈 기술은 다양한 공간 및 제품에 UV LED 모듈을 설치하여\n살균, 공기정화, 포충, 경화에 활용되고 있습니다.\n또한 UV 전 파장 영역대의 UV LED 기술로 산업 전반에 걸쳐 사용되고 있습니다.',
                mo: '바이오레즈 기술은 다양한 공간 및 제품에 UV LED 모듈을 설치하여 살균, 공기정화, 포충, 경화에 활용되고 있습니다. 또한 UV 전 파장 영역대의 UV LED 기술로 산업 전반에 걸쳐 사용되고 있습니다.'
            }
        }
    },
    technology: {
        title: 'TECHNOLOGY',
        desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정기술로 물, 공기, 표면 등의 살균 및 정화, 탈취를 위한 응용제품에 활용 가능합니다.\n생활용품이나 치료 및 진단 목적의 생명과학 응용기기 등에도 다양하게 적용 가능하며, 단순한 광촉매 결합이 아닌 광원일체의 광집적도, 지향각 관련 특허를 활용한 첨단 기술입니다.',
        items: [{
            title: '살균',
            desc: '표면/물/공기 살균을 위한\nUV LED PKG와 살균 모듈\n(module)을 제공하고 있습니다.',
            buttonLabel: '자세히 보기',
            href: '/tech/sterilization'
        }, {
            title: '공기정화',
            desc: '독보적인 광촉매 기술을 이용하여 \n공기 중 유해물질 제거에\n월등한 효과를 발휘합니다.',
            buttonLabel: '자세히 보기',
            href: '/tech/deodorization'
        }, {
            title: '포충',
            desc: 'UV 파장, 세기 및 방사형태를 조절하여 \n비행성 해충을 유인하는\n솔루션을 보유하고 있습니다.',
            buttonLabel: '자세히 보기',
            href: '/tech/insect-attraction'
        }, {
            title: '경화',
            desc: 'UV 전 파장 영역대의 UV LED를\n생산하는 세계 유일의 회사로\n최적의 경화 솔루션을 제공합니다.',
            buttonLabel: '자세히 보기',
            href: '/tech/curing'
        }]
    },
    applicationField: {
        title: 'UV APPLICATION FIELD',
        buttonLabel: '자세히 보기',
        items: ApplicationItems
    },
    media: {
        title: 'MEDIA',
        buttons: [{
            title: '소셜콘텐츠',
            desc: 'MORE >',
            pc: MediaMore03,
            mobile: MediaMoreMobile03,
            link: '/media/social'
        }, {
            title: '인플루언서',
            desc: 'MORE >',
            pc: MediaMore02,
            mobile: MediaMoreMobile02,
            link: '/media/influencer'
        }, {
            title: '보도자료',
            desc: 'MORE >',
            pc: MediaMore01,
            mobile: MediaMoreMobile01,
            link: '/media/news'
        }, {
            title: '사회공헌 캠페인',
            desc: 'MORE >',
            pc: MediaMore04,
            mobile: MediaMoreMobile04,
            link: '/store-map'
        }],
        api: '/media/list'
    }
}