import bathBg from '../images/vr/titleBg_bathroom.png'
import bedBg from '../images/vr/titleBg_bedroom.png'
import kitchenBg from '../images/vr/titleBg_kitchen.png'
import laundryBg from '../images/vr/titleBg_laundry.png'
import livingBg from '../images/vr/titleBg_living.png'
import rsKitchenBg from '../images/vr/rs_kitchen_bg.png'
import rsHallBg from '../images/vr/rs_hall_bg.png'
import ApplicationItems from '../data_vr/ApplicationItems'

const applications = {
    header: {
        title: 'violeds | VIRTUAL SHOWROOM',
        desc: '온라인 쇼룸을 통해 다양한 공간에서 활용되는 violeds 제품을 소개합니다. 일상생활의 모든 분야에 적용할 수 있는 응용제품을 만나보세요!',
        keyword: 'VR,VIRTUAL,SHOWROOM,violeds,uvled,applications'
    },
    guide: {
        title: '버추얼 쇼룸 UI 가이드',
        desc: {
            pc: '마우스 클릭과 드래그를 이용해\n자유롭게 공간을 이동해 보세요.',
            mo: '화면을 터치하거나 드래그하여\n자유롭게 공간을 이동해 보세요.'
        },
        showroom: '온라인 쇼룸을 통해 다양한 공간에서 활용되는 violeds 제품을 소개합니다.\n일상생활의 모든 분야에 적용할 수 있는 응용제품을 만나보세요!'
    },
    category: ['전체', '물 살균', '공기 살균', '탈취'],
    data: {
        home: [{
            key: 'bedroom',
            name: 'Bedroom',
            url: '/showroom/bedroom',
            mbTitleBg: bedBg,
            items: [{
                name: '의류 관리기',
                url: 'bedroom_item3',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: '공기 청정기',
                url: 'bedroom_item2',
                detail: ApplicationItems.product.airpurifier
            }, {
                name: '에어컨',
                url: 'bedroom_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '가습기',
                url: 'bedroom_item4',
                detail: ApplicationItems.product.humidifier
            }]
        }, {
            key: 'kitchen',
            name: 'Kitchen',
            url: '/showroom/kitchen',
            mbTitleBg: kitchenBg,
            items: [{
                name: '냉장고',
                url: 'kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: '정수기',
                url: 'kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '식기 세척기',
                url: 'kitchen_item3',
                detail: ApplicationItems.product.dishwasher
            }]
        }, {
            key: 'living-room',
            name: 'Living Room',
            url: '/showroom/living-room',
            mbTitleBg: livingBg,
            items: [{
                name: '에어컨',
                url: 'living_room_item2',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '공기 청정기',
                url: 'living_room_item1',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'laundry-room',
            name: 'Laundry Room',
            url: '/showroom/laundry-room',
            mbTitleBg: laundryBg,
            items: [{
                name: '세탁기',
                url: 'laundry_item1',
                detail: ApplicationItems.product.washingMachine
            }, {
                name: '청소기',
                url: 'laundry_item2',
                detail: ApplicationItems.product.vacuumCleaner
            }]
        }, {
            key: 'bathroom',
            name: 'Bathroom',
            url: '/showroom/bathroom',
            mbTitleBg: bathBg,
            items: [{
                name: '비데',
                url: 'bathroom_item1',
                detail: ApplicationItems.product.bidet
            }]
        }],

        cafeteria: [{
            key: 'rs-hall',
            name: 'Hall',
            url: '/showroom/rs-hall',
            mbTitleBg: rsHallBg,
            items: [{
                name: '에어컨',
                url: 'rs_hall_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '의류 관리기',
                url: 'rs_hall_item4',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: '정수기',
                url: 'rs_hall_item3',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '공기 청정기',
                url: 'rs_hall_item2',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'rs-kitchen',
            name: 'Kitchen',
            url: '/showroom/rs-kitchen',
            mbTitleBg: rsKitchenBg,
            items: [{
                name: '정수기',
                url: 'rs_kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '냉장고',
                url: 'rs_kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: '공기 청정기',
                url: 'rs_kitchen_item3',
                detail: ApplicationItems.product.airpurifier
            }]
        }]
    }
}

export default applications