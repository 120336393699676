import styled from 'styled-components';
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import itemIco from '../images/applications/itemInfo_ico.png'
import arrowLeft from '../images/applications/app_arrow_left.png'
import arrowLeftOn from '../images/applications/app_arrow_left_on.png'
import arrowRight from '../images/applications/app_arrow_right.png'
import arrowRightOn from '../images/applications/app_arrow_right_on.png'
import Slider from 'react-slick'
import { RightOutlined } from '@ant-design/icons'

const ApplicationList = ({ data, current, techPC, techMO, ...props }) => {

    const [showSlide, setShowSlide] = useState(false)
    const [isMobile, setIsMobile] = useState()
    const [on, setOn] = useState(false)

    useEffect(() => {

        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 767) setIsMobile(true)
                else setIsMobile(false)
            }
        }
        resizeHandle();

        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)

    }, [])

    const settings = {
        variableWidth: true,
        dots: true,
        infinite: false,
        draggable: true,
        slidesToShow: 4,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        }, {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: "10px",
            }
        }]
    }

    const getPageMaxScroll = () => {
        return Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
          ) - window.innerHeight; // Subtract viewport height
    }

    useEffect(() => {
        if (current === data.keyword) {
            if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                setTimeout(() => {
                    window.scrollTo({top: document.documentElement.scrollTop - 80, behavior: 'smooth'})
                }, 500)
            } else {
                setTimeout(() => {
                    window.scrollTo({top: document.documentElement.scrollTop - 160, behavior: 'smooth'})
                }, 100)
            }

            // setShowSlide(true)
        }
    }, [])

    return (
        <Wrapper>
            <AppInfo showSlide={showSlide} onMouseEnter={() => !isMobile ? setOn(true) : null} onMouseLeave={() => !isMobile ? setOn(false) : null}>
                {!isMobile ?
                <>
                <TopBtnArea>
                    {data.type.map((name, idx) => {
                        let url, techNum;
                        switch (name) {
                            case '살균':
                                url = 'sterilization';
                                techNum = techPC[0];
                                break;

                            case '탈취':
                                url = 'deodorization';
                                techNum = techPC[1];
                                break;

                            case '포충':
                                url = 'insect-attraction';
                                techNum = techPC[2];
                                break;
                                
                            case '경화':
                                url = 'curing';
                                techNum = techPC[3];
                                break;
                        }
                        return (
                            <a href={`${props.currentLocale}/tech/${url}`} key={`tech_${idx}`}>
                                <TopBtn on={on}><span>{techNum}</span><RightOutlined /></TopBtn>
                            </a>
                        )
                    })}
                </TopBtnArea>
                <InfoWrap>
                    <InfoImgLine>
                        <InfoImgWrap on={on && (data.keyword == 'blackLightBulb')}>
                            <InfoImg src={on && data.keyword == 'blackLightBulb' ? data.mainImg.off : data.img} alt={data.alt} />
                        </InfoImgWrap>
                        <InfoImgPopup onClick={() => {}}>
                            <InfoImgPopupIco src={itemIco} />
                            <InfoImgTxt>제품정보 &gt;</InfoImgTxt>
                        </InfoImgPopup>
                    </InfoImgLine>
                    <InfoTxtLine>
                        <InfoTitle>{data.title}</InfoTitle>
                        <InfoDesc word={props.currentLocale}>{data.desc}</InfoDesc>
                    </InfoTxtLine>
                </InfoWrap>
                <ToggleSlider onClick={() => setShowSlide(!showSlide)} showSlide={showSlide}>
                    {showSlide ? '적용 사례 보기 ▲' : '적용 사례 보기 ▼'}
                </ToggleSlider>
                </>
                :
                <>
                <InfoWrap>
                    <InfoImgLine>
                        <InfoTitle>{data.title}</InfoTitle>
                        <InfoImgWrap>
                            <InfoImg src={data.img} alt={data.alt} />
                        </InfoImgWrap>
                    </InfoImgLine>
                    <InfoBtnWrap>
                        <InfoImgPopup onClick={() => {}}>
                            <InfoImgPopupIco src={itemIco} />
                            <InfoImgTxt>제품정보 &gt;</InfoImgTxt>
                        </InfoImgPopup>
                        <TopBtnArea>
                            {data.type.map((name, idx) => {
                                let url, techNum;
                                switch (name) {
                                    case '살균':
                                        url = 'sterilization';
                                        techNum = techMO[0];
                                        break;

                                    case '탈취':
                                        url = 'deodorization';
                                        techNum = techMO[1];
                                        break;

                                    case '포충':
                                        url = 'insect-attraction';
                                        techNum = techMO[2];
                                        break;
                                        
                                    case '경화':
                                        url = 'curing';
                                        techNum = techMO[3];
                                        break;
                                }

                                return (
                                    <a href={`${props.currentLocale}/tech/${url}`} key={`tech_${idx}`}>
                                        <TopBtn><span>{techNum}</span> <RightOutlined /></TopBtn>
                                    </a>
                                )
                            })}
                        </TopBtnArea>
                    </InfoBtnWrap>
                    <InfoTxtLine>
                        <InfoDesc word={props.currentLocale}>{data.desc}</InfoDesc>
                    </InfoTxtLine>
                </InfoWrap>
                <ToggleSlider onClick={() => setShowSlide(!showSlide)} showSlide={showSlide}>
                    {showSlide ? '적용 사례 보기 ▲' : '적용 사례 보기 ▼'}
                </ToggleSlider>
                </>
                }
            </AppInfo>
            <AppSliderWrap showSlide={showSlide}>
                <Slider {...settings}>
                    {data.detail?.map((item, idx) => {
                        return (
                            <div key={`slide_${idx}`}>
                                <Slide onClick={() => window.location.href=`/applications/${item.url}`} >
                                    <SlideTitle>{item.title}</SlideTitle>
                                    <SlideDesc>{item.desc}</SlideDesc>
                                    <SlideDate>{item.date}</SlideDate>
                                </Slide>
                            </div>
                        )
                    })}
                </Slider>
            </AppSliderWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1199px) {
        padding: 0 15px;
    }
`

const AppInfo = styled.div`
    position: relative;
    width: 100%;
    padding: 42px 40px 42px 40px;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-color: ${props => props.showSlide ? '#8f0a7c' : '#aaa'};
    ${props => props.showSlide ? 'border-top-left-radius: 12px; border-top-right-radius: 12px' : 'border-radius: 12px'};
    transition: all .5s;

    &:hover {
        background-color: #000;
        div {
            color: #fff;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 25px 14px 40px;

        &:hover {
            
        background-color: #fff;
            div {
                color: #222;
            }
        }
    }
`

const TopBtnArea = styled.div`
    position: absolute;
    top: 27px;
    right: 37px;
    display: flex;
    float: right;
    margin-left: auto;

    @media screen and (max-width: 767px) {
        position: static;
    }
`

const TopBtn = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-left: 16px;
    border: 1px solid #393939;
    color: #393939;
    font-size: 12px;
    font-weight: bold;
    border-radius: 12px;
    cursor: pointer;
    ${props => props.on ? 'border-color: #fff' : null};
    transition: all .5s;

    span {
        &:first-child {
            padding-right: 3px;
        }
        &:last-child {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 767px) {
        margin-left: 8px;
        border-radius: 8px;
    }
`

const InfoBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 35px;
`

const InfoWrap = styled.div`
    display: flex;
    clear: both;
    align-items: center;

    @media screen and (max-width: 767px) {
        display: block;
    }
`

const InfoImgLine = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const InfoImgWrap = styled.div`
    width: 160px;
    height: 160px;
    ${props => props.on ? 'padding: 28px 30px' : null};
    box-sizing: border-box;
    text-align: center
`
const InfoImg = styled.img`
    width: 100%;
    height: 100%;
    margin: 0 auto;
`

const InfoImgPopup = styled.div`
    // display: flex;

    display: none;
    justify-content: center;
    align-items: center;
    background-color: #393939;
    border-radius: 12px;
    padding: 7px 24px;
    cursor: pointer;
    
    @media screen and (max-width: 767px) {
        width: 138px;
        border-radius: 8px
    }
`

const InfoImgPopupIco = styled.img`
    margin-right: 16px;
`

const InfoImgTxt = styled.div`
    // padding-top: 2px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
`

const InfoTxtLine = styled.div`
    padding-left: 40px;

    @media screen and (max-width: 767px) {
        padding: 0
    }
`

const InfoTitle = styled.div`
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: bold;
    color: #222;

    @media screen and (max-width: 767px) {
        margin: 0;
        padding-bottom: 8px;
    }
`

const InfoDesc = styled.div`
    font-size: 16px;
    color: #222;
    line-height: 1.5;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};


    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const ToggleSlider = styled.div`
    display: none;

    position: absolute;
    bottom: -20px;
    left: 50%;
    background-color: #fff;
    transform: translateX(-50%);
    border: 1px solid #aaa;
    border-color: ${props => props.showSlide ? '#8f0a7c' : '#aaa'};
    border-radius: 12px;
    color: ${props => props.showSlide ? '#8f0a7c' : '#222'} !important;
    padding: 8px 18px 8px 24px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all .45s;
`
const AppSliderWrap = styled.div`
    width: 100%;
    height: ${props => props.showSlide ? '329px' : 0};
    overflow: hidden;
    background-color: #fafafa;
    border: 1px solid #8f0a7c;
    border-top: 0;
    ${props => props.showSlide ? 'border-bottom-left-radius: 12px; border-bottom-right-radius: 12px;' : 'border-radius: 12px; border-color: transparent'};
    box-sizing: border-box;
    transition: all .45s;
    

    .slick-slide {
        width: 272px;
        // padding-bottom: 42px;
    }
    .slick-list {
        margin-top: 42px;
        margin-left: 40px;
    }

    .slick-dots {
        position: static;
        padding: 20px 0 23px;

        li {
            width: 10px;
            height: 10px;
            margin: 0 6px;
        }

        li button {
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: 1px solid #d0d0d0;
            opacity: 1;
            border-radius: 100%;
    
            &:before {
                display: none;
            }
        }

        li.slick-active button {
            background-color: #8f0a7c;
            border-color: #8f0a7c;
        }
    }

    .slick-prev {
        top: calc(100% - 31px);
        left: 40px;
        z-index: 10;
        width: 20px;
        height: 16px;
        background: url(${arrowLeftOn}) no-repeat center;
        
        &:before {
            content: "";
        }
    }
    .slick-prev.slick-disabled {
        background: url(${arrowLeft}) no-repeat center;
    }
    .slick-next {
        top: calc(100% - 31px);
        right: 40px;
        z-index: 10;
        width: 20px;
        height: 16px;
        background: url(${arrowRightOn}) no-repeat center;

        &:before {
            content: "";
        }
    }
    .slick-next.slick-disabled {
        background: url(${arrowRight}) no-repeat center;
    }

    @media screen and (max-width: 767px) {
        .slick-list {
            margin-left: 0px;
        }
    }
`

const Slide = styled.div`
    padding: 28px 16px 23px;
    margin-right: 32px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 20px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        margin: 0 10px;
    }
`

const SlideTitle = styled.div`
    font-size: 14px;
    line-height: 1.57;
    color: #222;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
`

const SlideDesc = styled.div`
    margin: 12px 0 16px;
    font-size: 12px;
    font-weight: 300;
    color: #222;
    line-height: 1.33;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
`

const SlideDate = styled.div`
    font-size: 11px;
    color: #aaa;
    font-weight: 500;
`

export default ApplicationList