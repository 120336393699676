import React, { useState } from 'react'
import styled from 'styled-components'
import Arrow from '../../images/tech/tech_arrow_right.png'

//-------------------------
// Page
//-------------------------
const TechExample = ({ data }) => {
    const caution = data.caution
    const readmore = data.readmore
    const contents = data.contents
    const [hoverIdx, setHoverIdx] = useState(-1)

    //-------------------------
    // Function
    //-------------------------
    // Mouse Hover
    const handleMouseHover = (p_idx, idx) => {
        const result = calcIdx(p_idx, idx)
        setHoverIdx(result)
    }

    // Idx 계산
    const calcIdx = (p_idx, idx) => {
        return p_idx * 10 + idx
    }

    // clickReadmore
    const clickReadmore = (link) => {
        window.location.href = link
        // window.open(link)
    }

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Caution>{caution}</Caution>

            <Wrap>
                <Box>
                {(contents && contents.length) ? contents.map((item, idx) => {
                    return (
                        <Example key={idx}>
                            <ImageWrap
                                onMouseEnter={e => handleMouseHover(0, idx)}
                                onMouseLeave={e => handleMouseHover(0, -1)} >
                                <Image width={item.width} src={item.image} alt={item.alt} />

                                {/* Hover 이미지 */}
                                {hoverIdx === calcIdx(0, idx) &&
                                    <ImageHover
                                        id={'tech_product_case'}
                                        onClick={e=> clickReadmore(item.link) }>
                                        <HoverTitle>{readmore}</HoverTitle>
                                        <HoverImage
                                            src={Arrow}
                                            alt={idx} />
                                    </ImageHover>
                                }
                            </ImageWrap>
                            <Name>{item.name}</Name>
                        </Example>
                    )
                }) : ( <div></div> )}
                </Box>
            </Wrap>
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`

//-------------------------
// Product
//-------------------------
const Wrap = styled.div`
    display: relative;
    width: 100%;
    border-radius: 12px;
    background-color: #fafafa;
    margin: 17px 0;
    padding: 10px;

    @media (max-width: 767px) {
        margin: 17px 0 0;
    }

    @media (max-width: 374px) {
        padding: 0px;
    }
`
const Caution = styled.div`
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #222;

    @media (max-width: 767px) {
        text-align: center;
        margin-top: 7px;
    }

    @media (max-width: 567px) {
        font-size: 10px;
    }

    @media (max-width: 374px) {
        margin-top: 24px;
    }
`

//-------------------------
// Box
//-------------------------
const Box = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 80px 15px;
    border-radius: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
    grid-gap: 16px;

    @media (max-width: 767px) {
        flex-direction: column;
        padding: 30px 20px 15px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    @media (max-width: 374px) {
        padding: 30px 0 15px;
    }
`
const Example = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px;

    @media (max-width: 374px) {
        padding: 7px 0;
    }
`
const ImageWrap = styled.div`
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: solid 1px #029a45;
    background-color: #fff;

    @media (max-width: 374px) {
        width: 80px;
        height: 80px;
    }
`
const Image = styled.img`
    width: ${(props) => props.width}px;

    @media (max-width: 374px) {
        width: calc(${(props) => props.width}px / 2);
    }
`
const Name = styled.span`
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #222;
`

//-------------------------
// Hover
//-------------------------
const ImageHover = styled.div`
    display: flex;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 152px;
    height: 152px;
    border-radius: 76px;
    opacity: 0.8;
    background-color: #222;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 374px) {
        left: 1px;
        top: 1px;
        width: 76px;
        height: 76px;
        border-radius: 38px;
    }
`
const HoverTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @media (max-width: 374px) {
        font-size: 10px;
    }
`
const HoverImage = styled.img`
    margin-top: 5px;
    width: 24px;
    height: 20px;

    @media (max-width: 374px) {
        width: 12px;
        height: 10px;
    }
`

export default TechExample