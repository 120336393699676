import React, { useState, useEffect, useRef } from 'react'
import styled from "styled-components";
import { Table, Pagination, Select } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import DetailSeoul from '../images/store/detail_seoul.png'
import DetailGyeonggi from '../images/store/detail_gyeonggi.png'
import DetailEtc from '../images/store/detail_etc.png'
import TotalSeoul from '../images/store/total_seoul.png'
import TotalGyeonggi from '../images/store/total_gyeonggi.png'
import TotalEtc from '../images/store/total_etc.png'
import map_01 from './../images/storemap/map_01.png'
import mapmo_01 from './../images/storemap/mapmo_01.png'
import shohtotal from './../images/storemap/shohtotal.png'
import shop_01 from './../images/storemap/shop_01.png'
import shop_02 from './../images/storemap/shop_02.png'
import shop_03 from './../images/storemap/shop_03.png'
import shop_04 from './../images/storemap/shop_04.png'
import shop_05 from './../images/storemap/shop_05.png'
import shop_06 from './../images/storemap/shop_06.png'
import shop_07 from './../images/storemap/shop_07.png'
import shop_08 from './../images/storemap/shop_08.png'
import shop_09 from './../images/storemap/shop_09.png'
import shop_10 from './../images/storemap/shop_10.png'
import pinOff from './../images/store/pin_off.png'
import pinOn from './../images/store/pin_on.png'
import StoreData from '../data/Store.json'
import Head from '../components/Head'

const StoreMap = ({ ...props }) => {
    const labelRef = useRef()

    const getCount = (key) => {
        let values = Object.values(StoreData[key])
        let count = 0;
        for (var i = 0; i < values.length; i++) {
            count = count + values[i].list.length
        }

        return count
    }

    //큰지역
    const [mapArea, setMapArea] = useState('서울특별시')
    const [mapAreaCount, setMapAreaCount] = useState(mapAreaCount => {
        let count = getCount(mapArea)
        return count
    })
    const [storeList, setStoreList] = useState(storeList => {
        let arr = []
        let values = Object.values(StoreData[mapArea])
        
        for (var i=0; i<values.length; i++) {
            arr = arr.concat(values[i].list)
        }

        return arr
    })
    
    //상세지역
    const [pinArea, setPinArea] = useState('all')
    const [currentPage, setCurrentPage] = useState(1)
    const [isMobile, setIsMobile] = useState(null)
    
    useEffect(() => {
        // resize
        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 900) setIsMobile(true)
                else setIsMobile(false)
            }
        }
        
        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)
    }, [])


    const shopImgList = [shop_01,shop_02,shop_03,shop_04,shop_05,shop_06,shop_07,shop_08,shop_09,shop_10];

    const { Option } = Select;
    
    const onArea1Change = async (v) => {
        await setMapArea(v)
        let count = await getCount(v)
        await setMapAreaCount(count)
        await setPinArea('all')
        let arr = []
        let values = Object.values(StoreData[v])
        
        for (var i=0; i<values.length; i++) {
            arr = arr.concat(values[i].list)
        }
        setStoreList(arr)
    }

    const onArea2Change = async (v) => {
        await setPinArea(v)
        if (v === 'all') {
            let arr = []
            let values = Object.values(StoreData[mapArea])
            
            for (var i=0; i<values.length; i++) {
                arr = arr.concat(values[i].list)
            }
            
            setStoreList(arr)
        } else {
            setStoreList(StoreData[mapArea][v].list)
        }
        await setActivePin(v);

    }

    const [activePin, setActivePin] = useState();

    return (
        <Wrapper>
            <Head
                title={'violeds | 사회공헌캠페인'}
                description={'광반도체 전문기업 서울바이오시스는 ‘위드 코로나’로 인해 일상으로의 복귀가 시작된 가운데 소상공인 지원을 위해 300만 원 상당의 ‘바이오레즈(Violeds) 공청(공기청정) 살균기’를 300곳의 시설에 무상으로 제공하였습니다.'}
                keyword={'VR,VIRTUAL,SHOWROOM,violeds,uvled,applications,campaign'}
                lang={props.currentLocale}
            />
             <TopWrap>
                 {window.innerWidth <= 900 ? <Section1 src={mapmo_01}/> : <Section1 src={map_01}/> }
                <ContentsInner>
                    <Section2>
                        {shopImgList.map((list, index) => {
                            return <ShopImg key={index} src={list}/>
                        })}
                    </Section2>
                    <Section2Mobile>
                        <ShopImg src={shohtotal} />
                    </Section2Mobile>
                    <BottomSec>
                        <BottomBox>
                            <BoxText>소상공인 매장 300곳 무상 지원</BoxText>
                        </BottomBox>
                    </BottomSec>
                </ContentsInner>
                <TopDesc>
                    광반도체 전문기업 서울바이오시스는 ‘위드 코로나’로 인해 일상으로의 복귀가 시작된 가운데<br />
                    소상공인 지원을 위해 300만 원 상당의 ‘바이오레즈(Violeds) 공청(공기청정) 살균기’를 300곳의 시설에 무상으로 제공하였습니다.<br/><br/>
                    바이오레즈 공청 살균기는 10분마다 환기하는 것보다 환기되는 공기의 양이 6배 증가해<br/>
                    바이러스 농도를 추가로 6분의 1로 낮춰 실제로 실내 코로나 감염 가능성을 30분의 1로 낮출 수 있습니다. 
                </TopDesc>
            </TopWrap>
            <SelectInner>
                <SelectTitle>‘공청살균기’ 무상 지원 소상공인 가게 확인하기</SelectTitle>
                <SelectTitleMo>‘공청살균기’<br/>무상 지원 소상공인 가게 확인하기</SelectTitleMo>
                <SelectBox>
                    <Sel value={mapArea} onChange={onArea1Change} placeholder="지역 선택하기">
                        {Object.keys(StoreData).map(key => {
                            
                            let count = getCount(key)
                            return (
                                <Option value={key} key={key}>{`${key} (${count})`}</Option>
                            )
                        })}
                    </Sel>
                    <Sel value={pinArea} onChange={onArea2Change} placeholder="상세지역 선택하기">
                        
                        <Option value={'all'} key={'all'}>{`${'전체'} (${mapAreaCount})`}</Option>
                        {Object.keys(StoreData[mapArea]).map(key => {
                            return (
                                <Option value={key} key={key}>{`${key} (${StoreData[mapArea][key].list.length})`}</Option>
                            )
                        })}
                    </Sel>
                </SelectBox>
            </SelectInner>
            
            <MapSelectWrap>
                {!isMobile &&
                <TotalAreaMap>
                    <TotalAreaSelectBtnWrap>
                        <TotalAreaSelectBtn onClick={() => onArea1Change('서울특별시')} active={mapArea === '서울특별시'}>서울</TotalAreaSelectBtn>
                        <TotalAreaSelectBtn onClick={() => onArea1Change('경기\/인천')} active={mapArea === '경기\/인천'}>경기 / 인천</TotalAreaSelectBtn>
                        <TotalAreaSelectBtn onClick={() => onArea1Change('기타')} active={mapArea === '기타'}>기타</TotalAreaSelectBtn>
                    </TotalAreaSelectBtnWrap>
                    <TotalAreaMapImg>
                        <img src={mapArea == '서울특별시' ? TotalSeoul : mapArea == '기타' ? TotalEtc : TotalGyeonggi } />
                    </TotalAreaMapImg>
                </TotalAreaMap>}
                <DetailAreaMap>
                    <DetailAreaMapImg mapArea={mapArea} > 
                        <img src={mapArea == '서울특별시' ? DetailSeoul : mapArea == '기타' ? DetailEtc : DetailGyeonggi} />
                        {Object.entries(StoreData[mapArea]).map((arr) => {
                            return (
                                <PinBox onClick={() => {onArea2Change(arr[0]);  setActivePin(arr[0])}} 
                                        top={arr[1].top} 
                                        left={arr[1].left} 
                                        key={arr[0]}
                                        active={activePin}
                                        area={arr[1].list[0].area2}>
                                    {pinArea == arr[0] && <PinName>{arr[0]} ({arr[1].list.length})</PinName>}
                                    <Marker active={pinArea === arr[0] ? true : false} />
                                    {/* <Pin src={pinArea == arr[0] ? pinOn : pinOff} /> */}
                                </PinBox>
                            )
                        })}
                    </DetailAreaMapImg>
                </DetailAreaMap>
            </MapSelectWrap>
            <AreaListWrap>
                <AreaSelected id={'areaLabel'} ref={labelRef}>
                    {`${mapArea} > ${pinArea === 'all' ? '전체' : pinArea}`}
                </AreaSelected>
                {!isMobile && 
                <AreaTop>
                    <ListBox1>구분</ListBox1>
                    <ListBox2>상호명</ListBox2>
                    <ListBox3>주소</ListBox3>
                </AreaTop>}
                <AreaTable>
                    {storeList.slice((currentPage -1), currentPage + 19 ).map((item, key) => {
                        
                        return (
                            <AreaLine key={`area_${key}`}>
                                {!isMobile && <AreaWhere>{item.area1}</AreaWhere>}
                                <AreaName>{item.name}</AreaName>
                                <AreaAddress>{item.address}</AreaAddress>
                            </AreaLine>
                        )
                    })}
                </AreaTable>
                <PaginationWrap>
                    <Pagination 
                    total={storeList.length}
                    current={currentPage}
                    pageSize={20}
                    onChange={(value) => {
                        document.getElementById('areaLabel').scrollIntoView();
                        setCurrentPage(value)
                    }} />
                </PaginationWrap>
            </AreaListWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`

const TopWrap = styled.div`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    padding : 0 32px;

    @media screen and (max-width: 1200px) {
        padding : 0px 0px;
    }
`
const Section1 = styled.img`
    width : 100%;
    height : auto;
    margin-top : 125px;

    @media screen and (max-width: 1200px) {
        margin-top : 80px;
    }

    @media screen and (max-width: 900px) {
        margin-top : 60px;
    }
`

const ContentsInner = styled.div`
    position : relative;
    padding : 0 32px;
    width : 1200px;
    margin-top : 10px;
    
    @media screen and (max-width: 1200px) {
        width : 100%;
        padding : 0px;
    }
`

const Section2 = styled.div`
    width : 100%;
    height : 114px;
    display : flex;
    @media screen and (max-width: 1200px) {
        display : none;
    }
`
const Section2Mobile = styled.div`
    display : none;
    @media screen and (max-width: 1200px) {
        display : block;
        padding : 0px 16px;
    }
`

const ShopImg = styled.img`
    width : 114px;
    height : 114px;
    @media screen and (max-width: 1200px) {
        width : 100%;
        height : auto;
    }
`


const BottomSec = styled.div`
    position : absolute;
    bottom : -32px;
    z-index : 20;
    width : 100%;
    display : flex;
    justify-content : center;

    @media screen and (max-width: 1200px) {
        bottom : -42px;
    }
`

const BottomBox = styled.div`
    padding: 10px 23px;
    border-radius: 60px;
    border: solid 4px #fff;
    background-color: #8f0a7c;
`

const BoxText = styled.div`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.8;
    color: #fff;
`

const TopDesc = styled.div`
    margin-top : 80px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    color: #222222;
    word-break : keep-all;

    @media screen and (max-width: 1200px) {
        padding : 0px 24px;
        margin-top : 60px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.71;
        text-align: center;
    }
`

const SelectInner = styled.div`
    margin-top : 80px;
    width : 100%;
    height : auto;
    display : flex;
    flex-direction : column;
    align-items : center;
    
    @media screen and (max-width: 1200px) {
        margin-top : 72px;
    }
`

const SelectTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    color : #222222;
    @media screen and (max-width: 1200px) {
        display : none;
    }
`

const SelectTitleMo = styled.div`
    display : none;
    @media screen and (max-width: 1200px) {
        display : block;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.8;
        text-align: center;
        color : #222222;
    }
`

const SelectBox = styled.div`
    margin-top : 40px;
    display : flex;
    @media screen and (max-width: 1200px) {
        margin-top : 20px;
    }
`

const Sel = styled(Select)`
    margin-right : 32px;

    &:last-child {
        margin-right : 0px;
    }

    .ant-select-selector {
        border-radius: 8px !important;
        width : 247px !important;
        height: 40px !important;
        padding: 5px 11px !important;
    }

    @media screen and (max-width: 1200px) {
        margin-right : 8px;

        .ant-select-selector {
            width : 164px !important;
            padding: 5px 11px !important;
        }

    }
`

const MapSelectWrap = styled.div`
    display: flex;
    margin: 32px auto 0px;
    border: 1px solid #d0d0d0;
    border-radius: 12px;
    max-width : 1085px;
    max-height : 480px;

    @media screen and (max-width: 900px) {
        border: none;
    }
`

const TotalAreaMap = styled.div`
    width: 40%;
    border-right: 1px solid #d0d0d0;
    background-color: #fafafa;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
`

const TotalAreaSelectBtnWrap = styled.div`
    padding: 24px 0 0;
    text-align: center;
`

const TotalAreaSelectBtn = styled.div`
    display: inline-block;
    padding: 10px 38px;
    background-color: ${props => props.active ? '#8f0a7c' : '#fff'};
    border: 1px solid #d0d0d0;
    border-radius: 60px;
    font-size: 18px;
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    color: ${props => props.active ? '#fff' : '#aaa'};
    cursor: pointer;

    &:nth-child(2) {
        margin: 0 8px;
    }
`

const TotalAreaMapImg = styled.div`
    padding: 50px 60px; 
    text-align: center;
    
    @media screen and (max-width: 1200px) {
        img {
            width: 100%;
        }
    }
`

const DetailAreaMap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
`

const DetailAreaMapImg = styled.div`
    position: relative;

    @media screen and (max-width: 1200px) {
        width: ${props => props.mapArea === '서울특별시' ? '90%' : props.mapArea === '기타' ? '65%' : '80%'};

        img {
            width: 100%;
        }
    }
`

const PinBox = styled.div`
    position: absolute;
    top: ${props => props.top}%;
    left: ${props => props.left}%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props => props.area === props.active ? 'transform: translate(-2px, -3px)' : null};
    cursor: pointer;
    z-index: ${props => props.area === props.active ? 10 : 1};



`

const Pin = styled.img`
    transition : .5s;
`

const Marker = styled.div`
    border-radius: 50% 50% 50% 0;
    border: ${props=>props.active ? '6px': '4px'} solid ${props=>props.active ? '#DE10C1' : '#fff'};
    width: ${props=>props.active ? '26px': '20px'};
    height: ${props=>props.active ? '26px': '20px'};
    transform: rotate(-45deg);
    box-shadow: 0px 0px 10px 5px ${props=>props.active ? 'rgba(222,16,193,0.7)' : 'rgba(0,0,0,0.15)'};
    transition: border-color .5s, opacity .5s, box-shadow .5s;
    background-color: #fff;
    ${props=>props.active && 'opacity: 0.8;'}


    &:hover {
        border: ${props=>props.active ? '6px': '4px'} solid #DE10C1;
        opacity: 0.8;
        box-shadow: 0px 0px 10px 5px rgba(222,16,193,0.7);
    }

    
`

const PinName = styled.div`
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translateX(-50%);
    color: #DE10C1;
    font-size: 22px;
    font-weight: bold;
    line-height: 0;
    white-space: nowrap;
`


const AreaListWrap = styled.div`
    padding: 50px 0;
    max-width: 1085px;
    margin: 0px auto;

    .ant-table-thead > tr > th {
        border-top: 4px solid #d0d0d0;
        font-weight: bold;
        font-size: 20px;
    }
    .ant-table.ant-table-bordered > .ant-table-container {
        border-color: #d0d0d0;
    }
    .ant-table-cell {
        font-size: 16px;
        text-align: center;
        border-color: #d0d0d0 !important;
    }

    @media screen and (max-width: 1200px) {
       padding : 0px 16px;
    }
`

const AreaSelected = styled.div`
    padding-bottom: 20px;
    color: #aaa;
    font-size: 16px;
    line-height: 24px;
`

const AreaTable = styled.ul`

    @media screen and (max-width: 900px) {
        border-top: 4px solid #d0d0d0;
    }
`

const AreaLine = styled.li`
    display: flex;
    border-left: 1px solid #d0d0d0;

    div {
        padding: 16px;
        border-bottom: 1px solid #d0d0d0;
        border-right: 1px solid #d0d0d0;
    }

    @media screen and (max-width: 900px) {
        display: block;
        border: none;
        border-bottom: 1px solid #d0d0d0;
        padding: 16px 0;

        div {
            border: none;
            padding: 0;
        }
    }
`

const AreaTop = styled.div`
    display: flex;
    width: 100%;
    border-top: 4px solid #d0d0d0;


    @media screen and (max-width: 900px) {
        border: none;
    }
`

const ListBox1 = styled.div`
    width: 20.2%;
    background-color: #fafafa;
    text-align: center;
    border: 1px solid #d0d0d0;
    border-top: 0;
    border-left-width: 0;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0;

    &:first-child {
        border-left-width: 1px;
    }


    @media screen and (max-width: 900px) {
        border: none
    }
`

const ListBox2 = styled(ListBox1)`
    width: 30%;
`

const ListBox3 = styled(ListBox1)`
    width: 60%;
`

const AreaWhere = styled.div`
    width: 20%;
    font-size: 16px;
    text-align: center;
`

const AreaName = styled.div`
    width: 30%;
    font-size: 16px;
    text-align: center;

    @media screen and (max-width: 900px) {
        margin-bottom: 8px;
        font-size: 14px;
        color: #8f0a7c;
        font-weight: bold;
        width: 100%;
        text-align: left;
    }
`

const AreaAddress = styled.div`
    width: 60%;
    font-size: 16px;
    text-align: center;

    @media screen and (max-width: 900px) {
        font-size: 12px;
        line-height: 18px;
        color: #222;
        width: 100%;
        text-align: left;
    }
`

const PaginationWrap = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 60px;

    .ant-pagination-options {
        display: none;
    }
    
    .ant-pagination-item {
        width: 38px;
        height: 38px;
        margin: 0 4px;
        border-width: 0;

        a {
            width: 38px;
            height: 38px;
            padding: 5px 0;
            text-align: center;
            color: #393939;
            border-radius: 20px;
        }
    }
    .ant-pagination-item-active {
        a {
            background-color: #222;
            color: #fff;
        }
    }

    .ant-pagination-item-link {
        border-width: 0;
    }
`


export default withRouter(StoreMap);