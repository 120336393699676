import {useParams, useLocation} from "react-router-dom";
import styled from 'styled-components';
import React, { useState, useEffect, useMemo } from "react";
import { Link, withRouter } from 'react-router-dom'
import { Progress } from 'antd'
import MapRooms from '../components_vr/MapRooms'
import Popup from '../components_vr/Popup'
import VrGuide from '../components_vr/VrGuide'
import VrVideo from '../components_vr/VrVideo'
import horizonIco from '../images/vr/horizontally.png'
import { useTranslation } from 'react-i18next'

const useQuery = () => {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

const Vrpano = ({ ...props }) => {
    let { id } = useParams();
    let query = useQuery()

    const { t } = useTranslation('application_vr');

    const [space, setSpace] = useState('')
    const [room, setRoom] = useState('')
    const [showVideo, setShowVideo] = useState(null)
    const [showPopup, setShowPopup] = useState(null)
    const [showVrGuide, setShowVrGuide] = useState(false)
    const [loading, setLoading] = useState(0)

    let vrlocale = props.currentLocale.substring(props.currentLocale.lastIndexOf("/")+1);
    let vrangle = props.location.search.substring(6);

    useEffect(() => {
        // popup open
        setLoading(50)
        
        window.onVideo = (item) => {
            setShowVideo(item)
        }

        window.onLoadPano = (type) => {
            if (type) {
                setLoading(95)
                setTimeout(() => {
                    setLoading(100)
                }, 200)
            }
        }

        // guide popup sessionstorage
        if(sessionStorage.getItem('guidePopup')) {
            setShowVrGuide(false)
        } else {
            let val = query.get('item');
            if (!val) {
                setShowVrGuide(true)
            }
            
        }
    }, [])

    useEffect(() => {
        // room setting
        if (props.currentLocale) {
            setRoom(props.location.pathname.substring(13))
        } else setRoom(props.location.pathname.substring(10))

        // space setting
        if (props.location.pathname === `${props.currentLocale}/showroom/laundry-room` 
            || props.location.pathname === `${props.currentLocale}/showroom/kitchen`
            || props.location.pathname === `${props.currentLocale}/showroom/living-room`
            || props.location.pathname === `${props.currentLocale}/showroom/bedroom`
            || props.location.pathname === `${props.currentLocale}/showroom/bathroom`) {
            setSpace('home');
        } else if (props.location.pathname === `${props.currentLocale}/showroom/rs-kitchen` 
                || props.location.pathname === `${props.currentLocale}/showroom/rs-hall`) {
            setSpace('cafeteria');
        }
        
        // vr item 이동
        if (props.location.search) {
            setTimeout(() => {
                // console.log()
                let vr = document.getElementById('vr').contentWindow || document.getElementById('vr').contentDocument;
                let val = query.get('item');
                
                vr.window[val]();
            }, 1300)
        }
    }, [props.location.pathname])

    const onCloseGuide = () => {
        sessionStorage.setItem('guidePopup', true);
        setShowVrGuide(false)
    }

    return (
        <Wrapper>
            
            <MapRooms {...props} data={t('data', { returnObjects: true })} space={space} room={room} />

            <LandScapeMode>
                <LandScapeTxt>
                    <HorizontalIco src={horizonIco} />{props.currentLocale ? 'Tilt your phone horizontally' : '모바일에서는 가로보기 모드만 지원됩니다.'}
                </LandScapeTxt>
            </LandScapeMode>

            {showVideo &&
                <VrVideo 
                    item={showVideo} 
                    onClosePopup={() => setShowVideo(false)}
                    onShowPopupDetail={(item) => {
                        setShowVideo(false)
                        setShowPopup(item)
                    }} />
            }
            
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'refrigerator'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'airConditioner'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'washingMachine'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'airpurifier'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'waterPurifier'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'dishwasher'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'vacuumCleaner'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'steamCloset'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'humidifier'} lang={props.currentLocale} />
            <Popup currentLocale={props.currentLocale} visible={showPopup} closePopup={() => setShowPopup(null)} item={'bidet'} lang={props.currentLocale} />
            
            {showVrGuide &&
            <VrGuide onCloseGuide={onCloseGuide} data={t('guide', { returnObjects: true })} />}

            <LoadingPop hide={loading === 100}>
                <LoadingTxt>LOADING</LoadingTxt>
                <Progress percent={loading} showInfo={false} trailColor={'white'}></Progress>
            </LoadingPop>

            <Pano id='vr' src={`/krpano/index_t.html?name=${id}&locale=${vrlocale}&angle=${vrangle}`} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
`

const Pano = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
`

const LandScapeMode = styled.div`
    display: none;

    @media screen and (max-width: 900px) and (orientation: portrait) {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100vh;
        background: #000;
        z-index: 10000;
    }
`

const HorizontalIco = styled.img`
    display: block;
    width: 105px;
    margin: 0 auto;
    margin-bottom: 25px;
`

const LandScapeTxt = styled.div`
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
`

const LoadingPop = styled.div`
    opacity: ${props => props.hide ? 0 : 1};
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    padding: 40px 60px;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center;
    transition: all .3s .4s;
    z-index: ${props => props.hide ? -1 : 10010};

    .ant-progress-outer {
        padding: 8px;
        border: 1px solid #fff;
        border-radius: 40px;
        line-height: 0;
    }
    .ant-progress-success-bg, .ant-progress-bg {
        background-color: #8f0a7c;
        height: 4px !important;
    }
`

const LoadingTxt = styled.div`
    padding: 0 7px 15px;
    font-size: 38px;
    font-weight: bold;
    color: #fff;

    @media screen and (max-width: 900px) {
        font-size: 30px;
    }
`

export default withRouter(Vrpano)