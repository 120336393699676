import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

const Dropdown = ({ big, spaceName, title, level, spaceList = {} }) => {

    const [dropdownVisible, setDropDownVisible] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const initMobile = async () => {
            const screenWidth = window.innerWidth
            const screenHeight = window.innerHeight

            if (screenWidth > screenHeight) {
                if (screenWidth < 900) {
                    await setIsMobile(true)
                }
            }
        }

        initMobile()
    }, [])


    return (
        <Wrapper 
        onMouseEnter={() => {
            if (!isMobile) setDropDownVisible(true)
        }}
        onClick={(e) => {
            if (isMobile) setDropDownVisible(!dropdownVisible)
        }}
        >
            <MobileWrap visible={dropdownVisible} />
            <Title big={big}>{title}</Title>
            <DropDownWrapper 
            onMouseLeave={() => {
                if (!isMobile) setDropDownVisible(false)
            }}
            visible={dropdownVisible}>
                {level === 1 && Object.keys(spaceList).map((value, idx) => (
                    <DropDownItem key={String(idx)} selected={spaceList[value].name === title}>
                        <a href={spaceList[value].name === title ? '#' : spaceList[value].url}>
                            {spaceList[value].name}
                        </a>
                    </DropDownItem>  
                ))}
                {level === 2 && spaceList[spaceName].roomList.map((room, idx) => (
                    <DropDownItem key={String(idx)} selected={room.name === title}>
                        <a href={room.name === title ? '#' : room.url}>
                            {room.name}
                        </a>
                    </DropDownItem>
                ))}
            </DropDownWrapper>
            
        </Wrapper>
    )

}

const MobileWrap = styled.div`
    display: ${props=>props.visible ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
`

const Wrapper = styled.div`
    height: auto;
    position: relative;
    padding: 0 10px;
`

const Title = styled.p`
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    color: #fff;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        font-size: 16px;
    }
`

const DropDownWrapper = styled.ul`
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 260px;
    background-color: transparent;
    padding-top: 50px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow: hidden;
    opacity: ${props=>props.visible ? '1':'0'};
    transform: ${props=>props.visible ? 'scale(1)':'scale(0)'};
    transition: opacity .3s;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 160px;
        padding-top: 40px;
    }
`

const DropDownItem = styled.li`
    width: 100%;
    height: 46px;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        height: 38px;
    }

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    
    overflow: hidden;
    

    a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transition: background .3s;
        line-height: 48px;
        font-size: 16px;
        font-weight: ${props=>props.selected ? 'bold' : '400'};
        color: #393939;
        padding: 0 20px;

        &:hover {
            background-color: #eee;
        }

        @media screen and (max-width: 900px) and (orientation: landscape) {
            font-size: 12px;
            padding: 0 14px;
            line-height: 38px;
        }
    }

    

`

export default Dropdown