import styled from 'styled-components'
import React, { useState, useEffect, useRef } from 'react'
import closeBtn from '../images/vr/close_btn_bl.png'
import { useTranslation } from 'react-i18next'
import prevBtn from '../images/vr/popup_prev_ic.png'
import nextBtn from '../images/vr/popup_next_ic.png'
import markOn from '../images/vr/popupMarkOn.png'
import markOff from '../images/vr/popupMarkOff.png'
import Slider from 'react-slick'
import { Modal } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'

const Popup = ({ item, closePopup, visible, lang, ...props }) => {

    const { t } = useTranslation('ApplicationItem_vr');

    const slider = useRef()
    
    const [showDetail, setShowDetail] = useState(false)
    const [onMark, setOnMark] = useState(0)
    const [slide, setSlide] = useState(s => {
        let sl = t(`product.${item}.slide`, { returnObjects: true })
        return sl
    })
    const [isMobile, setIsMobile] = useState()
    
    useEffect(() => {
        // resize
        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 900) setIsMobile(true)
                else setIsMobile(false)
            }
        }
        
        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)
    }, [])
    
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: idx => {
            setOnMark(idx)
        },
        ref: slider
    }

    const onShowClick = (e) => {
        setShowDetail(!showDetail)
    }

    const onCloseModal = () => {
        closePopup()
        setShowDetail(false)
        setOnMark(0)
        slider.current.slickGoTo(0, true)
    }

    return (
        <ModalWrap 
        centered={true}
        width={'auto'} 
        visible={item === visible ? true : false} 
        centered={true}
        onCancel={null} 
        footer={null} 
        forceRender={true}
        bodyStyle={{padding: 0, backgroundColor: 'transparent', borderRadius: '20px', overflow: 'hidden'}}
        style={{ backgroundColor: 'transparent', minWidth: isMobile ? '395px' :'655px' }}
        closable={true}
        closeIcon={<CloseBtn src={closeBtn} onClick={onCloseModal} alt="Close popup" />}
        >
            <PopupSlideArea show={showDetail} lang={lang}>
                <PopupTop>
                    <TopTitle>{item && t(`product.${item}.name`)}</TopTitle>
                    <TopDesc word={props.currentLocale}>{item && t(`product.${item}.desc`)}</TopDesc>
                    <Divider />
                </PopupTop>
                <PopupContents lang={lang}>
                    <PopupInnerWrapLeft>
                        <PopThumbWrapper>
                            <PopThumbImg src={t(`product.${item}.popupThumb`)} />
                            
                            {item && t(`product.${item}.slidePosition`, { returnObjects: true }).map((mark, index) => {
                                return (
                                    <PopupMark 
                                    key={String(index)}
                                    src={onMark == index ? markOn : markOff} 
                                    top={mark.top} left={mark.left}
                                    onClick={() => {
                                        setOnMark(index)
                                        slider.current.slickGoTo(index, false)
                                    }} />
                                )
                            })}
                        </PopThumbWrapper>
                        
                        <PopupThumbInfo>{t('common.btnInfo')}</PopupThumbInfo>
                    </PopupInnerWrapLeft>
                    <PopupInnerWrapRight>
                        <PopupSlider>
                            
                            <DetailSlider {...settings} lang={lang}>
                                {item && slide.map((slide, idx) => {
                                    return (
                                        <div key={String(idx)}>
                                            <SliderItems>
                                                <SliderImg src={`/images/vr/${item}_0${idx + 1}.png`} />
                                                <SliderTitle>{slide.title}</SliderTitle>
                                                <SliderDesc>{slide.desc}</SliderDesc>
                                            </SliderItems>
                                        </div>
                                    )
                                })}
                            </DetailSlider>
                            <ArrowBtnPrev onClick={e=> slider.current.slickPrev()} visible={onMark !== 0} src={prevBtn} />
                            <ArrowBtnNext onClick={e=> slider.current.slickNext()} visible={onMark < t(`product.${item}.slide`, { returnObjects: true }).length - 1} src={nextBtn} />
                            <CurrentSlide>
                                {`${onMark + 1}/${t(`product.${item}.slide`, { returnObjects: true }).length}`}
                            </CurrentSlide>
                            <ShowItemBtn show={showDetail} onClick={onShowClick}>
                                {t('common.showProduct')}
                                <CaretRightOutlined />
                            </ShowItemBtn>
                        </PopupSlider>
                    </PopupInnerWrapRight>
                    
                </PopupContents>
            </PopupSlideArea>
            <DetailWrap show={showDetail} lang={lang}>
                <DetailContent show={showDetail}>
                    <DetailTitle>
                        {t('common.violedsProduct')}
                    </DetailTitle>
                    <DetailContents>
                        <DetailBox>
                            <DetailTab>PKG</DetailTab>
                            {item && 
                                slide[onMark].pkg.map((el, idx) => {
                                    return (
                                        <DetailItemWrap key={String(idx)}>
                                            {isMobile ?
                                            <DetailItemDesc>
                                                <ItemDetailWrapMobile>
                                                    <ItemImg src={el.img} />
                                                    <ItemName>{el.name}</ItemName>
                                                </ItemDetailWrapMobile>
                                                <ItemDetailBtnWrap>
                                                    <ItemDetail href={el.detail} target={'_blank'}>Product Detail</ItemDetail>
                                                    <ItemDataSheet href={el.datasheet} download>Data Sheet</ItemDataSheet>
                                                </ItemDetailBtnWrap>
                                            </DetailItemDesc>
                                            : <>
                                                <ItemImg src={el.img} />
                                                <DetailItemDesc>
                                                    <ItemName>{el.name}</ItemName>
                                                    <ItemDetailBtnWrap>
                                                        <ItemDetail href={el.detail} target={'_blank'}>Product Detail</ItemDetail>
                                                        <ItemDataSheet href={el.datasheet} download>Data Sheet</ItemDataSheet>
                                                    </ItemDetailBtnWrap>
                                                </DetailItemDesc>
                                            </>
                                            }
                                        </DetailItemWrap>
                                    )
                                })
                            }
                        </DetailBox>

                        <DetailBox>
                            <DetailTab>Module</DetailTab>
                            <DetailModuleInfo>
                                {t('common.moduleInfo')}
                            </DetailModuleInfo>
                            {item && 
                                slide[onMark].module.map((el, idx) => {
                                    return (
                                        <DetailItemWrap key={String(idx)}>
                                            {isMobile ?
                                            <DetailItemDesc>
                                                <ItemDetailWrapMobile>
                                                    <ItemImg src={el.img} />
                                                    <ItemName>{el.name}</ItemName>
                                                </ItemDetailWrapMobile>
                                                <ItemDetailBtnWrap>
                                                    <ItemDetail target={'_blank'} href={el.detail}>Product Detail</ItemDetail>
                                                    <ItemDataSheet href={el.datasheet} download>Data Sheet</ItemDataSheet>
                                                </ItemDetailBtnWrap>
                                            </DetailItemDesc>
                                            : <>
                                            <ItemImg src={el.img} />
                                            <DetailItemDesc>
                                                <ItemName>{el.name}</ItemName>
                                                <ItemDetailBtnWrap>
                                                    <ItemDetail target={'_blank'} href={el.detail}>Product Detail</ItemDetail>
                                                    <ItemDataSheet href={el.datasheet}>Data Sheet</ItemDataSheet>
                                                </ItemDetailBtnWrap>
                                            </DetailItemDesc>
                                            </>}
                                        </DetailItemWrap>
                                    )
                                })
                            }
                        </DetailBox>
                    </DetailContents>
                </DetailContent>
                
            </DetailWrap>
        </ModalWrap>
    )
}

const ModalWrap = styled(Modal)`
    
    .ant-modal-body {
        display: flex;
    }
    .ant-modal-content {
        background-color: transparent;
    }
`

const PopupTop = styled.div`
    position: relative;
    width: 100%;
    // height: 140px;
    min-height: 140px;
    max-height: 160px;
    padding: 32px 20px 9px 40px;

    @media screen and (max-width: 900px) {
        min-height: 90px;
        padding: 18px 12px 5px 24px;
    }
`

const CloseBtn = styled.img`
    position: absolute;
    top: 26px;
    right: 36px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
    }
`

const PopupSlideArea = styled.div`
    position: relative;
    width: 740px;
    // height: 594px;
    min-height: 594px;
    // max-height: ${props => props.lang == '/en' ? '650px' : props.lang == '/jp' ? '640px' : '614px'};
    max-height: 650px;
    background-color: white;

    @media screen and (max-width: 900px) {
        width: 480px;
        // max-height: ${props => props.lang == '/en' ? '500px' : '410px'};
        max-height: 500px;
        min-height: 390px;
    }
`

const TopTitle = styled.div`
    font-size: 28px;
    color: #8f0a7c;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 30px;
    }
`

const TopDesc = styled.div`
    // height: 38px;
    font-size: 14px;
    line-height: 1.39;
    color: #000;
    font-weight: 500;
    white-space: pre-line;
    margin-bottom: 12px;
    letter-spacing: -0.2px;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};
    @media screen and (max-width: 900px) {
        margin-bottom: 5px;
        font-size: 12px;
    }
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background-color: #8f0a7c;
`

const PopupContents = styled.div`
    width: 100%;
    height: ${props => props.lang == '/en' ? '500px' : props.lang == '/jp' ? '480px' : '460px'};
    display : flex;
    align-items: center;
    padding: 0 20px 0 40px;

    @media screen and (max-width: 900px) {
        max-height: 350px;
        min-height: 200px;
        // max-height: ${props => props.lang == '/en' ? '350px' : '298px'};
        // min-height: ${props => props.lang == '/en' ? '300px' : '200px'};
        height: auto;
        padding: 0 12px 0 24px;
    }
`

const PopupInnerWrap = styled.div`
    position: relative;
    width: 50%;
    height: 100%;
    
`

const PopupInnerWrapLeft = styled(PopupInnerWrap)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;

    @media screen and (max-width: 900px) {
        padding-top: 7px;
    }
`

const PopupInnerWrapRight = styled(PopupInnerWrap)`
    position: relative;
    padding: 20px 0 20px 24px;

    @media screen and (max-width: 900px) {
        padding: 7px 0 15px 0;
    }
`
const PopThumbWrapper = styled.div`
    position: relative;
    width: 307px;
    height: 404px;
    // background-image: url(${props=>props.image});
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;

    @media screen and (max-width: 900px) {
        width: 183px;
        height: 240px;
    }
`

const PopThumbImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`


const PopupMark = styled.img`
    position: absolute;
    left: ${props => props.left};
    top: ${props => props.top};
    cursor: pointer;

    @media screen and (max-width: 900px) {
        width: 32px;
        height: 48px;
    }
`

const PopupThumbInfo = styled.div`
    color: #aaa;
    font-size: 12px;
    text-align: center;

    @media screen and (max-width: 900px) {
        font-size: 8px;
    }
`

const PopupSlider = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    border-radius: 20px;
`

const ArrowBtn = styled.img`
    position: absolute;
    top: 140px;
    cursor: ${props=>props.visible ? 'pointer' : 'auto'};
    width: 20px;
    height: 20px;
    opacity: ${props=>props.visible ? '0.8' : '0.4'};
    transition: .3s;
    &:hover {
        ${props=>props.visible && 'opacity: 1;'}
    }

    @media screen and (max-width: 900px) {
        width: 14px;
        height: 14px;
        top: 75px;
    }
`

const ArrowBtnPrev = styled(ArrowBtn)`
    left: 10px;

    @media screen and (max-width: 900px) {
        left: 16px;
    }
`
const ArrowBtnNext = styled(ArrowBtn)`
    right: 10px;

    @media screen and (max-width: 900px) {
        right: 16px;
    }
`

const CurrentSlide = styled.p`
    position: absolute;
    top: 38px;
    right: 48px;
    margin-bottom: 0;
    width: 32px;
    height: 16px;
    background-color: #555;
    border-radius: 8px;
    color: #fff;
    line-height: 15px;
    font-size: 8px;
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 900px) {
        top: 13px;
        right: 42px;
        width: 28px;
        font-size: 8px;
    }
`

const DetailSlider = styled(Slider)`
    // width: 316px;
    max-width: 316px;
    height: 100%;
    // height: 379px;
    // overflow: hidden;

    .slick-slide {
        transform : scale(1);
    }

    @media screen and (max-width: 900px) {
        // min-height: 340px;
        height: auto;
        padding-bottom: 20px;
    }
`

const SliderItems = styled.div`
    width: 100%;
    padding-top: 30px;
    display: flex !important;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 900px) {
        padding-top: 8px;
    }
`

const SliderTitle = styled.h3`
    margin: 16px 0 8px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-weight: bold;
    white-space: pre-line;

    @media screen and (max-width: 900px) {
        margin: 8px 0 5px;
        font-size: 11px;
        line-height: 16px;
    }
`

const SliderDesc = styled.div`
    padding: 0 8%;
    font-size: 12px;
    color: #000;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.36;

    @media screen and (max-width: 900px) {
        padding: 0 6%;
        font-size: 11px;
    }
`

const SliderImg = styled.img`
    width: 240px;
    height: 240px;
    border-radius: 8px;

    @media screen and (max-width: 900px) {
        width: 150px;
        height: 150px;
    }
`

const ShowItemBtn = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 148px;
    height: 30px;
    color: ${props => props.show ? '#fff' : '#8f0a7c'};
    background: ${props => props.show ? '#8f0a7c' : '#fff'};
    border: 1px solid #8f0a7c;
    border-radius: 15px;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    line-height: 28px;

    @media screen and (max-width: 900px) {
        bottom: -5px;
        width: auto;
        height: auto;
        padding: 4px 10px 5px;
        font-size: 6px;
        line-height: 12px;

        span {
            vertical-align: middle;
            font-size: 9px;
        }
    }
`

const DetailWrap = styled.div`

    width: ${props=>props.show ? '300px' : '0'};
    // height: 594px;
    min-height: 594px;
    // max-height: ${props => props.lang == '/en' ? '650px' : props.lang == '/jp' ? '640px' : '614px'};
    max-height: 650px;
    padding-top: 58px;
    background-color: #fafafa;
    overflow-y: hidden;
    transform: ${props=>props.show ? 'scaleX(1.0)' : 'scaleX(0.0)'};
    transform-origin: left;
    -webkit-transition: transform .2s, width .2s;
    transition: transform .2s, width .2s;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition-delay: ${props=>props.show ? '0' : '.3s'};

    @media screen and (max-width: 900px) {
        width: ${props=>props.show ? '180px' : '0'};
        max-height: 500px;
        min-height: 390px;
        padding-top: 34px;
    }
`

const DetailContent = styled.div`
    width: 100%;
    height: 536px;
    padding-top: 22px;
    padding-bottom: 40px;
    overflow-y: auto;
    opacity: ${props=>props.show ? '1' : '0'};
    transition: .2s;
    transition-delay: ${props=>props.show ? '.3s' : '0'};
    
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        height: 10%;
        background-color: rgba(0,0,0,0.3);
        border-radius: 10px;   
    }

    @media screen and (max-width: 900px) {
        height: 356px;
        padding-top: 10px;
        padding-bottom: 15px;
    }
`

const DetailTitle = styled.div`
    color: #393939;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

    @media screen and (max-width: 900px) {
        font-size: 14px;
    }
`
const DetailContents = styled.div`
    width: 100%;
    padding: 0 20px;

    @media screen and (max-width: 900px) {
        padding: 0 5px;
    }
`

const DetailBox = styled.div`
    position: relative;
    padding: 16px 10px;
    margin-top: 35px;
    border: 1px solid #8f0a7c;
    border-radius: 12px;
    
    @media screen and (max-width: 900px) {
        padding: 5px;
    }
`

const DetailTab = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    transform: translateY(-50%);
    padding: 4px 20px;
    border-radius: 12px;
    background-color: #8f0a7c;
    color: #fff;
    font-size: 12px;
    font-weight: bold;

    @media screen and (max-width: 900px) {
        font-size: 10px;
        padding: 3px 12px;
    }
`

const DetailModuleInfo = styled.div`
    padding: 8px 0;
    font-size: 8px;
    color: #aaa;

    @media screen and (max-width: 900px) {
        padding: 8px 0 5px;
        letter-spacing: -0.7px;
    }
`

const DetailItemWrap = styled.div`
    width: 100%;
    display: flex;
    padding: 8px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 4px;
`

const ItemImg = styled.img`
    width: 52px;
    height: 52px;

    @media screen and (max-width: 900px) {
        width: 40px;
        height: 40px;
    }
`

const DetailItemDesc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 52px);
    padding-left: 6px;

    @media screen and (max-width: 900px) {
        padding: 0;
        width: 100%;
    }

`

const ItemName = styled.div`
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
        font-size: 11px;
        margin: 0 auto;
        letter-spacing: -0.2px;
    }
`

const ItemDetailBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    
    @media screen and (max-width: 900px) {
        margin-top: 4px;
    }
`

const ItemDetailWrapMobile = styled.div`
    display: flex;
    align-items: center;
`

const ItemDetail = styled.a`
    cursor: pointer;
    width: 86px;
    height: 20px;
    font-size: 10px;
    color: #fff;
    border-radius: 10px;
    background: #aaa;
    border: 1px solid #aaa;
    cursor: pointer;
    text-align: center;
    line-height: 18px;
    opacity: 1;
    transition: .3s;

    &:hover {
        color: #fff;
        opacity: 0.8;
    }

    @media screen and (max-width: 900px) {
        font-size: 8px;
        letter-spacing: -0.5px;
    }
`

const ItemDataSheet = styled(ItemDetail)`
    width: 72px;
    color: #aaa;
    background: #fff;
    margin-left: 4px;
    &:hover {
        color: #aaa;
    }
`


export default withRouter(Popup)