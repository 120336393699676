import React, { useEffect, useState } from 'react'
import homebg from '../images/showroom/elevation_02.png'
import homebgMB from '../images/showroom/elevation_02_mb.png'
import rs_kitchen_img from '../images/showroom/rs_kitchen.png'
import rs_hall_img from '../images/showroom/rs_hall.png'
import pointer from '../images/showroom/ico_map_pointer.png'
import pointer_on from '../images/showroom/ico_map_pointer_on.png'
import styled, { keyframes } from 'styled-components';
import { Link, withRouter } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next'
const Second_Showroom = ({index, point, roomname}) => {
    const { t } = useTranslation('application_vr')
    const [titleOn1, setTitleON1] = useState(false);
    const [titleOn2, setTitleON2] = useState(false);

    const [space1, setSpace1] = useState(true);
    const [space2, setSpace2] = useState(true);

    let hall = t('data', { returnObjects: true }).cafeteria[0]
    let kitchen = t('data', { returnObjects: true }).cafeteria[1]

    useEffect(() => {
      if (point === 1) {
        setTimeout(()=>{ setSpace1(false) }, 1000);
        setTimeout(()=>{ setSpace2(false) }, 1300);
        setTimeout(()=>{ setSpace1(true); setTitleON1(true); }, 1600);
      } else {
        setSpace1(true);
        setSpace2(true);
        setTitleON1(false);
      }
    },[point]);

    return (
      <Wrapper>
        <MoblieImgInner>
          <HomebgMB src={homebgMB} />
        </MoblieImgInner>
        <Homebg index={index}>

          {/* Item1 Hall position */}
          <Section1>
            <Fade top when={point === 1 ? true : false}  
                      distance="20%" 
                      duration={1000}>
              <Space1 src={rs_hall_img} status={space1}/>
            </Fade>
          </Section1>
          <Point1>
            <Fade top when={point === 1 ? true : false}  
                      distance={point === 1 ? '180%' : '20%'}
                      duration={1100}>
              <Title1 status={titleOn1}>Hall</Title1>
              <Link to={hall.url}>
                <PointItemimg onMouseOver={() => { setTitleON1(true); setSpace1(true) }} 
                              onMouseOut={() => { setTitleON1(false); setSpace1(false) }} 
                              status={titleOn1} />
              </Link>
            </Fade>
          </Point1>
     
          {/* Item2 Kitchen position */}
          <Section2>
            <Fade top when={point === 1 ? true : false} 
                      distance="20%" 
                      duration={1000} 
                      delay={point === 1 ? 300 : 0}>
              <Space2 src={rs_kitchen_img} status={space2}/>
            </Fade>
          </Section2>
          <Point2>
            <Fade top when={point === 1 ? true : false} 
                      distance={point === 1 ? '180%' : '20%'}
                      duration={1100} 
                      delay={point === 1 ? 300 : 0}>
              <Title2 status={titleOn2}>Kitchen</Title2>
              <Link to={kitchen.url}>
                <PointItemimg onMouseOver={() => { setTitleON2(true); setSpace2(true); setSpace1(false); setTitleON1(false); }} 
                              onMouseOut={() => { setTitleON2(false); setSpace2(false) }}
                              status={titleOn2} />
              </Link>
            </Fade>
          </Point2>
          <RoomTextInner>
          <TextBox room={roomname}>
            <RoomText room={roomname}>Cafeteria</RoomText>
          </TextBox>
        </RoomTextInner>
        </Homebg>
      </Wrapper>
    )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 900px) {
      width : 100%;
  }
`
const MoblieImgInner = styled.div`
    display : none;
    @media screen and (max-width: 900px) {
      display : block;
      width : 80%;
  }
`
const HomebgMB = styled.img`
    width : 100%;
`
const Homebg = styled.div`
  position : relative;
  width : 770px;
  height : 675px;
  background-image : url(${homebg});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 900px) {
      display : none;
  }
`
const Section1 = styled.div`
  position : absolute;
  top:0;
  z-index : 10;
`

const Section2 = styled.div`
  position : absolute;
  top:0;
  z-index : 20;
`


const Title1 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space1 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`
const bounce1 = keyframes`
  0% { top: 210px; }
  50% { top: 200px; }
  100% { top: 210px; }
`

const Point1 = styled.div`
  position : absolute;
  top: 210px;
  right: 270px;
  z-index : 100;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce1} 2s infinite linear;
`

const Title2 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space2 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`

const bounce2 = keyframes`
  0% { top: 335px; }
  50% { top: 325px; }
  100% { top: 335px; }
`

const Point2 = styled.div`
  position : absolute;
  top: 335px;
  left: 270px;
  z-index : 100;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce2} 2s infinite linear;
`

const PointItemimg = styled.div`
  width : 44px;
  height : 56px;
  background-image : url(${props => props.status ? pointer_on : pointer });
  background-repeat: no-repeat;
  background-size: cover;
  transition : .4s;
  cursor: pointer;
`

const RoomTextInner = styled.div`
  position : absolute;
  bottom : 0px;
  width : 100%;
  height : 52px;
  display : flex;
  justify-content : center;
`

const TextBox = styled.div`
  width: 206px;
  height: 52px;
  border-radius: 80px;
  background-color: #fff;
  display : flex;
  justify-content : center;
  align-items : center;
`

const RoomText = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color:  #000;
`




export default withRouter(Second_Showroom)