import styled, { keyframes } from 'styled-components';
import i2bIc from '../images/common/inquiry_icon.png'
import i2bClose from '../images/common/i2b_close_ic.png'
import { Modal, Radio, Checkbox, Select, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { postInquiry } from '../libs/inquiry'
import { useTranslation } from 'react-i18next'

export const InquiryModal = ({showModal, onCancel, onSucces, toggleModal}) => {
    
    const [isMobile, setIsMobile] = useState(false);
    const [pdSelect, setPdSelect] = useState(false);
    const [ctSelect, setCtSelect] = useState(0);
    
    const { Option } = Select;

    const [type, setType] = useState('Select')
    const [typeE, setTypeE] = useState(false)
    const [type2, setType2] = useState('Select')
    const [type2E, setType2E] = useState(false)
    const [nation, setNation] = useState('Select')
    const [nationE, setNationE] = useState(false)
    const [city, setCity] = useState(null)
    const [cityE, setCityE] = useState(false)
    const [preName, setPreName] = useState(null)
    const [preNameE, setPreNameE] = useState(null)
    const [name, setName] = useState(null)
    const [nameE, setNameE] = useState(null)
    const [company, setCompany] = useState(null)
    const [companyE, setCompanyE] = useState(null)
    const [grade, setGrade] = useState(null)
    const [gradeE, setGradeE] = useState(null)
    const [email, setEmail] = useState(null)
    const [emailE, setEmailE] = useState(null)
    const [phone, setPhone] = useState(null)
    const [phoneE, setPhoneE] = useState(null)
    const [content, setContent] = useState(null)
    const [route, setRoute] = useState('')
    const [routeEtc, setRouteEtc] = useState(null)
    const [agree, setAgree] = useState(false)
    const [agreeE, setAgreeE] = useState(false)

    useEffect(() => {

        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 767) setIsMobile(true)
                else setIsMobile(false)
            }
        }
        resizeHandle();

        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)

    }, [])

    // useEffect(() => {
    //     // 메뉴 모달창 오픈시 스크롤 막기
    //     const body = document.querySelector('body');
    //     if (isMobile && showModal) {
    //         body.style.overflow = 'hidden'
    //     } 
    //     if (!showModal) body.style.overflow = 'unset'
    // }, [showModal])

    const iqselectList = ["Select", "제품문의", "샘플문의", "홍보문의", "채용문의", 
                          "투자문의", "일반문의", "마케팅문의", "소상공인 대상 무상 설치 접수"
                        ]

    const iqChange = async (value) => {
        setTypeE(false)
        if (value === 'Select') {
            await setCtSelect(0);
        } else if (value === '제품문의' || value === '샘플문의' ) {
            await setCtSelect(1);
        } else { 
            await setCtSelect(2); 
        }

        if (value === '제품문의') {
            await setPdSelect(true);
        } else { await setPdSelect(false);  }
        
        if (value === '소상공인 대상 무상 설치 접수') {
            setContent('신청정보 =========================================\n\n상호명 : \n사업자번호 : \n업종 : \n매장 평수 : \n주소 : \m연락처 :')
        } else {
            setContent(null)
        }
        await setNation('Select')
        await setType2('Select')
        await setType(value)

    }

    const prselectList = ["Select", "General Lighting", "Automotive", "Display & Mobile", "IR / VCSEL"]
    const productChange = (value) => {  setType2(value);setType2E(false);  }


    const ctselectList = ["Select", "Africa Unity", "Albania", "Andorra", "Australia",
                          "Austria", "Azerbaijan", "Brazil", "Belarus", "Belgium",
                          "Bosnia And Herzegovina", "Bulgaria", "Cambodia", "Canada", "China",
                          "Croatia", "Czech-Republic", "Denmark", "Estonia", "Finland",
                          "France", "Georgia", "Germany", "Greece", "HongKong",
                          "Hungary", "Indonesia", "Ireland", "Israel", "Italy",
                          "Japan", "Laos", "Latvia", "Kazakhstan", "Korea",
                          "Lithuania", "Luxembourg", "Macedonia", "Malaysia", "Mexico",
                          "Monaco", "Myanmma", "New Zealand", "Netherland", "Norway",
                          "Philippine", "Poland", "Portugal", "Puerto Rico", "Romania",
                          "Russia", "Serbia", "Singapore", "Slovakia", "Slovenia",
                          "Spain", "Sweden", "Switzerland", "Taiwan", "Thailand",
                          "Turkey", "UK", "USA", "Ukraine", "Vietnam"
                        ]
    const ctChange01 = (value) => {  setNation(value);setNationE(false);  }


    const coselectList = ["Select", "NA", "CN", "EU", "JP", "KR"]                           
        
    const onNameChange = (e) => {
        setPreName(e.target.value);
        setPreNameE(false);
    };

    const onSubmitClick = async (e) => {
        if (type === 'Select') { setTypeE(true); return document.getElementById('inquiry_type').scrollIntoView(false) }
        else {
            if (type === '제품문의') {
                if (type2 === 'Select') { setType2E(true); return document.getElementById('inquiry_type').scrollIntoView(false) }
            }
        }
        if (nation === 'Select') { setNationE(true); return document.getElementById('inquiry_nation').scrollIntoView(false) }
        if (!city) { setCityE(true); return document.getElementById('inquiry_nation').scrollIntoView(false) }
        if (!preName) { setPreNameE(true); return document.getElementById('inquiry_name').scrollIntoView(false) }
        if (!name) { setNameE(true); return document.getElementById('inquiry_name').scrollIntoView(false) }
        if (!company) { setCompanyE(true); return document.getElementById('inquiry_company').scrollIntoView(false) }
        if (!grade) { setGradeE(true); return document.getElementById('inquiry_company').scrollIntoView(false) }
        if (!email) { setEmailE(true); return document.getElementById('inquiry_email').scrollIntoView(false) }
        if (!phone) { setPhoneE(true); return document.getElementById('inquiry_email').scrollIntoView(false) }
        
        if (route === '기타') {
            if (routeEtc) {
                await setRoute(route + ': ' + routeEtc)
            }
        }

        if (!agree) { setAgreeE(true); return document.getElementById('inquiry_agree').scrollIntoView(false) }

        let params = {
            type: type,
            nation: nation,
            city: city,
            name: preName + name,
            company: company,
            grade: grade,
            email: email,
            phone: phone,
            content: content,
            route: route
        }

        
        try {
            const result = await postInquiry(params)
            if (result.status === 200) {
                // Success
                alert('접수되었습니다.');
                onSucces();
            }
        } catch (err) {
            
        }
    }

    return (
        <Modalcustom width={isMobile ? '100%' : 976} 
        visible={showModal}
        footer={null}
        destroyOnClose={true}
        closeIcon={isMobile ? <CloseIco onClick={toggleModal}><img src={i2bClose} alt="close button" /></CloseIco> : null}
        onCancel={onCancel}>
            <MDTitleInner>
                <MDTitle>INQUIRY TO BUY</MDTitle>
            </MDTitleInner>
            <MDInner>

                {/* Inquiry */}
                <MDInquirySelect>
                    <MDIqLeft>
                        <MDLeftIqSection>
                            <MDIqLeftText id={'inquiry_type'}>문의구분<MDPoint>*</MDPoint></MDIqLeftText>
                        </MDLeftIqSection>
                        <MDRightIqSection01 empty={typeE} value={type} defaultValue="Select" onChange={iqChange}>
                            {iqselectList.map( (item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                        </MDRightIqSection01>
                    </MDIqLeft>
                    <MDIqRight status={pdSelect}>
                        <MDRightIqSection02 empty={type2E} value={type2} defaultValue="Select" onChange={productChange}>
                            {prselectList.map( (item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                        </MDRightIqSection02>
                    </MDIqRight>
                </MDInquirySelect>


                {/* Country */}
                <MDCountryInner>
                    <MDCtLeft>
                        <MDLeftCtSection>
                            <MDCtLeftText id={'inquiry_nation'}>국가<MDPoint>*</MDPoint></MDCtLeftText>
                        </MDLeftCtSection>
                        <MDRightCtSection01 empty={nationE} value={nation} defaultValue="Select" onChange={ctChange01}>
                            {ctSelect === 0 && (
                                <Option value="Select">Select</Option>
                            )}
                            {ctSelect === 1 && ctselectList.map( (item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                            {ctSelect === 2 && coselectList.map( (item, index) => {
                                return <Option value={item} key={index}>{item}</Option>
                            })}
                        </MDRightCtSection01>
                    </MDCtLeft>
                    <MDCtRight>
                        <MDCtText value={city} empty={cityE} onChange={e=>{setCityE(false);setCity(e.target.value)}} placeholder="도시 입력" maxLength={30} />
                    </MDCtRight>
                </MDCountryInner>

                {/* Name */}
                <MDNameInner>
                    <MDNameLeft>
                        <MDLeftNameSection>
                            <MDLeftNameText id={'inquiry_name'}>성함<MDPoint>*</MDPoint></MDLeftNameText>
                        </MDLeftNameSection>
                        <MDRightNameSection empty={preNameE}>
                            <Radio.Group onChange={onNameChange} value={preName} >
                                <MDRadio value={'Mr.'}>Mr.</MDRadio>
                                <MDRadio value={'Ms.'}>Ms.</MDRadio>
                                <MDRadio value={'Dr.'}>Dr.</MDRadio>
                            </Radio.Group>
                        </MDRightNameSection>
                    </MDNameLeft>
                    <MDNameRight>
                        <MDNameText empty={nameE} value={name} onChange={e=>{setNameE(false);setName(e.target.value)}} placeholder="이름" maxLength={30} />
                    </MDNameRight>
                </MDNameInner>


                {/* Company */}
                <MDCompanyInner>
                    <MDComLeft>
                        <MDLeftComSection>
                            <MDLeftComText id={'inquiry_company'}>회사<MDPoint>*</MDPoint></MDLeftComText>
                        </MDLeftComSection>
                        <MDComText empty={companyE} value={company} onChange={e=>{setCompanyE(false);setCompany(e.target.value)}} placeholder="입력해주세요." maxLength={30} />
                    </MDComLeft>
                    <MDJobRight>
                        <MDRightJobSection>
                            <MDRightJobText>직급<MDPoint>*</MDPoint></MDRightJobText>
                        </MDRightJobSection>
                        <MDJobText empty={gradeE} value={grade} onChange={e=>{setGradeE(false);setGrade(e.target.value)}} placeholder="입력해주세요." maxLength={30} />
                    </MDJobRight>
                </MDCompanyInner>

                {/* Contact */}
                <MDContactInner>
                    <MDEmailLeft>
                        <MDLeftEmailSection>
                            <MDLeftEmailText id={'inquiry_email'}>이메일<MDPoint>*</MDPoint></MDLeftEmailText>
                        </MDLeftEmailSection>
                        <MDEmailText empty={emailE} value={email} onChange={e=>{setEmailE(false);setEmail(e.target.value)}} placeholder="입력해주세요." maxLength={40} />
                    </MDEmailLeft>
                    <MDTelRight>
                        <MDRightTelSection>
                            <MDRightTelText>전화번호<MDPoint>*</MDPoint></MDRightTelText>
                        </MDRightTelSection>
                        <MDTelText empty={phoneE} value={phone} onChange={e=>{setPhoneE(false);setPhone(e.target.value)}} placeholder="입력해주세요." maxLength={24} />
                    </MDTelRight>
                </MDContactInner>

                {/* Container */}
                <MDContainerInner>
                    <MDAreaLeft>
                        <MDLeftAreaText>내용</MDLeftAreaText>
                    </MDAreaLeft>
                    <MDAreaText value={content} onChange={e=>setContent(e.target.value)} placeholder="입력해주세요." maxLength={1000} />
                </MDContainerInner>

                {/* WebSite */}
                <MDWebSiteInner>
                    <MDWebSection>
                        <MDLeftWebSection>
                            <MDLeftWebText>웹사이트 {isMobile ? null : <br/>}방문경로</MDLeftWebText>
                        </MDLeftWebSection>
                        <MDRightVertical value={route.split(',')} onChange={checkedValues=> {
                            let str = checkedValues.join(',')
                            setRoute(str)
                        }}>
                            <MDRightWebSection01>
                                <MDCheckSec><MDWebCheckBox value={'온라인 광고'}>온라인 광고</MDWebCheckBox></MDCheckSec>
                                <MDCheckSec><MDWebCheckBox value={'잡지 광고'}>잡지 광고</MDWebCheckBox></MDCheckSec>
                                <MDCheckSec><MDWebCheckBox value={'전시회'}>전시회</MDWebCheckBox></MDCheckSec>
                                <MDCheckSec><MDWebCheckBox value={'보도자료'}>보도자료</MDWebCheckBox></MDCheckSec>
                                <MDCheckSec><MDWebCheckBox value={'영업사원'}>영업사원</MDWebCheckBox></MDCheckSec>
                                <MDCheckSec><MDWebCheckBox value={'검색엔진'}>검색엔진</MDWebCheckBox></MDCheckSec>
                                <MDCheckETCSec>
                                    <MDWebCheckBox value={'(기타)'}>기타</MDWebCheckBox>
                                    <MDEtcText value={routeEtc} onChange={e=>{
                                        setRouteEtc(e.target.value)
                                    }} placeholder="기타 사유를 입력해주세요." maxLength={40} />
                                </MDCheckETCSec>
                            </MDRightWebSection01>
                        </MDRightVertical>
                    </MDWebSection>
                </MDWebSiteInner>
                
                {/* 개인정보 */}
                <MDPrivacyInner>
                    <MDLeftPrivacySec empty={agreeE}>
                        {!isMobile ? 
                        <MDLeftPriText id={'inquiry_agree'}>개인정보<br/>수집 및<br/>이용동의<MDPoint>*</MDPoint></MDLeftPriText> :
                        <MDLeftPriText id={'inquiry_agree'}>개인정보 수집 및 이용동의<MDPoint>*</MDPoint></MDLeftPriText>}
                    </MDLeftPrivacySec>
                    <MDRightPrivacySec empty={agreeE}>
                        <MDContentInner>
                            <MDContentText>1. 개인정보의 수집 이용목적<br/>회사 및 제품에 대한 궁금한 사항 문의에 대한 답변 업무 목적으로 개인정보를 처리합니다.<br/><br/>
                                            2. 수집하는 개인정보 항목<br/>수집정보 항목 : 국가, 성별, 이름, 회사명, 직업, 이메일, 전화번호<br/><br/>
                                            3. 개인정보의 보유 및 이용 기간<br/>입력하신 개인정보는 문의에 대한 답변 처리가 완료된 후 1년간 보관 후 삭제 합니다. 단, 지원자께서 직접 삭제를 요청하실 경우 즉시 해당 정보를 삭제합니다.<br/><br/>
                                            4. 동의를 거부할 권리 및 동의 거부에 따른 불이익<br/>고객께서는 개인정보 수집 및 이용에 동의하지 않을 권리가 있습니다. 다만, 이메일, 연락처 등의 개인정보를 입력하지 않은 고객문의에 대하여는 답변을 제공받지 못할 수 있습니다.<br/><br/>
                                            5. 개인정보 관리책임자 및 담당자<br/>서울반도체는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                            </MDContentText>
                        </MDContentInner>
                        <MDPriCheckInner>
                            <MDPriCheckBox checked={agree} onChange={e=>{setAgreeE(false);setAgree(e.target.checked)}}>위 개인정보 수집이용에 동의합니다.</MDPriCheckBox>
                        </MDPriCheckInner>
                    </MDRightPrivacySec>
                </MDPrivacyInner>

                {/* 등록하기 */}
                <ApplayInner>
                    <ApplayTextInner>
                        <ApplayBtn id={'inquiry_submit'} onClick={onSubmitClick}>등록하기</ApplayBtn>
                    </ApplayTextInner>
                </ApplayInner>

            </MDInner>
        </Modalcustom>
    )
}


const Inquiry = ({ ...props }) => {
    const { t } = useTranslation('common')
    const [showModal, setShowModal] = useState(false);
    
    const onSucces = () => {
        setShowModal(false);
    }
    
    return (
        <Wrapper >
            <IcoBox className={'inquiry_button'}
                    onClick={() => {
                        window.open(t('inquiry.href'), '_blank')
                        // setShowModal(!showModal)
                    }}>
                <Ico src={i2bIc} alt={t('inquiry.alt')} />
            </IcoBox>
            <InquiryModal onSucces={onSucces} onCancel={() => setShowModal(false)} toggleModal={() => setShowModal(!showModal)} showModal={showModal}/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
`
const IcoBox = styled.div`
    position: fixed;
    bottom: 10%;
    right: 5%;
    cursor: pointer;
    z-index: 99;
    display : block;

    @media screen and (max-width: 767px) {
        bottom: 16px;
        right: 16px;
        
    }
`

const Ico = styled.img`
    width: 80px;
    height: 80px;
    background-color: #8f0a7c;
    box-shadow: 0 0 5px #8f0a7c;
    border-radius: 40px;
    transition: .8s;
    opacity: 0.8;
    &:hover {
        background-color: #DE10C1;
        box-shadow: 0 0 20px #DE10C1;
        opacity: 1;
    }

    @media screen and (max-width: 767px) {
        width: 53px;
        height: 53px;
    }
`
// modal
const Modalcustom = styled(Modal)`
    .ant-modal-body {
        padding : 40px 0px 80px;
    }

    @media screen and (max-width: 767px) {
        .ant-modal-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
        }

        .ant-modal-body {
            height: calc(100vh - 52px);
            overflow-y: auto;
            padding : 0 0px 80px;
            margin-top: 52px;
        }
    }
`
const CloseIco = styled.div`
    width: 60px;
    height: 60px;
`
const MDTitleInner = styled.div`
    display : flex;
    justify-content : center;
`
const MDTitle = styled.div`
    font-size: 32px;
    font-weight: 900;
    line-height: 1.13;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;
    }
`

const MDInner = styled.form`
    margin-top : 59px;
    padding : 0px 68px;

    @media screen and (max-width: 767px) {
        margin-top: 40px;
        padding: 0 16px;
    }
`

// inQuiry
const MDInquirySelect = styled.div`
    width : 840px;
    display : flex;


    @media screen and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
    }
`
const MDIqLeft = styled.div`
    display : flex;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: auto;
    }
`
const MDLeftIqSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`
const MDIqLeftText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDRightIqSection01 = styled(Select)`
    width : 286px;
    display : flex;
    align-items : center;
    border-radius : 0px 4px 4px 0px;
    
    .ant-select-selector {
        height : 52px !important;
        display : flex;
        align-items : center;
        border: 1px solid ${props=>props.empty ? '#ff0000' : '#aaa'} !important;
        border-radius : 0px 4px 4px 0px !important;

        @media screen and (max-width: 767px) {
            height: 40px !important;
        }
    }
`

const MDIqRight = styled.div`
    display : ${props => props.status ? 'flex' : 'none'};
    width: 50%;
    margin-left : 28px;

    @media screen and (max-width: 767px) {
        width: auto;
        margin-left: 0;
        margin-top: 12px;
    }
`
const MDRightIqSection02 = styled(Select)`
    width : 406px;
    display : flex;
    align-items : center;
    
    .ant-select-selector {
        height : 52px !important;
        display : flex;
        align-items : center;
        border: 1px solid ${props=>props.empty ? '#ff0000' : '#aaa'} !important;
        border-radius : 4px !important;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 14px;

        .ant-select-selector {
            height : 40px !important;
        }
    }
`



// 국가
const MDCountryInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`

const MDCtLeft = styled.div`
    display : flex;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: auto;
    }
`

const MDLeftCtSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height : 40px !important;
    }
`

const MDCtLeftText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;
    
    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`


const MDRightCtSection01 = styled(Select)`
    width : 286px;
    display : flex;
    align-items : center;
    border-radius : 0px 4px 4px 0px;
    
    .ant-select-selector {
        height : 52px !important;
        display : flex;
        align-items : center;
        border: 1px solid ${props=>props.empty ? '#ff0000' : '#aaa'} !important;
        border-radius : 0px 4px 4px 0px !important;
    }

    @media screen and (max-width: 767px) {
        .ant-select-selector {
            height : 40px !important;
        }
    }
`



const MDCtRight = styled.div`
    width: 50%;
    margin-left : 28px;

    @media screen and (max-width: 767px) {
        width: auto;
        margin-left: 0;
        margin-top: 12px;
    }
`

const MDCtText = styled(Input)`
    width : 406px;
    height : 100%;
    border-radius: 4px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 10px 12px;
        font-size: 14px;
    }
`


// Name
const MDNameInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`
const MDNameLeft = styled.div`
    display : flex;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: auto;
    }
`
const MDLeftNameSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border : solid 1px #aaa;
    border-radius : 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

const MDLeftNameText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDRightNameSection = styled.div`
    padding-left : 16px;
    width : 286px;
    display : flex;
    align-items : center;
    ${props=>props.empty && 'border-bottom: 1px solid #ff0000;'}

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDRadio = styled(Radio)`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: #aaa;
    margin-right : 28px;

    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: #8f0a7c;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #8f0a7c;
    }
    .ant-radio-inner::after {
        background-color: #8f0a7c;
    }
    .ant-radio-checked::after {
        border: 1px solid #8f0a7c;
    }

    @media screen and (max-width: 767px) {
        margin: 0 10px;
    }
`

const MDNameRight = styled.div`
    width: 50%;
    margin-left : 28px;

    @media screen and (max-width: 767px) {
        width: auto;
        margin-left: 0;
        margin-top: 12px;
    }
`

const MDNameText = styled(Input)`
    width : 406px;
    height : 100%;
    border-radius: 4px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 10px 12px;
        font-size: 14px;
    }
`


// Company
const MDCompanyInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`

const MDComLeft= styled.div`
    display : flex;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: auto;
    }
`

const MDLeftComSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

const MDLeftComText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDComText = styled(Input)`
    width : 286px;
    height : 100%;
    border-radius: 0px 4px 4px 0px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        height: 40px;
        padding: 10px 12px;
    }
`

const MDJobRight = styled.div`
    display : flex;
    width: 50%;
    margin-left : 28px;

    @media screen and (max-width: 767px) {
        width: auto;
        margin-left: 0;
        margin-top: 32px;
    }
`

const MDRightJobSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

const MDRightJobText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDJobText = styled(Input)`
    width : 286px;
    height : 100%;
    border-radius: 0px 4px 4px 0px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        height: 40px;
        padding: 10px 12px;
    }
`



// Contact
const MDContactInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`

const MDEmailLeft = styled.div`
    display : flex;
    width: 50%;

    @media screen and (max-width: 767px) {
        width: auto;
    }
`

const MDLeftEmailSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

const MDLeftEmailText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDEmailText = styled(Input)`
    width : 286px;
    height : 100%;
    border-radius: 0px 4px 4px 0px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        height: 40px;
        padding: 10px 12px;
    }
`

const MDTelRight = styled.div`
    display : flex;
    width: 50%;
    margin-left : 28px;

    @media screen and (max-width: 767px) {
        width: auto;
        margin-left: 0;
        margin-top: 32px;
    }
`

const MDRightTelSection = styled.div`
    width : 120px;
    height : 52px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

const MDRightTelText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDTelText = styled(Input)`
    width : 286px;
    height : 100%;
    border-radius: 0px 4px 4px 0px;
    border: solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};

    @media screen and (max-width: 767px) {
        height: 40px;
        padding: 10px 12px;
    }
`


// Container
const MDContainerInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`


const MDAreaLeft = styled.div`
    width : 120px;
    height : 300px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border-top : solid 1px #aaa;
    border-left : solid 1px #aaa;
    border-bottom : solid 1px #aaa;
    border-radius : 4px 0px 0px 4px;

    @media screen and (max-width: 767px) {
        width: 96px;
        height: 40px;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid #aaa;
    }
`

const MDLeftAreaText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDAreaText = styled(Input.TextArea)`
    width : 720px;
    padding: 16px;
    border: solid 1px #aaa;
    border-radius : 0px 4px 4px 0px;
    outline-color: #8f0a7c;
    resize: none;

    @media screen and (max-width: 767px) {
        height: 300px !important;
        border-radius: 4px;
    }
`


// Web Site
const MDWebSiteInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;
    /* background : red; */

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`

const MDWebSection = styled.div`
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`

const MDLeftWebSection = styled.div`
    width : 120px;
    min-height : 116px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border : solid 1px #aaa;
    border-radius : 4px;
    /* background : green; */

    @media screen and (max-width: 767px) {
        width: 172px;
        height: 40px;
        min-height: auto;
    }
`

const MDLeftWebText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`
const MDRightVertical = styled(Checkbox.Group)`
    width: calc(100% - 120px);
    padding-left : 16px;

    @media screen and (max-width: 767px) {
        width: auto;
        padding-left: 0;
    }
`

const MDRightWebSection01 = styled.div`
    width : 100%;
    display : flex;
    align-items : center;
    flex-wrap: wrap;
    
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        height: auto;
    }
`

const MDWebCheckBox = styled(Checkbox)`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #aaa;
    .ant-checkbox + span{
        padding-left: 12px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color : #8f0a7c;
        border-color: #8f0a7c;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
    .ant-checkbox:hover .ant-checkbox-inner, 
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #8f0a7c;
    }

    @media screen and (max-width: 767px) {
        min-width: 73px;
        margin-right: 20px;
        font-size: 14px;
    }
`

const MDCheckSec = styled.div`
    width : 140px;
    height : 52px;
    display : flex;
    align-items : center;
    /* background-color : red; */

    @media screen and (max-width: 767px) {
        width: 50%;
        height: auto;
        margin-top: 28px;
    }
`

const MDCheckETCSec = styled.div`
    height : 52px;
    display : flex;
    align-items : center;

    @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
        flex-direction: row;
        margin-top: 28px;
    }
`


const MDEtcText = styled(Input)`
    width : 489px;
    height : 52px;
    border-radius: 4px;
    border: solid 1px #aaa;

    @media screen and (max-width: 767px) {
        height: 40px;
    }
`

// Privacy
const MDPrivacyInner = styled.div`
    margin-top : 28px;
    width : 100%;
    display : flex;

    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 32px;
    }
`

const MDLeftPrivacySec = styled.div`
    width : 120px;
    height : 300px;
    display : flex;
    justify-content : center;
    align-items : center;
    background-color: #fafafa;
    border : solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};
    border-radius : 4px 0px 0px 4px;
    text-align : center;

    @media screen and (max-width: 767px) {
        width: 198px;
        height: 40px;
    }
`
const MDLeftPriText = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #222;
    
    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

const MDRightPrivacySec = styled.div`
    padding : 0px 8px 0px 16px;
    width : 720px;
    height : 300px;
    border-top : solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};
    border-bottom : solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};
    border-right : solid 1px ${props=>props.empty ? '#ff0000' : '#aaa'};
    display : flex;
    border-radius : 0px 4px 4px 0px;
    flex-direction : column;

    @media screen and (max-width: 767px) {
        width: 100%;
        height: 370px;
        margin-top: 12px;
        border: 1px solid ${props=>props.empty ? '#ff0000' : '#aaa'};
        border-radius: 4px;
    }
`

const MDContentInner = styled.div`
    width : 100%;
    height : 202px;
    margin-top : 16px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        border: solid 1px #d0d0d0;
        box-shadow: inset 0px 0px 5px white;
    }


    @media screen and (max-width: 767px) {
        height: 280px;
    }
`

const MDContentText = styled.div`
    font-size: 14px;
    line-height: 1.14;
    color: #aaa;
`

const MDPriCheckInner = styled.div`
    margin-top : 24px;
    width : 100%;
    height : 20px;
`

const MDPriCheckBox = styled(Checkbox)`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #aaa;

    .ant-checkbox + span{
        padding-left: 12px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color : #8f0a7c;
        border-color: #8f0a7c;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
    .ant-checkbox:hover .ant-checkbox-inner, 
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #8f0a7c;
    }

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`



// Apply BTn
const Applyframes = keyframes`
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 400%;
    }
`

const ApplayInner = styled.div`
    margin-top : 60px;
    width : 100%;
    height : 67px;
    display : flex;
    justify-content : center;
    align-items : center;   

    @media screen and (max-width: 767px) {
        height: 61px;
        margin-top: 36px;
    }
`

const ApplayTextInner = styled.div`
    width : 361px;
    height : 100%;
    display : flex;
    justify-content : center;
    align-items : center;
    /* background-color : gray; */
`

const ApplayBtn = styled.a`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width : 100%;
    height : 100%;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.83;
    color: #fff;
    -webkit-box-pack: center;
    -webkit-justify-content: center;        
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    border-radius: 100px;
    overflow: hidden;
    outline: none;
    background: linear-gradient(112deg, #46ff98 -54%, #ab86ff 42%, #9f0588 115%);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
    
    &:hover {
        background: -webkit-linear-gradient(left, #9f0588, #ab86ff, #46ff98, #9f0588);
        background: linear-gradient(90deg, #9f0588, #ab86ff, #46ff98, #9f0588);
        background-size: 400%;
        z-index: 1;
        color: #fff;
        -webkit-animation: ${Applyframes} 8s linear infinite;
                animation: ${Applyframes} 8s linear infinite;
    }

    &::before {
        content: '';
        position: absolute;
        background: inherit;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border-radius: 50px;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="20" /></filter></svg>#filter');
        -webkit-filter: blur(20px);
                filter: blur(20px);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }

    &:hover::before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        z-index: -1;
    }

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
`

const MDPoint = styled.span`
    color : #8f0a7c;
`




export default withRouter(Inquiry)
