import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Carousel as AntdCarousel } from 'antd'
import MainMediaSlide from './MainMediaSlide'
import MainMediaArrow from './MainMediaArrow'
import { Fade } from 'react-reveal'
import { getMediaList } from '../libs/media'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

//-------------------------
// Page
//-------------------------
const MainMedia = ({ data, ...props }) => {
    const { t } = useTranslation('main')
    const alt = t('alt.home', { returnObjects: true })

    // Use State
    const defwidth = [567, 899]
    const defBannerHeight = [75, 120]
    const [bannerWidth, setBannerWidth] = useState(255)
    const [bannerHeight, setBannerHeight] = useState(120)
    const [current, setCurrent] = useState(0);
    const [list, setList] = useState(null);
    const slider = useRef()

    // Carousel Setting
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        beforeChange: (prev, next) => {
            setCurrent(next)
        },
        afterChange: (idx) => {

        },
        // autoplay: true,
        speed: 1200,
        autoplaySpeed: 1000,
        draggable: true,
        swipeToSlide: true,
        slidesToShow: 5,
        initialSlide: 0,
        centerMode: true,
        variableWidth: true,
        responsive: [{
            breakpoint: 1199,
            settings: {
                slidesToShow: 2
            }
        },{
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 567,
            settings: {
                slidesToShow: 1
            }
        }, {
            breakpoint: 374,
            settings: {
                slidesToShow: 1
            }
        }]
    }

    const onArrowClick = (type) => {
        if (type === 'prev') {
            slider.current.prev()
        } else if (type === 'next') {
            slider.current.next()
        }
    }

    //-------------------------
    // Functions
    //-------------------------
    // Size
    const handleSideSize = (def) => {
        const min = def[0]
        const max = def[1]
        const x = window.innerWidth
        if (typeof window !== 'undefined') {
            // More Height
            if (x < max) {
                const ratio = calcRatio(x, min, max)
                const resultY = calcBannerHeight(ratio, defBannerHeight[0], defBannerHeight[1])
                const resultX = 255 / 120 * resultY
                setBannerHeight(resultY)
                setBannerWidth(resultX)
            }
        }
    }

    // Calc Ratio
    const calcRatio = (x, min, max) => {
        return (x - min) / (max - min) * 100
    }

    // Calc Banner Height
    const calcBannerHeight = (x, min, max) => {
        let result = min + ((max - min) * x / 100)
        if (min < result) return result
        return min
    }

    //-------------------------
    // Use Effect
    //-------------------------
    useEffect(() => {
        handleSideSize(defwidth)
        
        window.addEventListener('resize', function() {
            handleSideSize(defwidth)
        })

        return () => {
            window.addEventListener('resize', function() {
                handleSideSize(defwidth)
            })
        }
    })

    useEffect(() => {
        const callList = async() => {
            const params = {
                category: 'all',
                offset: 0,
                limit: 10,
            }
            try {
                const result = await getMediaList(params, t('media.api'));
                if (result.status === 200) {
                    setList(result.data.rows)
                }
            } catch (err) {
            }
            
        }
        callList();
    }, [])

    const [mediaWidth, setMediaWidth] = useState(true);

    useEffect(() => {
        if(props.location.pathname === '/en') {
            setMediaWidth(false);
        } else {
            setMediaWidth(true);
        } 
    }, [])

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Fade bottom duration={1200} delay={100}>
                <SectionTitle>{t('media.title')}</SectionTitle>
            </Fade>
            <Fade bottom duration={1200} delay={300}>
                <CarouselWrapper>
                    <Carousel ref={slider} {...settings}>
                    {list ? list.map((item, idx) => {
                        return (
                            <Slide key={String(idx)}>
                                <MainMediaSlide
                                    {...props}
                                    index={idx}
                                    current={current}
                                    item={item} />
                            </Slide>
                        )
                    }) : ( <div></div> )}
                    </Carousel>
                    <ArrowPrev onClick={e=> onArrowClick('prev')}>
                        <MainMediaArrow type={'prev'}/>
                    </ArrowPrev>
                    <ArrowNext onClick={e=> onArrowClick('next')}>
                        <MainMediaArrow type={'next'}/>
                    </ArrowNext>
                </CarouselWrapper>
            </Fade>
            {props.currentLocale !== '/jp' &&
            <Fade bottom duration={1200} delay={500}>
                <More width={mediaWidth}>
                    {t('media', { returnObjects: true }).buttons.map((item, idx) => {
                        return (
                            <Item key={String(idx)} href={item.link}>
                                <Content
                                    pc={item.pc}
                                    mobile={item.mobile}   
                                    bannerWidth={bannerWidth}
                                    bannerHeight={bannerHeight}
                                    role='img'
                                    aria-label={alt.media[idx]}
                                    >
                                    <Title>{item.title}</Title>
                                    <Desc>{item.desc}</Desc>
                                </Content>
                            </Item>
                        )
                    })}
                </More>
            </Fade>}
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const SectionTitle = styled.h2`
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #000;
    margin-bottom: 82px;

    @media (max-width: 567px) {
        margin-bottom: 61px;
        font-size: 34px;
    }

    @media (max-width: 435px) {
        margin-bottom: 40px;
    }
`
const Wrapper = styled.div`
    width: 100%;
    background-color: white;
    overflow: hidden;   
`
const CarouselWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 4px;
    overflow: hidden;
    margin-bottom: 24px;
`
const Carousel = styled(AntdCarousel)`
    position: relative;
    width: 100%;
    height: 490px;

    @media (max-width: 567px) {
        height: 450px;
    }

    @media (max-width: 435px) {
        height: 400px;
    }
`
const Slide = styled.div`
    width: 100%;
    margin-top: 10px;

`
const ArrowPrev = styled.div`
    cursor: pointer;
    position: absolute;
    top: 34.3%;
    right: 75.9%;
    
    @media (max-width: 567px) {
        right: -90%;
    }
`
const ArrowNext = styled.div`
    cursor: pointer;
    position: absolute;
    top: 34.3%;
    left: 75.9%;

    @media (max-width: 567px) {
        left: -90%;
    }
`

//-------------------------
// More
//-------------------------
const More = styled.div`
    margin: 0 auto;
    display: flex;
    width: ${props => props.width ? '1118' : '861'}px;

    @media (max-width: 899px) {
        width: 100%;
        padding: 0 30px;
    }

    @media (max-width: 567px) {
        width: 100%;
        flex-direction: column;
    }

    @media (max-width: 374px) {
        padding: 0;
    }
`
const Item = styled.a`
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 899px) {
    }

    @media (max-width: 567px) {
        margin-top: 30px;

    }
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    width: 255px;
    height: 120px;
    padding: 24px;
    border-radius: 20px;
    background: url(${(props) => props.pc}) no-repeat center;
    background-size: 100%;
    cursor: pointer;
    -webkit-transition: background-size .4s ease-in;
    transition: background-size .4s ease-in;

    &:hover {
        background-size: 120%;
    }

    @media (max-width: 899px) {
        width: ${(props) => props.bannerWidth}px;
        height: ${(props) => props.bannerHeight}px;
    }

    @media (max-width: 567px) {
        width: 240px;
        height: 83px;
        background: url(${(props) => props.mobile}) no-repeat center;
        background-size: cover;
        border-radius: 15px;
    }

    @media (max-width: 374px) {
        width: 240px;
        height: 83px;
    }
`
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-top: 2px;

    @media (max-width: 899px) {
        margin-top: 4px;
        font-size: 16px;
    }

    @media (max-width: 567px) {
        margin-top: 0px;
        font-size: 20px;
    }
`
const Desc = styled.div`
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;

    @media (max-width: 899px) {
        margin-top: 2px;
        font-size: 12px;
    }

    @media (max-width: 567px) {
        margin-top: 8px;
        font-size: 14px;
    }
`

export default withRouter(MainMedia)