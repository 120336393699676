import React from 'react'
import styled from 'styled-components';
import Banner from '../components/Banner'
// import ex01 from '../images/media_ex3.png'

const ApplicationsDetail = () => {

    const data = {
        title: '‘바이오레즈’ UV LED 기술로 만든 무화학  ‘미니공기청정기’',
        date: '2021-10-02',
        views: '212',
        desc: `<img src='' /><br/><br/><p>오늘 11일 서울반도체와 서울바이오시스가 최근 락앤락이 출시한 ‘미니공기청정기’에 UV LED 혁신기술 ‘바이오레즈(Violeds)’를 공급했다고 밝혔다.</p><p>이 기술을 통해 운전자의 건강에 해로운 차량 내부 먼지, 습기 찬 매트의 바이러스균을 제거할 수 있으며, 탁월한 냄새 탈취 효능까지 얻을 수 있다.<br/>락앤락의 미니공기청정기는 초미세먼지까지 제거하는 우수한 공기 청정 기능과 공기 중 세균 감소 기능, 탈취 기능을 갖췄으며, 청정화 능력과 오존 발생 농도, 소음도의 기준을 모두 인정받아 한국공기청정협회(KACA)로부터 CA인증(실내공기청정기 단체표준인증)을 획득한 제품이다.</p>`
    }

    return (
        <Wrapper>
            <Banner type='applications' />

            <Contents>
                <DetailTitle>{data.title}</DetailTitle>
                <DetailInfo>
                    <DetailDate>{data.date}</DetailDate>
                    <DetailViews>Views {data.views}</DetailViews>
                </DetailInfo>

                <DetailDesc dangerouslySetInnerHTML={{__html: data.desc}}></DetailDesc>

                <MoreBtn href="/applications">&lt; 다른 제품 적용사례 보러가기</MoreBtn>
            </Contents>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const Contents = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 80px auto;

    @media screen and (max-width: 1199px) {
        padding: 0 16px;
        margin: 40px 0;
    }
`

const DetailTitle = styled.div`
    padding-bottom: 20px;
    font-size: 24px;
    color: #222;
    line-height: 1.33;
    font-weight: bold;
    word-break: keep-all;

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
`

const DetailInfo = styled.div`
    display: flex;
    color: #aaa;
    font-size: 14px;

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`

const DetailDate = styled.div`
    padding-right: 39px;

`
const DetailViews = styled.div`
`

const DetailDesc = styled.div`
    padding: 60px 0;
    color: #222;
    font-size: 16px;
    line-height: 1.5;
    word-break: keep-all;
    
    p {
        color: #222;
        font-size: 16px;
        line-height: 1.5;
        word-break: keep-all;

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    img {
        max-width: 853px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 40px 0;
        font-size: 14px;
    }
`

const MoreBtn = styled.a`
    display: block;
    width: 256px;
    padding: 16px 40px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 100px;
    box-shadow: 0 0 8px 0 rgba(193, 30, 170, 0.4);
    background-image: linear-gradient(114deg, #46ff98 -56%, #ab86ff 41%, #9f0588 116%);
    color: #fff;
    text-align: center;

    @media screen and (max-width: 767px) {
        margin: 0 auto;
    }
`

export default ApplicationsDetail