// Images
import Feature_01 from '../images/tech/tech_feature_01.png'
import Feature_02 from '../images/tech/tech_feature_02.png'
import Feature_03 from '../images/tech/tech_feature_03.png'
import Feature_04 from '../images/tech/tech_feature_04.png'
import Feature_05 from '../images/tech/tech_feature_05.png'
import Feature_06 from '../images/tech/tech_feature_06.png'
import Feature_07 from '../images/tech/tech_feature_07.png'
import Feature_08 from '../images/tech/tech_feature_08.png'
import Feature_09 from '../images/tech/tech_feature_09.png'
import Feature_10 from '../images/tech/tech_feature_10.png'
import Feature_11 from '../images/tech/tech_feature_11.png'
import Product_01 from '../images/tech/tech_product_01.png'
import Product_02 from '../images/tech/tech_product_02.png'
import Product_03 from '../images/tech/tech_product_03.png'
import Product_04 from '../images/tech/tech_product_04.png'
// import Product_05 from '../images/tech/tech_product_05.png'
import Product_06 from '../images/tech/tech_product_06.png'
import Product_07 from '../images/tech/tech_product_07.png'
import Product_08 from '../images/tech/tech_product_08.png'
import Product_09 from '../images/tech/tech_product_09.png'
import Product_10 from '../images/tech/tech_product_10.png'
import Product_11 from '../images/tech/tech_product_11.png'
import Product_12 from '../images/tech/tech_product_12.png'
import Product_13 from '../images/tech/tech_product_13.png'
import Example_01 from '../images/tech/tech_example_01.png'
// import Example_02 from '../images/tech/tech_example_02.png'
import Example_03 from '../images/tech/tech_example_03.png'
import Example_04 from '../images/tech/tech_example_04.png'
import Example_05 from '../images/tech/tech_example_05.png'
import Example_06 from '../images/tech/tech_example_06.png'
import Example_07 from '../images/tech/tech_example_07.png'
import Example_08 from '../images/tech/tech_example_08.png'
import Example_09 from '../images/tech/tech_example_09.png'
import Example_10 from '../images/tech/tech_example_10.png'
import Example_11 from '../images/tech/tech_example_11.png'
import Example_12 from '../images/tech/tech_example_12.png'
import Example_13 from '../images/tech/tech_example_13.png'
import Example_14 from '../images/tech/tech_example_14.png'

// Data
export default {
    main: {
        tab: ['살균', '탈취', '포충', '경화'],
        head: [{
            title: 'violeds | TECHNOLOGY - 살균',
            desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정 기술로 물, 공기, 표면 등의 살균 및 정화, 탈취를 위한 응용제품에 활용가능합니다.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 테크놀로지, technology, 살균, 99%살균력, 유해화학물질, 긴수명, 낮은소비전력, 저전력, 공기청정기, 가전제품, 정수기, 이동식공기청정기, 냉장고, 표면살균기'
        }, {
            title: 'violeds | TECHNOLOGY - 탈취',
            desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정 기술로 물, 공기, 표면 등의 살균 및 정화, 탈취를 위한 응용제품에 활용가능합니다.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 테크놀로지, technology, 탈취, 광촉매, 유해가스제거, 담배연기제거, 악취제거, 에어컨, 공기청정기, 식기세척기, 의류관리기'
        }, {
            title: 'violeds | TECHNOLOGY - 포충',
            desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정 기술로 물, 공기, 표면 등의 살균 및 정화, 탈취를 위한 응용제품에 활용가능합니다.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 테크놀로지, technology, 포충, 살균'
        }, {
            title: 'violeds | TECHNOLOGY - 경화',
            desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정 기술로 물, 공기, 표면 등의 살균 및 정화, 탈취를 위한 응용제품에 활용가능합니다.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 테크놀로지, technology, 살균, 102%살균력, 유해화학물질, 긴수명, 낮은소비전력, 저전력, 공기청정기, 가전제품, 정수기, 이동식공기청정기, 냉장고, 표면살균기'
        }]
    },
    sterilization: {
        category: '살균',
        header: '서울바이오시스<span style="font-weight: normal">는<br />세계 최고의 성능을 자랑하는</span> UVC LED<span style="font-weight: normal">와<br />유해 물질을 사용하지 않는</span> 친환경 기술을 이용하며,<br />표면 살균 및 물 살균 <span style="font-weight: normal">등의</span> solution<span style="font-weight: normal">을 제공합니다.</span>',
        headerMobile: '서울바이오시스<span style="font-weight: normal">는<br />세계 최고의 성능을 자랑하는</span> UVC LED<span style="font-weight: normal">와<br />유해 물질을 사용하지 않는</span>, 친환경 기술을<br />이용하며, 표면 살균 및 물 살균 <span style="font-weight: normal">등의</span><br />solution<span style="font-weight: normal">을 제공합니다.</span>',
        section: [{
            title: '특장점',
            contents: [{
                alt: '99.9% 살균력',
                image: Feature_01,
                title: '99% 살균력',
                desc: '대표적인 오염 지표세균인 대장균과 대표적인 식중독균인<br />황색포도상구균 살균 시 99.9% 살균력을 나타냅니다.',
                descMobile: '대표적인 오염 지표세균인 대장균과 대표적인 식중독균인 황색포도상구균 살균 시 99.9% 살균력을 나타냅니다.'
            },{
                alt: '유해 화학물질 Zero',
                image: Feature_02,
                title: '유해 화학물질 Zero',
                desc: '수은 및 유해 화학물질을 사용하지 않은 기술로서,<br />인체에 유해하지 않아 안심하고 사용이 가능합니다.',
                descMobile: '수은 및 유해 화학물질을 사용하지 않은 기술로서, 인체에 유해하지 않아 안심하고 사용이 가능합니다.',
            },{
                alt: '50,000hr의 긴 수명',
                image: Feature_03,
                title: '50,000hr의 긴 수명',
                desc: 'L50 기준 50,000hr의 긴 수명을 통해 제품 교체 비용을 절감 가능합니다.',
                descMobile: 'L50 기준 50,000hr의 긴 수명을 통해 제품 교체 비용을 절감 가능합니다.',
            },{
                alt: '낮은 소비전',
                image: Feature_04,
                title: '낮은 소비전력',
                desc: 'UV LED의 낮은 소비전력을 통해 전기료를 절감 가능합니다.',
                descMobile: 'UV LED의 낮은 소비전력을 통해 전기료를 절감 가능합니다.',
            }]
        }, {
            title: '제품군',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                title: 'PKG',
                items: [{
                    alt: 'CA PKG(1chip)',
                    image: Product_01,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }, {
                    alt: 'AAP PKG(1chip)',
                    image: Product_02,
                    name: 'AAP PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }, {
                    alt: 'AAP PKG(4chip)',
                    image: Product_03,
                    name: 'AAP PKG',
                    desc: '(4chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }, {
                title: '살균용 모듈',
                items: [{
                    alt: '살균모듈(방수형)',
                    image: Product_04,
                    name: '살균모듈',
                    desc: '(방수형)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }]
        }, {
            title: '적용사례',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                alt: '공기청정기',
                image: Example_01,
                name: '공기청정기',
                link: '/applications/sterilization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: '가전제품',
                image: Example_13,
                name: '가전제품',
                link: '/applications?product=homeAppliance#homeAppliance',
                width: '130'
            }, {
                alt: '정수기',
                image: Example_03,
                name: '정수기',
                link: '/applications/sterilization?product=waterPurifier#waterPurifier',
                width: '60'
            }, {
                alt: '이동식 공기청정기',
                image: Example_04,
                name: '이동식 공기청정기',
                link: '/applications/sterilization?product=portableAirpurifier#portableAirpurifier',
                width: '44'
            }, {
                alt: '에어컨',
                image: Example_07,
                name: '에어컨',
                link: '/applications/sterilization?product=airConditioner#airConditioner',
                width: '130'
            }, {
                alt: '표면 살균기',
                image: Example_06,
                name: '표면 살균기',
                link: '/applications/sterilization?product=disinfectingSurfaces#disinfectingSurfaces',
                width: '146'
            }]
        }]
    },

    deodorization: {
        category: '탈취',
        header: '서울바이오시스<span style="font-weight: normal">는<br />안정적인 출력과 높은 신뢰성을 가진</span> UV LED<span style="font-weight: normal">와</span> 광촉매 기술<span style="font-weight: normal">을 이용하여<br />기존의 탈취 기술보다</span> 유해가스 제거효율이 최대 8배 이상 높은 solution<span style="font-weight: normal">을 제공합니다.</span>',
        headerMobile: '서울바이오시스<span style="font-weight: normal">는<br />안정적인 출력과 높은 신뢰성을 가진</span><br />UV LED<span style="font-weight: normal">와</span> 광촉매 기술<span style="font-weight: normal">을 이용하여<br />기존의 탈취 기술보다</span> 유해가스 제거효율이<br />최대 8배 이상 높은 solution<span style="font-weight: normal">을 제공합니다.</span>',
        section: [{
            title: '특장점',
            contents: [{
                alt: '악취, VOCs 등 유해화학물질의 우수한 제거',
                image: Feature_05,
                title: '악취, VOCs 등 유해화학물질의 우수한 제거',
                desc: '악취,  VOCs* 등 유해화학물질을 제거하여 쾌적하고 건강한 환경을 제공합니다.<br />(*VOCs : 휘발성 유기화학물의 약자로 새집증후군, 호흡기계 질환 등 건강에 악영향을 미치는 물질)',
                descMobile: '악취,  VOCs* 등 유해화학물질을 제거하여 쾌적하고 건강한 환경을 제공합니다. (*VOCs : 휘발성 유기화학물의 약자로 새집증후군, 호흡기계 질환 등 건강에 악영향을 미치는 물질)',
            },{
                alt: '유해 화학물질 Zero',
                image: Feature_02,
                title: '유해 화학물질 Zero',
                desc: '수은 및 유해 화학물질을 사용하지 않은 기술로서, 인체에 유해하지 않아 안심하고 사용이 가능합니다.',
                descMobile: '수은 및 유해 화학물질을 사용하지 않은 기술로서, 인체에 유해하지 않아 안심하고 사용이 가능합니다.',
            },{
                alt: '경제적인 반영구필터',
                image: Feature_06,
                title: '경제적인 반영구필터',
                desc: '광촉매 필터는 반영구적으로 사용 가능하며,<br />20여 분간 물에 끓이기만 해도 새것 수준의 성능이 유지됩니다.',
                descMobile: '광촉매 필터는 반영구적으로 사용 가능하며, 20여 분간 물에 끓이기만 해도 새것 수준의 성능이 유지됩니다.',
            },{
                alt: '우수한 광촉매 코팅',
                image: Feature_07,
                title: '우수한 광촉매 코팅',
                desc: '지지체로부터 광촉매 물질이 탈리되지 않으며, 우수한 부착력이 유지됩니다.',
                descMobile: '지지체로부터 광촉매 물질이 탈리되지 않으며, 우수한 부착력이 유지됩니다.',
            }]
        }, {
            title: '제품군',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                title: '탈취용 모듈 (Customizing)',
                items: [{
                    alt: 'CMA-FBA-DK3A',
                    image: Product_11,
                    name: 'CMA-FBA-DK3A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }, {
                    alt: 'CMA-FBM-CO4A',
                    image: Product_12,
                    name: 'CMA-FBM-CO4A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }, {
                title: '광촉매필터 (Customizing)',
                items: [{
                    alt: 'PCF Series',
                    image: Product_13,
                    name: 'PCF Series',
                    desc: '',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }]
        }, {
            title: '적용사례',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                alt: '냉장고',
                image: Example_14,
                name: '냉장고',
                link: '/applications/deodorization?product=refrigerator#refrigerator',
                width: '130'
            }, {
                alt: '공기청정기',
                image: Example_01,
                name: '공기청정기',
                link: '/applications/deodorization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: '식기세척기',
                image: Example_08,
                name: '식기세척기',
                link: '/applications/deodorization?product=dishwasher#dishwasher',
                width: '130'
            }, {
                alt: '의류관리기',
                image: Example_09,
                name: '의류관리기',
                link: '/applications/deodorization?product=steamCloset#steamCloset',
                width: '130'
            }]
        }]
    },

    insectAttraction: {
        category: '포충',
        header: '서울바이오시스<span style="font-weight: normal">는 기존의 수은 램프를 이용한 해충유인 기술 대비</span><br />최대 13배 이상의 유인율을 갖는 UV LED 기술을<br />개발<span style="font-weight: normal">하였으며, 이 기술을 이용하여 고객의 needs에<br />최적화된</span> 실용적인 해충 유인 solution<span style="font-weight: normal">을 제공합니다.</span>',
        headerMobile: '서울바이오시스<span style="font-weight: normal">는<br />기존의 수은 램프를 이용한 해충유인 기술 대비</span><br />최대 13배 이상의 유인율을 갖는 UV LED 기술을<br />개발<span style="font-weight: normal">하였으며, 이 기술을 이용하여<br />고객의 needs에 최적화된</span><br />실용적인 해충 유인 solution<span style="font-weight: normal">을 제공합니다.</span>',
        section: [{
            title: '특장점',
            contents: [{
                alt: '최대 13배 높은 모기 포집 성능',
                image: Feature_08,
                title: '최대 13배 높은 모기 포집 성능',
                desc: 'ZIKA virus를 전염시키는 이집트숲모기를 기존의 수은램프 대비<br />최대 13배 높은 뛰어난 모기 포집 성능을 발휘합니다. (미국 플로리다 주립대학교 곤충연구실 실험결과)',
                descMobile: 'ZIKA virus를 전염시키는 이집트숲모기를 기존의 수은램프 대비 최대 13배 높은 뛰어난 모기 포집 성능을 발휘합니다. (미국 플로리다 주립대학교 곤충연구실 실험결과)',
            }, {
                alt: '유해 화학물질 Zero',
                image: Feature_02,
                title: '유해 화학물질 Zero',
                desc: '수은과 같은 중금속이나 살충제와 같은 화학물질을 사용하지 않으므로,<br />인체에 유해하지 않아 안심하고 사용할 수 있습니다.',
                descMobile: '수은과 같은 중금속이나 살충제와 같은 화학물질을 사용하지 않으므로, 인체에 유해하지 않아 안심하고 사용할 수 있습니다.',
            }, {
                alt: '긴 수명',
                image: Feature_03,
                title: '긴 수명',
                desc: 'L50기준 20,000hr의 긴 수명을 통해 제품의 유지관리 비용을 절감합니다.<br />(Z5 PKG 기준)',
                descMobile: 'L50기준 20,000hr의 긴 수명을 통해 제품의 유지관리 비용을 절감합니다.<br />(Z5 PKG 기준)',
            }, {
                alt: '낮은 소비전력',
                image: Feature_04,
                title: '낮은 소비전력',
                desc: '소비전력이 매우 낮으므로 전기사용료 부담이 거의 없습니다.',
                descMobile: '소비전력이 매우 낮으므로 전기사용료 부담이 거의 없습니다.',
            }]
        }, {
            title: '제품군',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                title: '포충용 모듈',
                items: [{
                    alt: 'Customizing 모듈(T8-436mm)',
                    image: Product_06,
                    name: 'Customizing 모듈',
                    desc: '(T8-436mm)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }]
        }, {
            title: '적용사례',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                alt: '포충기',
                image: Example_10,
                name: '포충기',
                link: '/applications/insect-attraction?product=insectTrap#insectTrap',
                width: '130'
            }]
        }]
    },
    
    curing: {
        category: '경화',
        header: '서울바이오시스<span style="font-weight: normal">는<br />UV 전 파장(255~405nm)</span><span style="font-weight: normal">을 아우르는</span>,<br />세계 유일의 UV LED 제조사<span style="font-weight: normal">로서<br />고객의 Needs에 최적화된</span> UV LED 경화 Solution<span style="font-weight: normal">을 제공합니다.</span>',
        headerMobile: '서울바이오시스<span style="font-weight: normal">는<br />UV 전 파장(255~405nm)</span><span style="font-weight: normal">을 아우르는</span>,<br />세계 유일의 UV LED 제조사<span style="font-weight: normal">로서<br />고객의 Needs에 최적화된</span><br />UV LED 경화 Solution<span style="font-weight: normal">을 제공합니다.</span>',
        section: [{
            title: '특장점',
            contents: [{
                alt: '전 UV 파장 영역대 (255~405nm) 생산 및 공급 가능',
                image: Feature_09,
                title: '전 UV 파장 영역대 (255~405nm) 생산 및 공급 가능',
                desc: '다양한 경화 재료 및 장비에 최적화된 UV 파장에 적합한 Chip,<br />PKG 및 Module 제공 가능합니다.',
                descMobile: '다양한 경화 재료 및 장비에 최적화된 UV 파장에 적합한 Chip, PKG 및 Module 제공 가능합니다.',
            },{
                alt: '장비 디자인의 유연성',
                image: Feature_10,
                title: '장비 디자인의 유연성',
                desc: '노광 Kit 또는 장비 제조사의 장비 design 시 용도나, 공정 조건에 부합되도록<br />Chip, PKG, COB 및 Module 등 어떠한 형태로도 공급이 가능합니다.',
                descMobile: '노광 Kit 또는 장비 제조사의 장비 design 시 용도나, 공정 조건에 부합되도록 Chip, PKG, COB 및 Module 등 어떠한 형태로도 공급이 가능합니다.',
            },{
                alt: '낮은 소비 전력 및 공정 편의성',
                image: Feature_04,
                title: '낮은 소비 전력 및 공정 편의성',
                desc: '기존 수은, Metal Halide 또는 Xenon Lamp에 비해 소비 전력이 최대 30% 수준이므로<br />공정비용 절감에 효과적이며 기존 광원들의 경우<br />ON 시 Delay Time 과 출력 안정화에 상당한 시간이 걸리는데 비하여<br />실시간(Instantaneous) ON/OFF 및 즉시 구동이 가능합니다.',
                descMobile: '기존 수은, Metal Halide 또는 Xenon Lamp에 비해 소비 전력이 최대 30% 수준이므로 공정비용 절감에 효과적이며 기존 광원들의 경우 ON 시 Delay Time 과 출력 안정화에 상당한 시간이 걸리는데 비하여 실시간(Instantaneous) ON/OFF 및 즉시 구동이 가능합니다.',
            },{
                alt: '작업 안전성',
                image: Feature_11,
                title: '작업 안전성',
                desc: '기존 UV Lamp에 비해 상대적으로 낮은 전압을 사용하므로<br />작업 시 감전 등의 위험이 적습니다.',
                descMobile: '기존 UV Lamp에 비해 상대적으로 낮은 전압을 사용하므로 작업 시 감전 등의 위험이 적습니다.',
            },{
                alt: '유해 화학물질 Zero',
                image: Feature_02,
                title: '유해 화학물질 Zero',
                desc: '기존 수은 램프와 다르게 수은 등<br />산업 유해 물질을 사용하지 않으므로 친환경적입니다.',
                descMobile: '기존 수은 램프와 다르게 수은 등 산업 유해 물질을 사용하지 않으므로 친환경적입니다.',
            }]
        }, {
            title: '제품군',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                title: 'PKG',
                items: [{
                    alt: 'Z5 PKG (1chip)',
                    image: Product_07,
                    name: 'Z5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }, {
                    alt: 'nZ5 PKG',
                    image: Product_08,
                    name: 'nZ5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }, {
                    alt: 'CA PKG (1chip)',
                    image: Product_09,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }, {
                title: 'Module',
                items: [{
                    alt: 'NCOB (9chip)',
                    image: Product_10,
                    name: 'NCOB',
                    desc: '(9chip)',
                    link: 'http://www.seoulviosys.com/kr/product/new.asp'
                }]
            }]
        }, {
            title: '적용사례',
            caution: '* 제품 선택 시 자세한 사항을 확인하실 수 있습니다.',
            readmore: '자세히 보기',
            contents: [{
                alt: '프린터',
                image: Example_11,
                name: '프린터',
                link: '/applications/curing?product=printer#printer',
                width: '130'
            }, {
                alt: '산업용경화기',
                image: Example_12,
                name: '산업용경화기',
                link: '/applications/curing?product=industrialCuring#industrialCuring',
                width: '130'
            }, {
                alt: '네일큐어 기기',
                image: Example_05,
                name: '네일큐어 기기',
                link: '/applications/curing?product=nailCuring#nailCuring',
                width: '82'
            }]
        }]
    }
}