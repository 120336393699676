import section3_img1 from '../../images/company/jp_company_vision_01.png'
import section3_img2 from '../../images/company/jp_company_vision_02.png'
import section3_img3 from '../../images/company/jp_company_vision_03.png'
import section3_img4 from '../../images/company/jp_company_vision_04.png'
import section3_img5 from '../../images/company/jp_company_vision_05.png'
import section3_img6 from '../../images/company/jp_company_vision_06.png'
import section3_img6_MB from '../../images/company/jp_company_vision_06_MB.png'

export default {
    alt: [
        'Seoul Viosys company view',    //일본어번역 필요
        '清潔',
        '礼儀',
        '遵守',
        '整頓',
        'チームワーク',
        '10 EXECUTION VALUE'
    ],
    meta: {
        title: 'violeds | ABOUT COMPANY - 会社概要',
        desc: 'ソウルバイオシスは、2019年の売上高が約3000億ウォンの世界的なLED専業メーカーで、世界のLED市場でトップクラスの地位を占めています。',
        keyword: 'violeds,Violeds,violeds,About company,company introduction,seoul viosys'  //일본어번역 필요
    },
    tabs: [{
        hash: '#Introduce', 
        title: '会社概要'
    }, {
        hash: '#message', 
        title: 'CEOメッセージ'
    }, {
        hash: '#vision', 
        title: 'Vision'
    }, {
        hash: '#history', 
        title: '社史'
    }],
    section1: {
        title1: {
            left: '従業員に',
            right: 'プライドを',
            pwidth : '205px',
            mwidth : '160px',
        },
        title2: {
            left: '顧客に',
            right: '価値創造を',
            pwidth : '205px',
            mwidth : '165px',
        },
        title3: {
            left: '株主に',
            right: '安全性を',
            pwidth : '166px',
            mwidth : '133px',
        },
        subtitle: {
            pc: 'そして世界に貢献できるように\n成長し続ける企業',
            mo: 'そして世界に貢献できるように\n成長し続ける企業'
        },
        desc: 'ソウルバイオシスは、2019年の売上高が約3000億ウォンの世界的なLED専業メーカーで、世界のLED市場でトップクラスの地位を占めています。約4000件の特許によって差別化された技術力で、多様な製品ポートフォリオを構築して、急変するLED市場で柔軟な対応をしており、現在、世界各地の営業拠点網を生かして高品質LED製品を提供しています。'
    },
    section2: {
        title: {
            left: 'CEO',
            right: 'メッセージ'
        },
        subtitle: '光に新しい歴史を！そして技術奪取のない世界のために 原!',
        desc1: {
            author: '創業者 Lee Chung-hoon(イ·ジョンフン)',
            content: '「世界に役立てる、きれいな光を提供しよう」という信念を持って、私たちは賃貸工場から創業し、幾多の危機をチャンスに変えることで乗り越え、今や200nm台の紫外線から1400nm台の赤外線レーザーダイオード(VCSEL)に至る全ての波長域に渡る光半導体製品を開発、生産する世界唯一の企業にまで成長致しました。これからは、自然の光で、健康な光、きれいな光、勉強のできる光、新しい光を人類に提供していきます。\n\n\n私にとって、農家の末息子として生まれた米国のジミー·カーター（農夫の息子）が大統領になったことが、 人生のターニングポイントになったように、これからは光の素敵な作品を夢見ながら、若者に希望の姿になることを願います。知的財産権(Intellectual Property)がMotivationになって、第1次、第2次、第3次産業革命が行われ、世界の絶対貧困人口の割合が80%台から10%未満に減り、光が提供され、人々が情報の80%を光によって得て、識字率の改善でより安全な生活を送ることができ、そして幼児死亡率も43%台から3%未満に低下しているように、より多くの知識財産権が尊重され、人類の暮らしがさらに良くなり、公正な社会へと階層間の移動もできるという希望を与えるために、今日も魂を込めて闘いながら作品を作っていきます。',
            top : '70px'
        },
        desc2: {
            author: 'ソウルバイオシス代表取締役(CEO) イ・ヨンジュ',
            content: 'ソウルバイオシスにご関心をお寄せいただき誠にありがとうございます。企業は世の中の役に立つ存在、顧客に価値を創出する存在、従業員がプライドを持てる職場、株主にとっては安定性のある存在であるべきだと考えています。\n\nソウルバイオシスは、2002年にUV LED事業を行う目的で設立され、Nitride SemiconductorとUVA（長波長紫外線）の共同開発および生産を開始したのを皮切りに、2005年には米国SETi社と資本協力をして、短波長紫外線UV UVC（400nm前半）を世界で初めて開発生産できる体制を整えました。以来15年以上にわたりUV LEDに重点的に取り組み、数多くの新しい特許技術製品や応用分野を開拓し、宇宙ステーション、医療産業、診断装置、スキンヘルス、バイオ分野などの特別な用途のためにコアとなるデバイスを供給してきました\n\n2015年には米国国防総省の国際兵器取引規制（ITAR）の審査や、米国外国投資委員会（CFIUS）の承認を受けました。これから本格的に人類の暮らしに役立つよう、これまでソウルバイオシスが研究開発してきた多様な応用分野の新技術とソウル半導体(株)の大量生産技術を融合し、コストイノベーションによって画期的な価格で世の中に供給することにより、地球の人々にキレイな世界！健康的な世界！美しい世界！を作っていきたいと思っております。',
            top : '50px'
        }
    },
    section3: {
        title: 'VISION',
        subtitle: 'WORLD TOP LED',
        standard: [
            section3_img1,
            section3_img2,
            section3_img3,
            section3_img4,
            section3_img5
        ],
        execution: {
            image: {
                pc: section3_img6,
                mo: section3_img6_MB
            }
        }
    },
    section4: {
        title: '社史',
        subtitle: 'We make the WORLD TOP LED history',
        items: [{
            year: '2016',
            desc: '▪︎ violeds技術を利 用した完成品発売'
        }, {
            year: '2015',
            desc: '▪︎ SETi社、\nNitekを買収、\nそのSETiにSVCが出資\n▪︎ WICOP2(Flip-chip) 量産発表'
        }, {
            year: '2014',
            desc: '▪︎ 特許 4,000件突破\n▪︎ violeds技術の適用拡大\n▪︎ Chip生産数月10億個突破'
        }, {
            year: '2013',
            desc: '▪︎ \"ソウルバイオシスは\" に社名変更\n▪︎ UV System Solution リリース\n▪︎ UVA領域の高出力LED生産\n(365nm, 375nm, 385 nm, 395nm)'
        }, {
            year: '2012',
            desc: '▪︎ 世界初の光束密度5倍のLED nPola量産発表\n▪︎ WICOP(Flip-chip) 量産発表'
        }, {
            year: '2011',
            desc: '▪︎ V-Chip 量産開始'
        }, {
            year: '2010',
            desc: '▪︎ 京畿道、\n安山市と投資了承念書\n(MOU)締結\n▪︎ 世界 LED界の碩学Nakamura\nShuji教授を技術諮問として委嘱'
        }, {
            year: '2009',
            desc: '▪︎ LED TV適用チッ\nプ量産の開始\n▪︎ NICHIA訴訟合意及び クロスライセンス契約の締結\n▪︎ Visible＋\nUVを合わせて十億以上の\nチップをボリューム生産 (Visible + UV)'
        }, {
            year: '2008',
            desc: '▪︎ ISO 14000認証取得\n▪︎ ACRICH 2008 韓国技術大賞受\n賞及び10大技術選定\n▪︎ ACRICH LED 業界初のアメリカ\nULによるRU認証取得'
        }, {
            year: '2007',
            desc: '▪︎ ISO/TS_1694 認証取得\n▪︎ 世界初255nm~340nm \nDeep UV LED量産開始\n▪︎ ACRICHチップ量産開始'
        }, {
            year: '2005',
            desc: '▪︎ USのベンチャー会社\nSETiへ資本投資\n▪︎ 世界初のDeep UV領域の大量量産を始める\n(275nm, 310nm, 340nm)\n▪︎ 世界初ACRICH\nチップの開発'
        }, {
            year: '2004',
            desc: '▪︎ ISO-9001, QS-9000認証取得'
        }, {
            year: '2003',
            desc: '▪︎ USのMajor大学と 高輝度LEDを共同開発'
        }, {
            year: '2002',
            desc: '▪︎ EPI,FAB,UV LED開発を立ち上げ\n▪︎ 日本UVメーカとの戦略 的なパートナーシップを採決\n▪︎ ソウルオプトデバイスは\nソウルセミコンの技術協力社として設立'
        }]
    }
}