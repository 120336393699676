import section3_img1 from '../images/company/company_vision_01.png'
import section3_img2 from '../images/company/company_vision_02.png'
import section3_img3 from '../images/company/company_vision_03.png'
import section3_img4 from '../images/company/company_vision_04.png'
import section3_img5 from '../images/company/company_vision_05.png'
import section3_img6 from '../images/company/company_vision_06.png'
import section3_img6_MB from '../images/company/company_vision_06_MB.png'

export default {
    alt: [
        '서울 바이오시스 기업 전경',
        '청결',
        '예의',
        '준수',
        '정돈',
        '팀웍',
        '10 EXECUTION VALUE'
    ],
    meta: {
        title: 'violeds | ABOUT COMPANY - 회사소개',
        desc: '서울바이오시스는 2016년 기준 매출 약 3,000억원, 전세계 LED 시장 Top class의 자리를 차지하고 있는 글로벌 LED 전문 기업입니다.',
        keyword: 'violeds,Violeds,바이오레즈,Aboutcompany,회사소개,서울바이오시스,바이오시스'
    },
    tabs: [{
        hash: '#Introduce', 
        title: '회사소개'
    }, {
        hash: '#message', 
        title: 'CEO 메시지'
    }, {
        hash: '#vision', 
        title: '비전'
    }, {
        hash: '#history', 
        title: '연혁'
    }],
    section1: {
        title1: {
            left: '임직원에게',
            right: '프라이드를',
            pwidth : '',
            mwidth : '',
        },
        title2: {
            left: '고객에게',
            right: '가치창조를',
            pwidth : '',
            mwidth : '',
        },
        title3: {
            left: '주주에게',
            right: '안정성을',
            pwidth : '151px',
            mwidth : '118px',
        },
        subtitle: {
            pc: '나아가 세상에 도움이 되도록\n끊임없이 성장하는 기업',
            mo: '나아가 세상에 도움이 되도록\n끊임없이 성장하는 기업'
        },
        desc: '서울바이오시스는 2019년 기준 매출액 3,506억원으로, 전세계 LED 시장 Top class의 자리를 차지하고 있는 글로벌 LED 전문 기업입니다. 약 4,000여 개의 특허를 통한 차별화된 기술력으로 다양한 제품 포트폴리오를 구축하여 급변하는 LED 시장에 유연히 대처하고 있으며, 현재 전세계의 해외 영업소 Network를 통해 고품질 LED제품을 제공 중입니다.'
    },
    section2: {
        title: {
            left: 'CEO',
            right: 'MESSAGE'
        },
        subtitle: '빛에 새로운 역사! 그리고 기술탈취 없는 세상을 위하여',
        desc1: {
            author: '창업자 이정훈',
            content: '“세상과 고객에 도움이 되는 빛을 만들자”, “함께하는 임직원들과 주주를 위하여 Value Up하자”라는 신념으로 임대공장에서 위기를 기회로 만들며 LED와 레이저다이오드에 글로벌 USD 1 Billion의 광반도체 기업으로 성장해 왔습니다.\n\n농부의 막내아들로 태어나 미국 지미 카터(농부의 아들)가 대통령이 되며 제 인생의 터닝포인트가 되었듯, 작지만 하나의 빛의 작품을 꿈꾸며 젊은이들에게 희망의 모습이 되기를 바래봅니다. 지적재산권(Intellectual Property)이 Motivation이 되어 1차, 2차, 3차 산업혁명이 이루어지고, 세계 절대빈곤 인구 비율이 줄고, 물 부족 및 문맹 해결에 도움이 되었듯이, 앞으로 보다 더 지식재산권이 존중되는 공정한 사회로 어려운 젊은 창업인들과 중소기업들이 거대기업들과 공정하게 경쟁하면서도 계층간 이동을 통해 크게 성장할 수도 있다는 사례를 만들기 위하여 오늘도 마지막 투혼을 합니다.\n\n46억년 전 지구의 시작과 함께한 빛! 단지 보는 것만이 아닌 지구상의 모든 동식물의 생체리듬, 면역체계와 연관된 빛(Photon)! 저희는 지금 적외선(IR) 1400nm의 레이저다이오드 VCSEL부터 자외선(UV LED) 200nm 대까지 전파장의 빛(Photon)과 그 응용분야 솔루션을 개발 생산하는 세계 유일한 기업입니다. 앞으로도 “빛(Photon)으로 Clean한 세상, 건강한 세상”을 만드는데 더 유용하게 쓰이도록  한발 한발 앞으로 나아 갑니다.',
            top : ''
        },
        desc2: {
            author: '대표이사 이영주',
            content: '저희 서울바이오시스에 관심 가져 주시어 감사합니다. 기업은 세상에 도움이 되어야 하고, 고객의 가치를 창출할 수 있어야 하며, 같이 하는 식구(임직원) 들이 프라이드를 갖도록 하여야 하며, 주주의 안정성을 확보해야 한다고 생각합니다. 나아가 젊은 후배들에게 희망의 모델이 되는 인생의 작품을 만들고 싶습니다.\n\n서울바이오시스는 2002년 UV LED 비즈니스를 위해 설립하고, 일본 NS사와 UVA(장파장 자외선)의 공동개발 및 생산을 시작으로, 2005년에는 미국 SETi사와 자본협력을 하고, 전 파장의 자외선 UV ABC (장파장(400nm 초반)에서 단파장(200nm대) 자외선 LED)까지를 세계 최초로 개발 생산할 수 있는 체계를 갖추었습니다. 그 후 지난 15년 넘게 UV LED에 집중하며 수많은 새로운 특허 기술 제품과 응용분야를 개척하며 우주정거장은 물론 의료산업, 각종 진단, 피부건강, BIO 분야 등 특수 Application 용에 핵심소자를 공급하여 왔습니다.\n\n그리고 2015년에 미국 국방부의 국제무기거래규정 (ITAR)의 심사와 미국 외국투자위원회 (CFIUS)의 승인을 받고, 미 SETi사에 대지분을 투자했습니다. 이제 본격적으로 인류의 삶에 도움이 되도록 그 동안 서울바이오시스가 연구개발한 다양한 응용분야의 신기술과 서울반도체(주)의 대량 생산 기술을 접목하여 원가혁신을 통해 획기적인 가격으로 세상에 공급함으로써, violeds기술로 지구인들에게 깨끗한 세상! 건강한 세상! 아름다운 세상!을 만들어 가고자 합니다.',
            top : ''
        }
    },
    section3: {
        title: 'VISION',
        subtitle: 'WORLD TOP LED',
        standard: [
            section3_img1,
            section3_img2,
            section3_img3,
            section3_img4,
            section3_img5
        ],
        execution: {
            image: {
                pc: section3_img6,
                mo: section3_img6_MB
            }
        }
    },
    section4: {
        title: 'HISTORY',
        subtitle: 'WORLD TOP LED로 나아갑니다',
        items: [{
            year: '2023',
            desc: 'World Top LED'
        }, {
            year: '2020',
            desc: '서울 바이오시스\n코스닥 상장'
        }, {
            year: '2017',
            desc: 'UV LED 글로벌 순위\n1위 달성'
        }, {
            year: '2017',
            desc: '베트남 하남성에\n베트남 생산 공장 설립'
        }, {
            year: '2014',
            desc: '10년동안\n서울반도체 기술 고문 역할을 맡은 나카무라 수지교수 노벨 물리학상수상'
        }, {
            year: '2014',
            desc: '적용 기술 확장'
        }, {
            year: '2013',
            desc: '10,000여 개 특허 보유\nIEEE 선정 LED 부품 제조업체 세계 최고 특허 파워회사 세계 5위 진입'
        }, {
            year: '2005~2011',
            desc: '세계최고의 품질을 바탕으로\nIP소송 승소'
        }, {
            year: '2005',
            desc: '세계최고의 UVC업체\n\'SETI\' 인수 합병'
        }, {
            year: '2004',
            desc: '세계순위 10위 진입'
        }, {
            year: '2003',
            desc: '중국 텐진에\n광명반도체유한공사 설립'
        }, {
            year: '2002',
            desc: 'Niteride Semiconductor와\nUV LED 기술협력\n서울반도체 코스닥 상장'
        }, {
            year: '2001',
            desc: '서울 바이오시스 설립\n(현재 UVC 분야 세계 1위)'
        }, {
            year: '1992',
            desc: '서울 건물의 한쪽을\n임대하여 설립'
        }]
    }
}