import React from 'react'
import styled from 'styled-components'
import Category_Article from '../images/main/main_media_article.png'
import Category_Card from '../images/main/main_media_card.png'
import Category_Video from '../images/main/main_media_video.png'
import { Switch, Case } from 'react-if';
import moment from 'moment'

//-------------------------
// Page
//-------------------------
const MainMediaSlide = ({ index, current, item, ...props }) => {
    //-------------------------
    // Functions
    //-------------------------
    // Image Type
    const getImageType = (c) => {
        let content = JSON.parse(c)
        let image = null
        if (content.type === 'article') image = Category_Article
        else if (content.type === 'card') image = Category_Card
        else if (content.type === 'video') image = Category_Video
        return image
    }

    // Click Link
    const clickLink = (link) => {
        window.location.href = link
        // window.open(link)
    }

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper className="main_media_item" isCenter={current === index} onClick={e=> clickLink(`${props.currentLocale}/media/${item.mediaIdx}`) }>
            <SlideWrap
                >
                <ImageWrap isCenter={current === index}>
                    <Image img={item.thumbnail} />
                </ImageWrap>
                <Content>
                    <Title>{item.title}</Title>
                    <Desc dangerouslySetInnerHTML={{__html: item.summary}} />
                    <Bottom>
                        <Category>
                            <ImageType src={getImageType(item.content)} alt={JSON.parse(item.content).type} />
                        </Category>
                        <Date>
                            <DateSpan>
                            {moment(item.createdAt).format('YYYY. MM. DD')}
                            </DateSpan>
                        </Date>
                    </Bottom>
                </Content>
            </SlideWrap>
            <Cover isCenter={current === index} />
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    position: relative;
    width: 360px;
    height: 425px;
    ${props=>props.isCenter && 'transform: scale(1.07);'}
    margin: 20px 20px 30px 20px;
    transition: .3s;
    overflow: hidden;
    cursor: pointer;
    border-radius: 12px;
    border: solid 1px #d0d0d0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

    &:before {
        content: '';
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
    }
    
    ${props=>props.isCenter && `
        box-shadow: 0 0 16px 0 rgba(34, 34, 34, 0.4);
    `}

    ${props=>props.isCenter && `&:before {
        background-color: #000000;
    }`}

    ${props=>props.isCenter && `p {
        color: #fff;
    }`}

    @media (max-width: 567px) {
        width: 300px;
        height: 354px;
    }

    @media (max-width: 435px) {
        width: 250px;
        height: 327px;
    }
`
const Cover = styled.div`
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`

//-------------------------
// Wrap
//-------------------------
const SlideWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

//-------------------------
// Slide
//-------------------------
const ImageWrap = styled.div`
    display: flex;
    width: 100%;
    background-color: #e6e6e6;
    ${props=>props.isCenter && 'background-color: #000;'}
`
const Image = styled.div`
    display: flex;
    width: 100%;
    height: 0;
    padding-top: calc(360 / 640 * 100%);
    background: url(${props => props.img}) no-repeat center;
    background-size: contain;
    ${props => props.img == null ? 'background-color: #e6e6e6' : null };
`
const Content = styled.div`
    width: 100%;
    padding: 20px;

    @media (max-width: 567px) {
        padding: 12px;
    }

    @media (max-width: 374px) {
        padding: 10px;
    }
`
const Title = styled.p`
    margin-top: 4px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    height: 44px;
    overflow: hidden;
    color: #393939;
`
const Desc = styled.p`
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.57;
    text-align: left;
    color: #393939;
    height: 60px;
    overflow: hidden;
    margin-block-end: 0em;
`
const Bottom = styled.div`
    margin-top: 16px;
    display: flex;

    @media (max-width: 567px) {
        margin-top: 4px;
    }

    @media (max-width: 374px) {
        margin-top: 2px;
    }
`
const Category = styled.div`
    flex: 1;
`
const ImageType = styled.img`
    width: 36px;
    margin-left: -5px;

    @media (max-width: 567px) {
        margin-left: -6px;
    }
`
const Date = styled.div`
    display: flex;
    flex: 1;
    justify-content: right;
    align-items: center;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    color: #aaa;
    margin-right: 5px;

    @media (max-width: 567px) {
        margin-right: 0px;
    }
`
const DateSpan = styled.span`
    width: 100%;
    text-align: right;
`

export default MainMediaSlide