const Media = {
    header: {
        all: {
            title: 'violeds | MEDIA - 全体',
            desc: '今やvioleds技術は私たちの日々の生活にとって不可欠なものとなっています。私たちが持つ様々なチャネルを通じてより身近になったvioleds技術をお試しください。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、メディア、media、インフルエンサー'
        }
    },
    category: ['プレスリリース', null, null, null],
    mediaType: ['アーティクル', 'カードニュース', '動画'],
    list: {
        next: '次の',
        prev: '移転',
        golist: '目録で',
        noNext: '前のポストがありません。',
        noPrev: '次のポストがありません。',
        related: '関連コンテンツ'
    },
    api: {
        list: '/media/jp/list',
        detail: '/media/jp',
        preNext: '/media/jp/pre-next',
        related: '/media/jp/relative'
    }
}

export default Media