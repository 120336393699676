import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useParams, withRouter } from 'react-router-dom'
import MenuIc from '../images/vr/menu_ico.png'
import Menubar from '../components_vr/Menubar'
import HomeIc from '../images/vr/vr_home_ic.png'
import NavArrowIc from '../images/vr/vr_nav_arrow.png'
import Dropdown from '../components_vr/Dropdown'
import LogoImg from '../images/common/header_logo.png'

import { useTranslation } from 'react-i18next'

const GNB_VR = ({ currentLocale, ...props }) => {

    const { t } = useTranslation('application_vr');
    
    const [space, setSpace] = useState('')
    const [room, setRoom] = useState('')
    const [currentSpaceName, setCurrentSpaceName] = useState((currentSpaceName) => {
        if (props.location.pathname === `${currentLocale}/showroom/laundry-room` 
            || props.location.pathname === `${currentLocale}/showroom/kitchen`
            || props.location.pathname === `${currentLocale}/showroom/living-room`
            || props.location.pathname === `${currentLocale}/showroom/bedroom`
            || props.location.pathname === `${currentLocale}/showroom/bathroom`) {
            return 'Home'
        } 
        if (props.location.pathname === `${currentLocale}/showroom/rs-kitchen`
                    || props.location.pathname === `${currentLocale}/showroom/rs-hall`) {
            return 'Cafeteria'
        }
        return ''
    })
    const [currentRoomName, setCurrentRoomName] = useState((currentRoomName) => {
        if (props.location.pathname === `${currentLocale}/showroom/laundry-room`)   return 'Laundry Room'
        if (props.location.pathname === `${currentLocale}/showroom/kitchen`)   return 'Kitchen'
        if (props.location.pathname === `${currentLocale}/showroom/living-room`)   return 'Living Room'
        if (props.location.pathname === `${currentLocale}/showroom/bathroom`)   return 'Bathroom'
        if (props.location.pathname === `${currentLocale}/showroom/bedroom`)   return 'Bedroom'
        if (props.location.pathname === `${currentLocale}/showroom/rs-kitchen`)   return 'Kitchen'
        if (props.location.pathname === `${currentLocale}/showroom/rs-hall`)   return 'Hall'
        return ''
    })
    const [showMenu, setShowMenu] = useState(false);

    const spaceList = {
        Home: {
            name: 'Home',
            url: `${currentLocale}/showroom/kitchen`,
            roomList: [{
                name: 'Laundry Room',
                url: `${currentLocale}/showroom/laundry-room`
            }, {
                name: 'Kitchen',
                url: `${currentLocale}/showroom/kitchen`
            }, {
                name: 'Living Room',
                url: `${currentLocale}/showroom/living-room`
            }, {
                name: 'Bathroom',
                url: `${currentLocale}/showroom/bathroom`
            }, {
                name: 'Bedroom',
                url: `${currentLocale}/showroom/bedroom`
            }]
        }, 
        Cafeteria: {
            name: 'Cafeteria',
            url: `${currentLocale}/showroom/rs-kitchen`,
            roomList: [{
                name: 'Kitchen',
                url: `${currentLocale}/showroom/rs-kitchen`
            }, {
                name: 'Hall',
                url: `${currentLocale}/showroom/rs-hall`
            }]
        }
    }

    useEffect(() => {
        // room setting
        if (currentLocale) {
            setRoom(props.location.pathname.substring(13))
        } else {
            setRoom(props.location.pathname.substring(10))
        }
        
        // space setting
        if (props.location.pathname === `${currentLocale}/showroom/laundry-room` 
            || props.location.pathname === `${currentLocale}/showroom/kitchen`
            || props.location.pathname === `${currentLocale}/showroom/living-room`
            || props.location.pathname === `${currentLocale}/showroom/bedroom`
            || props.location.pathname === `${currentLocale}/showroom/bathroom`) {
            setSpace('home');
        } else if (props.location.pathname === `${currentLocale}/showroom/rs-kitchen`
                    || props.location.pathname === `${currentLocale}/showroom/rs-hall`) {
            setSpace('cafeteria');
        }
    }, [props.location.pathname])
    
    return (
        <Wrapper>
            <GnbWrap>
                <Side>
                    <NavArea>
                        <HomeBtn href={`${currentLocale}/showroom`}>
                            <img src={HomeIc} />
                        </HomeBtn>
                        <NavArow src={NavArrowIc} />
                        <Dropdown 
                        level={1} 
                        title={currentSpaceName}
                        spaceName={currentSpaceName}
                        spaceList={spaceList} />
                        <NavArow src={NavArrowIc} />
                        <Dropdown 
                        level={2} 
                        big={true} 
                        title={currentRoomName}
                        spaceName={currentSpaceName}
                        spaceList={spaceList} />
                    </NavArea>
                </Side>
                <Logo>
                    <Logoimg src={LogoImg} alt={t('footer.logo.alt')} />
                </Logo>
                <Side right>
                    <MenuBtn src={MenuIc} onClick={() => setShowMenu(true)} alt="menu button"/>
                </Side>
            </GnbWrap>

            <Menubar 
                showMenu={showMenu}
                data={t('data', { returnObjects: true })} 
                space={space}
                room={room}
                category={t('category', { returnObjects: true })} 
                currentLocale={currentLocale}
                onCloseMenu={() => setShowMenu(false)} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
`

const GnbWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 20px 40px;

    @media screen and (max-width: 900px) and (orientation: landscape)  {
        height: 40px;
        padding: 0 28px;
    }
`

const Logo = styled.div`
    width : 120px;
    height : 32px;
    
    @media screen and (max-width: 900px) and (orientation: landscape) {
        width : 80px;
        height : 21px;
        display: none;
    }
`

const Logoimg = styled.img`
    width : 100%;
    height : 100%;
    margin-top: -6px;
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    padding-top: 5px;

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
`

const Side = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    ${props=>props.right && 'justify-content: flex-end;'}
    @media screen and (max-width: 900px) and (orientation: landscape)  {
        width: 140px;
    }
`

const NavArea = styled.div`
    display: flex;
    align-items: center;

`

const MenuBtn = styled.img`
    width: 36px;
    height: 36px;
    cursor: pointer;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 24px;
        height: 24px;
    }
`

const HomeBtn = styled.a`
    img {
        width: 34px;
        height: 36px;
        margin-right: 10px;
        @media screen and (max-width: 900px) and (orientation: landscape) {
            width: 28px;
            height: 30px;
        }
    }

    
`

const NavArow = styled.img`
    width: 18x;
    height: 36px;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 13px;
        height: 26px;
    }
`



export default withRouter(GNB_VR)