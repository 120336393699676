import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TechFeature from '../components/tech/TechFeature'
import TechProduct from '../components/tech/TechProduct'
import TechExample from '../components/tech/TechExample'
import { Fade } from 'react-reveal';
import { useTranslation } from 'react-i18next'

//-------------------------
// Page
//-------------------------
const TechMain = ({ data }) => {
    const { t } = useTranslation('main')
    const alt = t('alt.tech', { returnObjects: true })
    const [headerText, setHeaderText] = useState(data.header)

    //-------------------------
    // Functions
    //-------------------------
    // Size
    const handleSideSize = () => {
        if (window.innerWidth < 768) setHeaderText(data.headerMobile)
        else setHeaderText(data.header)
    }

    //-------------------------
    // Use Effect
    //-------------------------
    useEffect(() => {
        handleSideSize()
        window.addEventListener('resize', handleSideSize)

        return () => {
            window.addEventListener('resize', handleSideSize)
        }
    })

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Main>
            {(data) ? (
                <>
                <Fade bottom delay={200} duration={1500}>
                    <Header dangerouslySetInnerHTML={{__html: headerText}} />
                </Fade>
                <Fade bottom delay={400} duration={1500}>
                    <Section title={data.section[0].title}>
                        <TechFeature data={data.section[0]} alt={alt} />
                    </Section>
                </Fade>
                <Fade bottom delay={400} duration={1500}>
                <Section title={data.section[1].title}>
                    <TechProduct data={data.section[1]} alt={alt} />
                </Section>
                </Fade>
                {(data.section[2]) ? (
                    <Fade bottom delay={400} duration={1500}>
                        <Section title={data.section[2].title}>
                            <TechExample data={data.section[2]} alt={alt} />
                        </Section>
                    </Fade>
                ) : ( <div></div> )}
                </>
            ) : ( <div></div> )}
            </Main>
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`
const Main = styled.div`
    padding: 47px 100px 0px;

    @media (max-width: 1199px) {
        padding: 40px 60px 0px;
    }

    @media (max-width: 767px) {
        padding: 20px 40px 0px;
    }

    @media (max-width: 567px) {
        padding: 15px 25px 0px;
    }

    @media (max-width: 374px) {
        padding: 10px 15px 0px;
    }
`
const Header = styled.p`
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #222;

    @media (max-width: 567px) {
        font-size: 16px;
    }

    @media (max-width: 374px) {
        font-size: 14px;
    }
`
const Section = styled.div`
    position: relative;
    margin: 80px auto;
    border-radius: 12px;
    border: solid 0.5px #8f0a7c;
    padding: 55px 80px 55px;

    &:before {
        content: '${(props) => props.title}';
        display: flex;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
        min-width: 240px;
        width: 170px;
        height: 50px;
        background-image: linear-gradient(315deg, #d183c6, #9c9aff);
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        box-shadow: 0px 5px 18px 1px rgba(0, 0, 255, .3);

        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    @media (max-width: 767px) {
        margin: 72px auto 60px;
        padding: 55px 60px 24px;
    }

    @media (max-width: 567px) {
        margin: 72px auto 60px;
        padding: 30px 24px 24px;

        &:before {
            min-width: 150px;
            width: 120px;
            height: 36px;
            font-size: 16px;
            top: -20px;
        }
    }

    @media (max-width: 374px) {
        margin: 72px auto 60px;
        padding: 10px 7px 10px;

        &:before {
            min-width: 120px;
            width: 90px;
            height: 27px;
            font-size: 14px;
            top: -16px;
        }
    }
`

export default TechMain