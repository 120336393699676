import styled from 'styled-components';
import React ,{ useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Head from '../components/Head'

const Privacy = ({ ...props }) => {
    
    if (props.currentLocale === '/en') {
        return (
            <ContentWrapper>
            <Head
                title={'violeds | Privacy Policy'}
                description={"Privacy Policy"}
                keyword={''}
                lang={props.currentLocale}
            />
            <h3><strong>PRIVACY POLICY</strong></h3>
            <p>&nbsp;</p>
            <p>This Privacy Policy explains how Seoul Viosys Co., Ltd. (“SVC”, “we”, “our,” or “us”) process your personal information, including how we collect, use and process your personal information, and how, in doing so, we comply with our legal obligations.</p>
            <p>This Privacy Policy also describes your data protection rights, including a right to object to some of the processing. More information about your rights, and how to exercise them, is set out in the “Your Rights” section.</p>
            <p>This Privacy Policy applies only to the websites and services controlled by SVC where this Privacy Policy is posted or referenced and through information collected via VIOLEDS website “https://violeds.com/en”(“Website”). This Privacy Policy does not pertain to other information that is collected offline or to information that is posted or provided by third parties.</p>
            <p>&nbsp;</p>
            <h4><strong>A. Information to be collected and method of collection</strong></h4>
            <p>(1) Personal information items to be collected</p>
            <ul>
            <li><strong> Required Personal Information</strong></li>
            </ul>
            <p>Cookies will be generated and placed on your browser when you are visiting our Website. For more detailed information, please see our Cookie Policy placed on our Website.</p>
            <p>In addition, in the course of using services provided on our Website, the information as described in the following may be created and collected: Log information (IP address, log data, use time, search word input, internet protocol address, and web beacon).</p>
            <p>Other than cookies and the log information as described above, there is no personal information that will be required or collected from you in using our Website. You may access the majority of the Website without the need to sign up for membership.</p>
            <p><strong>&nbsp;</strong></p>
            <ul>
            <li><strong> Optional Personal Information </strong></li>
            </ul>
            <p>You may reject the collection and use of the following optional personal information when you engage in certain activities on our Website and, even in case of rejection, there is no limit on use of services.</p>
            <table>
            <tbody>
            <tr>
            <td><strong>Title of activities</strong></td>
            <td><strong>Personal information to be collected</strong></td>
            </tr>
            <tr>
            <td>Business inquiry</td>
            <td>We may collect your first and last name, your location (based on country), phone number, email address and/or company name if you choose to use our business inquiry section.</td>
            </tr>
            <tr>
            <td>Leaving comments</td>
            <td>We may collect your user name or login ID when you leave a comment in the Website by linking your Facebook, Twitter or Google account</td>
            </tr>
            </tbody>
            </table>
            <p>&nbsp;</p>
            <p>(2) Method of collection</p>
            <p>SVC collects your information through one or more of the following methods:</p>
            <ul>
            <li>Your voluntary submission of personal information for your use of the services provided under the Website.</li>
            <li>Communication via the webpage, email, fax, or phone, during communications made with customer support.</li>
            <li>Automatic collection of certain information during a user access to the PC web or mobile web/app.</li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h4><strong>B. Purpose of collecting personal information and </strong><strong>the legal basis for this use</strong></h4>
            <p>We may process your information (depending on your interaction with us) for a variety of reasons that are permitted under data protection laws and in accordance with this Privacy Policy, which may include the below purposes. Note that the legal bases outlined below are required to be included under EU data protection laws. They do not apply to you if you do not reside in the EU.</p>
            <p>SVC uses the collected personal information for the following purposes:<br />
            <strong>• </strong>To alert you to special offers, updated information and other new products or services from SVC, or other third parties, or to forward promotional materials to you. This may be done to meet and fulfill our legitimate business interests or with your consent;</p>
            <ul>
            <li>To ensure the Website is relevant to your needs. This may be done to meet and fulfill our legitimate business interest;</li>
            <li>To fulfill the terms of a promotion. This may be done to meet and fulfill our contractual obligations with you;</li>
            <li>To help us create and publish content most relevant to you. This may be done to meet and fulfill our legitimate business interests;</li>
            <li>To notify you about a material change to this Privacy Policy or the Terms and Conditions, if necessary. This may be done to meet and fulfill our contractual obligations under an existing agreement with you;</li>
            <li>To allow you access to limited-entry areas of the Website. This may be done to meet and fulfill our contractual obligations under an existing agreement with you; and/or</li>
            <li>To contact you in response to sign up forms such as Contact Us or other inquiry. This may be done to meet and fulfill our legitimate business interests or with your consent.</li>
            </ul>
            <p>SVC agrees that it will obtain a consent from you, if SVC desires to use the information other than those expressly stated in this Privacy Policy.</p>
            <p><strong>Relying on our legitimate interests</strong></p>
            <p>We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. Our legitimate interests are in promoting our business, improving our website and services and responding to user queries. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.</p>
            <p>&nbsp;</p>
            <h4><strong>C. Commission for collected personal information </strong></h4>
            <p>For carrying out services, SVC commissions external professional companies (subcontractors) to process personal information as follows. This commissioned works for processing personal information is carried out by each subcontractor and service only if necessary for providing that service.</p>
            <p>In commissioning process of personal information, in order to secure safety of personal information , SVC supervises and ensures to expressly state in the agreement with subcontractors so that those subcontractors will safely process personal information by strictly complying with directions regarding personal information protection, keeping personal information secret, not disclosing it to a 3rd party and being liable for accidents and returning or destructing personal information upon termination of the commission or process.</p>
            <table>
            <tbody>
            <tr>
            <td><strong>Name of subcontractors</strong></td>
            <td><strong>Description of commissioned works (services)</strong></td>
            </tr>
            <tr>
            <td>Delicious Langor</td>
            <td>maintain Website and promotion</td>
            </tr>
            </tbody>
            </table>
            <p><strong>&nbsp;</strong></p>
            <h4><strong>D. Details of provision of personal information to 3rd party </strong></h4>
            <p>Except for the following case, SVC does not disclose or provide your personal information to a 3rd party:</p>
            <table>
            <tbody>
            <tr>
            <td><strong>Recipients of information</strong></td>
            <td><strong>Purpose of use of recipient</strong></td>
            <td><strong>Items to be provided</strong></td>
            <td><strong>Period of retention and use of recipient</strong></td>
            </tr>
            <tr>
            <td>
            <table>
            <tbody>
            <tr>
            <td>Delicious Langor</td>
            </tr>
            </tbody>
            </table>
            </td>
            <td>maintain Website</td>
            <td>SNS information</td>
            <td>Until the date when the purpose of use is achieved or period as required by the laws</td>
            </tr>
            </tbody>
            </table>
            <p>&nbsp;</p>
            <h4><strong>E. Collection and use of information from children under the age of 16</strong></h4>
            <p>SVC does not knowingly collect or solicit Personal Information from anyone under the age of 16 or knowingly allow such persons to use the Website. The Site is not intended for, nor does SVC believe they are appealing to, children under the age of 16. If you are under 16, do not use or provide any information on this Site. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at the email address below.</p>
            <p>&nbsp;</p>
            <h4><strong>F. International transfer of information</strong></h4>
            <p>Information we collect is stored in the Republic of Korea. If you are located outside of the Republic of Korea be aware that information you submit will be transferred to the Republic of Korea. If you reside in the EU, we will transfer your personal data to the Republic of Korea in accordance with the EU data protection law requirements by using model contractual clauses that have been approved by the European Commission or other appropriate means. You may ask us for a copy of these clauses. You understand that the Republic of Korea may not provide the same level of protections as the laws in your country.</p>
            <p>&nbsp;</p>
            <h4><strong>G. Your Rights</strong></h4>
            <p>(1) Marketing Emails</p>
            <p>From time to time, we communicate with users who subscribe to our Website via email. For example, we may use your email address to confirm your request, to send you information about changes to our services, and to send notices and other disclosures as required by law. Generally, users cannot opt-out of these communications, but they will be primarily informational in nature rather than promotional.</p>
            <p>However, you have an absolute right to exercise an opt-out choice at any time if you do not want to receive marketing communication from us, such as emails or updates from us regarding new services and/or promotion events offered on the Website. For e-mails, the opt-out choice may be exercised by ticking or un-ticking the appropriate box if such checkbox is available at the points where personally identifiable information is collected or by contacting us. We will process your unsubscribe as soon as possible, but please be aware that in some circumstances you may receive a few more messages until the unsubscribe is processed. You also may opt-out of receiving such emails by clicking “UNSUBCSCRIBE” in such E-mails.</p>
            <p>&nbsp;</p>
            <p>(2) Cookies and Tracking Tools</p>
            <p>Please refer to our Cookie Policy to learn more about the controls you have over cookies and other online tracking tools.</p>
            <p>&nbsp;</p>
            <p>(3) Do Not Track Policy</p>
            <p>Our Do Not Track Policy is available in E. Cookies and other similar technologies.</p>
            <p>&nbsp;</p>
            <p>(4) If you are in the EU:</p>
            <p>You may have certain rights with respect to the personal information you provided to us. To the extent permitted by applicable data protection laws, you may:</p>
            <p>&nbsp;</p>
            <p><strong>Data Subject Access Requests:</strong> You may ask us to confirm what information we hold about you at any time, and request us to provide a copy of this and to modify, update or delete such information. We may ask you to verify your identity and for more information about your request. If we provide you with access to the information we hold about you, we will not charge you for this unless your request is “manifestly unfounded or excessive”. If you request further copies of this information from us, we may charge you a reasonable administrative cost where legally permissible. Where we are legally permitted to do so, we may refuse your request and in this case we will always let you know.</p>
            <p><strong>Right to Object: </strong>This right enables you to object to us processing your personal data where we do so for one of the following four reasons: (i) our legitimate interests; (ii) to enable us to perform a task in the public interest or exercise official authority; (iii) to send you direct marketing materials; and (iv) for scientific, historical, research, or statistical purposes.</p>
            <p><strong>Right to Erasure:</strong> You have the right to request that we erase your personal data in certain circumstances. Normally, the information must meet one of the following criteria: (i) the data are no longer necessary for the purpose for which we originally collected and/or processed them; (ii) where previously given, you have withdrawn your consent to us processing your data, and there is no other valid reason for us to continue processing; (iii) the data has been processed unlawfully (i.e. in a manner which does not comply with the GDPR); (iv) it is necessary for the data to be erased in order for us to comply with our legal obligations as a data controller; or (v) if we process the data because we believe it necessary to do so for our legitimate interests, you object to the processing and we are unable to demonstrate overriding legitimate grounds for our continued processing.</p>
            <p><strong>Right to Restrict Processing: </strong>You have the right to request that we restrict our processing of your personal data in certain circumstances. The circumstances in which you are entitled to request that we restrict the processing of your personal data are: (i) where you dispute the accuracy of the personal data that we are processing about you. In this case, our processing of your personal data will be restricted for the period during which the accuracy of the data is verified; (ii) where you object to our processing of your personal data for our legitimate interests. Here, you can request that the data be restricted while we verify our grounds for processing your personal data; (iii) where our processing of your data is unlawful, but you would prefer us to restrict our processing of it rather than erasing it; and (iv) where we have no further need to process your personal data but you require the data to establish, exercise, or defend legal claims.</p>
            <p><strong>Right to Withdraw Consent: </strong>Where we have obtained your consent to process your personal data for certain activities, you may withdraw this consent at any time and we will cease to carry out the particular activity that you previously consented to unless we consider that there is an alternative reason to justify our continued processing of your data for this purpose in which case we will inform you of this condition.</p>
            <p><strong>Right to Rectification:</strong> You also have the right to request that we rectify any inaccurate or incomplete personal data that we hold about you. If we have shared this personal data with third parties, we will notify them about the rectification unless this is impossible or involves disproportionate effort. Where appropriate, we will also tell you which third parties we have disclosed the inaccurate or incomplete personal data to. Where we think that it is reasonable for us not to comply with your request, we will explain our reasons for this decision.</p>
            <p><strong>Right of Data Portability: </strong>Under certain circumstances, you can have your personal information transferred to another company or provided to you in a commonly used, structured and machine-readable format. If you wish to exercise any of these rights please contact us at abc@seoulsemicon.com. Although we urge you to contact us to find a solution for every concern you may have, if you are an EU resident you always have the right to lodge a complaint with your competent data protection authority.</p>
            <p><strong>Right to automated individual decision-making, including profiling: </strong>You also have right to request to cease the automated treatment of personal information, including profiling, which has critical impact or cause legal effect on them.</p>
            <p><strong>Right of California residents</strong></p>
            <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit the CCPA Privacy Notice.</p>
            <p>&nbsp;</p>
            <h4><strong>H. Forums, chat rooms and other public posting areas</strong></h4>
            <p>Please note that any information you include in a message you post to any review board, chat room, forum or other public posting area is available to anyone with internet access. If you don’t want people to know your e-mail address, for example, don’t include it in any message you post publicly.</p>
            <p>&nbsp;</p>
            <h4><strong>I. Third Party Web Sites</strong></h4>
            <p>This statement applies solely to information collected on the Website. The Website may contain links to other web sites. We are not responsible for the privacy practices or the content of these other web sites.</p>
            <p>&nbsp;</p>
            <h4><strong>J. Changes to this Privacy Policy</strong></h4>
            <p>We reserve the right to change this Privacy Policy from time to time in our sole discretion. When we do, we will place an updated version on this page and also revise the “last update” date at the bottom of this Privacy Policy to show the date the Privacy Policy became effective.</p>
            <p>&nbsp;</p>
            <h4><strong>K. Security</strong></h4>
            <p>No data transmissions over the internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us and you understand that any information that you transfer to SVC is done at your own risk.</p>
            <p>In order to prevent the loss, theft, leakage, alteration or damage of your personal information, SVC takes technical, managerial and physical measures for securing safety as follows:</p>
            <table>
            <tbody>
            <tr>
            <td><strong>Items</strong></td>
            <td><strong>Examples</strong></td>
            </tr>
            <tr>
            <td><strong>Technical measures</strong></td>
            <td>∘ Utilize security servers for transmitting encryption of personal information<p></p>
            <p>∘ Take measures of encryption for confidential information</p>
            <p>∘ Install and operate access control devices and equipment</p>
            <p>∘ Establish and execute internal management plan</p></td>
            </tr>
            <tr>
            <td><strong>Managerial measures</strong></td>
            <td>∘ Appoint a staff responsible for protecting personal information<p></p>
            <p>∘ Provide education and training for staffs treating personal information</p>
            <p>∘ Establish and execute internal management plan</p>
            <p>∘ Ensure safe storage of record of access to personal information processing system</p>
            <p>∘ Classify the level of authority to access to personal information processing system</p></td>
            </tr>
            <tr>
            <td><strong>Physical measures</strong></td>
            <td>∘ Establish and operate the procedure for access control for the facilities for storing personal information<p></p>
            <p>∘ Store documents and backing storage containing personal information in safe places which have locking device</p></td>
            </tr>
            </tbody>
            </table>
            <p>&nbsp;</p>
            <h4><strong>L. Period for retention and use of personal information </strong></h4>
            <p>In principle, SVC destructs your personal information without delay when: the purpose of its collection and use has been achieved; the legal or management needs are satisfied; or your request: Provided that, if it is required to retain the information by relevant laws and regulations, SVC will retain member information for certain period as designated by relevant laws and regulations. The information to be retained as required by relevant laws and regulations are as follows:</p>
            <p>∘ Log record of users such as internet/data detecting the place of user connection: 3 months(The Protection of Communications Secrets Act)</p>
            <p>∘ Other data for checking communication facts: 12 months (The Protection of Communications Secrets Act)</p>
            <p>&nbsp;</p>
            <h4><strong>M. Procedure and method of destruction of personal information </strong></h4>
            <p>In principle, SVC destructs the information immediately after the purposes of its collection and use have been achieved without delay: Provided that, if any information is to be retained as required by relevant laws and regulations, SVC retain it for the period as required by those laws and regulations before destruction and, in such event, the personal information which is stored and managed separately will never be used for other purposes. SVC destructs: hard copies of personal information by shredding with a pulverizer or incinerating it; and delete personal information stored in the form of electric file by using technological method making that information not restored.</p>
            <p><strong>&nbsp;</strong></p>
            <h4><strong>N. Contacting SVC</strong></h4>
            <p>For visitors from the EU, SVC is a controller of your personal data. It has an Seoul Viosys. If you have any questions about this privacy policy or our privacy practices and or to exercise any of your rights, please contact us at abc@seoulsemicon.com. All information you provide in any written communication will also be covered by this policy. The staff of SVC responsible for managing personal information is as follows:</p>
            <ul>
            <li>Name of staff responsible for managing personal information: JungWoo Lee</li>
            </ul>
            <p>Dept. : Head of Department / Marketing Team</p>
            <p>Tel. : <em>+82-70-4391-8440</em></p>
            <p>Contact : <a href="mailto:abc@seoulsemicon.com">abc@seoulsemicon.com</a></p>
            <p>&nbsp;</p>
            <h4><strong>O. Infringement Remedies</strong></h4>
            <p>You can apply for dispute resolution, damage relief, consultation, etc. to the Personal Information Infringement Reporting Center, Personal Information Dispute Mediation Committee, etc.</p>
            <ul>
            <li>Privacy Infringement Report Center (operated by Korea Internet Security Agency)</li>
            </ul>
            <p>-Jurisdiction: Report on personal information infringement, apply for consultation</p>
            <p>-Homepage: privacy.kisa.or.kr</p>
            <p>-Phone: 118</p>
            <p>-Address: (138-950) Personal Information Infringement Report Center, Korea Internet &amp; Security Agency, 135, Jung-daero, Songpa-gu, Seoul</p>
            <ul>
            <li>Personal Information Dispute Mediation Committee (operated by Korea Internet &amp; Security Agency)</li>
            </ul>
            <p>-Jurisdiction: Application for mediation of personal information disputes, collective dispute mediation (civil resolution)</p>
            <p>-Homepage: www.kopico.go.kr</p>
            <p>Phone: 02-1833-6972</p>
            <p>-Address: (03171) 4F, Government Building, 209 Sejong-daero, Jongno-gu, Seoul</p>
            <ul>
            <li>Supreme Prosecutors’ Office Cyber Crime Investigation Division: 02-3480-3573 (www.spo.go.kr)</li>
            <li>National Police Agency Cyber Terror Response Center: 1566-0112 (<a href="http://www.netan.go.kr">www.netan.go.kr</a>)</li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>Last Updated: November 19, 2021</p>
        </ContentWrapper>
        )
    } else if(props.currentLocale === '/jp') {
        return (
            <ContentWrapper>
            <Head
                title={'violeds | プライバシーポリシー'}
                description={"プライバシーポリシー"}
                keyword={''}
                lang={props.currentLocale}
            />
        <h3>プライバシーポリシー</h3>
        <p>&nbsp;</p>
        <h4>1. 基本方針</h4>
        <p>ソウルバイオシス株式会社(以下「当社」といいます) は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。</p>
        <p>&nbsp;</p>
        <h4>2. 適用範囲</h4>
        <p>本プライバシーポリシーは、お客様の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。</p>
        <p>&nbsp;</p>
        <h4>3. 個人情報の利用目的</h4>
        <p>当サイトは、お客様からご提供いただく情報を以下の目的の範囲内において利用します</p>
        <ul className="dot">
        <li>お問い合わせ、コメント等の確認・回答のため</li>
        <li>メールマガジン・DM・各種お知らせ等の配信・送付のため</li>
        <li>キャンペーン・アンケート・モニター・取材等の実施のため</li>
        <li>サービスの提供・改善・開発・マーケティングのため</li>
        <li>利用規約等で禁じている行為などの調査のため</li>
        <li>その他個別に承諾いただいた目的</li>
        </ul>
        <p>&nbsp;</p>
        <h4>4. 個人情報の管理</h4>
        <p>当サイトは、個人情報の正確性及び安全確保のために、セキュリティ対策を徹底し、個人情報の漏洩、改ざん、不正アクセスなどの危険については、必要かつ適切なレベルの安全対策を実施します。</p>
        <p>当サイトは、第三者に重要な情報を読み取られたり、改ざんされたりすることを防ぐために、SSLによる暗号化を使用しております。</p>
        <p>&nbsp;</p>
        <h4>5. 個人情報の第三者提供</h4>
        <p>当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。</p>
        <ul className="dot">
        <li>ご本人の同意がある場合</li>
        <li>法令に基づく場合</li>
        <li>人の生命・身体・財産の保護に必要な場合</li>
        <li>公衆衛生・児童の健全育成に必要な場合</li>
        <li>国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）</li>
        </ul>
        <p></p>
        <p>当サイトでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。</p>
        <p>&nbsp;</p>
        <h4>6. 個人情報に関するお問い合わせ</h4>
        <p>開示、訂正、利用停止等のお申し出があった場合には、所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、お問い合わせください。</p>
        <p>&nbsp;</p>
        <h4>7. Cookie（クッキー）</h4>
        <p>Cookie（クッキー）は、利用者のサイト閲覧履歴を、利用者のコンピュータに保存しておく仕組みです。</p>
        <p>利用者はCookie（クッキー）を無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。ただし、Cookie（クッキー）を拒否した場合、当サイトのいくつかのサービス・機能が正しく動作しない場合があります。</p>
        <p>&nbsp;</p>
        <h4>8. アクセス解析</h4>
        <p>当サイトでは、サイトの分析と改善のためにGoogleが提供している「Google アナリティクス」を利用しています。</p>
        <p>このサービスは、トラフィックデータの収集のためにCookie（クッキー）を使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません</p>
        <p>&nbsp;</p>
        <h4>9. 免責事項</h4>
        <p>当サイトのコンテンツ・情報について、可能な限り正確な情報を掲載するよう努めておりますが、正確性や安全性を保証するものではありません。当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>
        <p>当サイトからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。</p>
        <p>&nbsp;</p>
	</ContentWrapper>
        )
    } else {
        return (
            <ContentWrapper>
            <Head
                title={'violeds | 개인정보처리방침'}
                description={"개인정보처리방침"}
                keyword={''}
                lang={props.currentLocale}
            />
		<h3>개인정보처리방침</h3>
        <p>&nbsp;</p>
        <p>서울바이오시스 주식회사(이하 “회사”라 합니다)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자의 권익 보호에 최선을 다하고 있습니다. 개인정보처리방침을 통해 이용자가 제공한 개인정보가 어떠한 용도와 방식으로 이용되며, 어떠한 방법을 통해 관리되고 있는지에 대해 알려드립니다.</p>
        <p>본 개인정보처리방침은 VIOLEDS 웹사이트(https://violeds.com)(이하 “웹사이트”라 합니다)를 통해 수집된 정보에만 적용된다.</p>
        <p>&nbsp;</p>
        <h4>제1조(개인정보의 수집·이용 목적, 수집·이용하는 개인정보 항목 및 보유기간)</h4>
        <ul className="dot">
        <li>회사는 본 개인정보처리방침에서 정하는 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 제4항의 목적 이외의 용도로는 이용되지 않으며, 이용목적이 변경되는 경우에는 관련 법률에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</li>
        <li>회사는 법령에 따른 개인정보 보유이용기간 또는 이용자로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</li>
        <li>회사가 개인정보를 수집하는 방법은 다음 각 호와 같습니다.
        <ul className="dot">
        <li>이용자가 서비스 이용 과정에서 직접 입력</li>
        <li>고객 지원을 위한 커뮤니케이션 과정에서 웹페이지, 이메일, 팩스, 전화를 통한 수집</li>
        <li>PC웹이나 모바일 웹/어플리케이션 이용과정에서 자동으로 생성되어 수집</li>
        </ul>
        </li>
        <li>각각의 수집·이용하는 개인정보 항목 및 보유기간은 다음과 같습니다.</li>
        </ul>
        <br /><br />
        <table>
        <tbody>
        <tr>
        <td><strong>수집·이용 목적</strong></td>
        <td><strong>수집·이용하는 개인정보의 항목</strong></td>
        <td><strong>보유기간</strong></td>
        </tr>
        <tr>
        <td><strong><br />
        코멘트</strong> <strong>작성<br />
        </strong>작성한 코멘트에 대한 처리결과 회신 등 과정에서 수집되는 항목</td>
        <td>(선택) 페이스북, 트위터 또는 구글 로그인 아이디, 성명</td>
        <td>수집·이용 목적 달성시까지</td>
        </tr>
        <tr>
        <td><strong>서비스 이용과정에서 자동으로 생성되어 수집되는 항목</strong></td>
        <td>(필수) 쿠키, 로그정보(IP 주소, 로그 데이터, 사용시간, 검색어, IP 주소, web beacon)</td>
        <td>1. 「통신비밀보호법 시행령」 제41조 제2항 제1호에 따른 통신사실확인자료: 12개월 (통신비밀보호법)<p></p>
        <p>2. 「통신비밀보호법 시행령」 제41조 제2항 제2호에 따른 통신사실확인자료: 12개월 (통신비밀보호법)</p></td>
        </tr>
        </tbody>
        </table>
        <br /><br />
        <ul className="dot">
        <li>기존 서비스 개선, 신규 서비스 개발 및 마케팅, 개인별 맞춤 서비스 제공을 위해 위와 같이 수집된 개인정보가 활용될 수 있습니다. 또한 위와 같이 수집된 개인정보는 통계적 정보를 바탕으로 서비스 유효성 확인, 광고게재, 이벤트 정보 및 참여기회 제공 및 이용자의 서비스 이용에 대한 통계제작을 목적으로 활용될 수 있습니다. 이용자는 마케팅 목적의 개인정보 수집 및 이용을 거부할 수 있으나 이 경우 회사가 제공하는 유용한 정보를 받지 못할 수 있습니다.</li>
        <li>이용자는 회사가 수집하는 개인정보의 항목 및 수집 방법에 동의를 거부할 수 있으며, 거부 시 이용자에게 제공되는 서비스 이용의 일부가 제한될 수 있습니다.</li>
        </ul>
        <p>&nbsp;</p>
        <h4>제2조(개인정보 처리의 위탁)</h4>
        <ul className="dot">
        <li>회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 회사는 사전에 다음 각 호의 사항 모두를 이용자에게 미리 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항이 변경되는 경우에도 같습니다. 위탁받는 업체, 위탁하는 업무의 내용이 변경될 경우, 웹사이트의 공지사항을 통하여 알려드리겠습니다.
        <ul className="dot">
        <li>개인정보 처리 위탁을 받는 자</li>
        <li>개인정보 처리 위탁을 하는 업무의 내용</li>
        </ul>
        </li>
        <li>회사는 정보통신서비스의 제공에 관한 계약을 이행하고 이용자의 편의 증진 등을 위하여 필요한 경우 개인정보처리방침에 따라 제1항 각 호의 사항을 공개하는 등으로 이용자에게 알린 경우 고지절차와 동의절차를 거치지 아니하고 개인정보 처리를 타인에게 위탁할 수 있습니다.</li>
        <li>회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등 수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁 처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한됩니다.</li>
        </ul>
        <br /><br />
        <table>
        <tbody>
        <tr>
        <td><strong>위탁 대상자</strong></td>
        <td><strong>위탁업무 내용</strong></td>
        </tr>
        <tr>
        <td>Delicious Langor</td>
        <td>웹사이트 유지 및 홍보</td>
        </tr>
        </tbody>
        </table>
        <br />
        <p>&nbsp;</p>
        <h4>제3조(개인정보의 제3자 제공)</h4>
        <ul className="dot">
        <li>회사는 이용자의 동의가 있거나 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제24조의2 등 관련 법령의 규정에 해당하는 경우를 제외하고는 어떠한 경우에도 제1조에서 고지한 범위를 넘어 이용자의 고객정보를 이용하거나 제3자에게 제공하지 않습니다.</li>
        <li>회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</li>
        </ul>
        <br /><br />
        <table>
        <tbody>
        <tr>
        <td><strong>개인정보를 제공받는 자</strong></td>
        <td><strong>개인정보를 제공받는 자의 개인정보 이용목적</strong></td>
        <td><strong>제공하는 개인정보의 항목</strong></td>
        <td><strong>개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</strong></td>
        </tr>
        <tr>
        <td>Delicious Langor</td>
        <td>웹사이트 유지</td>
        <td>SNS 정보</td>
        <td>이용 목적 달성시까지</td>
        </tr>
        </tbody>
        </table>
        <br /><br />
        <ul className="dot">
        <li>회사가 이용자의 개인정보를 제3자에게 제공하는 경우에는 사전에 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간을 개인정보처리방침을 통하여 공개하고, 개별적으로 전자우편 또는 웹사이트 공지를 통해 공지한 후 동의를 구합니다.</li>
        </ul>
        <p>&nbsp;</p>
        <h4>제4조(개인정보 파기절차 및 파기방법)</h4>
        <p>회사는 원칙적으로 개인정보 처리목적이 달성되거나, 보유기간의 종료, 사업의 종료 등 파기사유가 발생하는 경우 이용자의 개인정보를 지체 없이 파기합니다. 다만, 관계 법령에 따라 개인정보를 보존하여야 하는 경우에는 해당 기간 경과 후 지체 없이 재생 불가능한 방법으로 파기합니다. 이때 별도 저장 관리되는 개인정보는 법령에 정한 경우를 제외하고 다른 용도로 이용되지 않습니다. 구체적인 파기 절차, 기한 및 방법은 다음과 같습니다.</p>
        <br />
        <table>
        <tbody>
        <tr>
        <td><strong>파기절차</strong></td>
        <td><strong>파기방법</strong></td>
        </tr>
        <tr>
        <td>이용자가 입력한 정보는 목적이 달성된 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(제1조의 보유기간 참조) 일정기간 저장되거나 즉시 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</td>
        <td>전자적 파일 형태의 정보는 복구 및 재생이 되지 않는 기술적인 방법을 사용합니다.<p></p>
        <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p></td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <h4>제5조(이용자의 권리, 의무 및 그 행사방법)</h4>
        <ul className="dot">
        <li>이용자(만 14세 미만인 경우에는 법정대리인을 말함)는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
        <ul className="dot">
        <li>개인정보 열람 요구</li>
        <li>오류 등이 있을 경우 정정 요구</li>
        <li>삭제요구</li>
        <li>처리정지 요구</li>
        <li>개인정보처리에 대한 동의에 대한 철회</li>
        </ul>
        </li>
        <li>제1항에 따른 권리 행사는 회사에 대해 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</li>
        <li>이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</li>
        <li>제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호 서식을 참고하여 위임장을 제출하셔야 합니다.</li>
        </ul>
        <p>&nbsp;</p>
        <h4>제6조(개인정보의 안전성 확보조치)</h4>
        <p>회사는 다음 각 호와 같이 개인정보의 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 취하고 있습니다.</p>
        <ul className="dot">
        <li>개인정보 암호화 전송을 위한 보안서버 활용</li>
        <li>비밀정보 암호화 조치</li>
        <li>접근통제장치의 설치 및 운영</li>
        <li>내부관리계획의 수립 및 시행</li>
        <li>개인정보보호책임자 지정</li>
        <li>개인정보를 다루는 직원들에게 교육 및 훈련 제공</li>
        <li>개인정보처리시스템에 대한 접근기록의 안전한 보관</li>
        <li>개인정보처리시스템에 대한 접근권한 차별화</li>
        <li>개인정보를 보관하는 시설에 대한 접근 통제 절차 수립 및 운영</li>
        <li>개인정보가 포함된 서류나 보조기억매체 등은 잠금장치가 부착되어 있는 안전한 장소에 보관</li>
        </ul>
        <p>&nbsp;</p>
        <h4>제7조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</h4>
        <ul className="dot">
        <li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>
        <li>쿠키는 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며, 이용자들의 컴퓨터, 태블릿, 스마트폰 또는 기타 기기의 하드디스크에 저장됩니다.
        <ul className="dot">
        <li>쿠키의 사용목적: 이용자의 웹사이트 방문, 이용형태 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
        <li>쿠키의 설치·운영 및 거부: 이용자는 브라우저의 종류에 따라 하단에 제시한 링크를 참고하여, 쿠키 설정을 거부할 수 있습니다. 단 쿠키 설정을 거부할 경우 맞춤서비스 이용에 어려움이 발생할 수 있습니다.
        <ul className="nodot">
        <li>〮 Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=ko">https://support.google.com/chrome/answer/95647?hl=ko</a></li>
        <li>〮 Internet Explorer: <a href="https://support.microsoft.com/ko-kr/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/ko-kr/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
        <li>〮 Safari: <a href="https://support.apple.com/ko-kr/HT201265">https://support.apple.com/ko-kr/HT201265</a></li>
        <li>〮 Firefox: <a href="https://support.mozilla.org/ko/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/ko/kb/delete-cookies-remove-info-websites-stored</a></li>
        <li>〮 Opera: <a href="https://help.opera.com/en/latest/web-preferences/">https://help.opera.com/en/latest/web-preferences/</a></li>
        <li>〮 Edge: <a href="https://support.microsoft.com/ko-kr/help/4468242/microsoft-edge-browsing-data-and-privacy">https://support.microsoft.com/ko-kr/help/4468242/microsoft-edge-browsing-data-and-privacy</a></li>
        </ul>
        </li>
        </ul>
        </li>
        </ul>
        <ul className="dot">
        <li>회사의 웹사이트 방문을 통해 이용자의 브라우저에 저장되는 쿠키는 다음과 같습니다.</li>
        </ul>
        <br /><br />
        <table>
        <tbody>
        <tr>
        <td>PHPSESSID</td>
        <td>웹 애플리케이션에서 사용자 세션 식별자를 저장하는 데 사용되는 일반적인 기술 쿠키입니다.</td>
        </tr>
        <tr>
        <td>_ga</td>
        <td>구글 애널리틱스에서 사용자를 식별하기 위해 사용됩니다. (2년 후 만료)</td>
        </tr>
        <tr>
        <td>_gid</td>
        <td>구글 애널리틱스에서 사용자의 행동을 추적하기 위해 사용됩니다. (24시간 후 만료)</td>
        </tr>
        <tr>
        <td>_gat_UA-137183644-8</td>
        <td>구글 애널리틱스로 전송되는 네트워크 요청의 효율화를 위해 사용됩니다. (1분 후 만료)</td>
        </tr>
        <tr>
        <td>_gat_UA-129131751-4</td>
        <td>구글 애널리틱스로 전송되는 네트워크 요청의 효율화를 위해 사용됩니다. (1분 후 만료)</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>제8조(개인정보보호 책임자 및 연락처)</p>
        <ul className="dot">
        <li>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호 책임자를 지정하고 있습니다. 회사 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서에 문의하실 수 있습니다.</li>
        </ul>
        <ul className="nodot">
        <li>▶ 개인정보보호 책임자</li>
        <li>성 &nbsp;명 : 이정우 실장</li>
        <li>부서명 : 마케팅팀</li>
        <li>전 &nbsp;화 : 070-4391-8440</li>
        <li>메 &nbsp;일 : <a href="mailto:abc@seoulsemicon.com">abc@seoulsemicon.com</a></li>
        </ul>
        <ul className="dot">
        <li>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</li>
        </ul>
        <ul className="nodot">
        <li>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
        <li>– 소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
        <li>– 홈페이지 : privacy.kisa.or.kr</li>
        <li>– 전화 : (국번없이) 118</li>
        <li>– 주소 : (58342) 전남 나주시 진흥길 9(빛가람동) 한국인터넷진흥원 개인정보침해 신고센터</li>
        <li>▶ 개인정보 분쟁조정위원회</li>
        <li>– 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
        <li>– 홈페이지 : www.kopico.go.kr</li>
        <li>– 전화 : (국번없이) 1833-6972</li>
        <li>– 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층</li>
        <li>▶ 대검찰청 사이버범죄수사단</li>
        <li>– 전화 : (국번없이) 1301</li>
        <li>– 홈페이지 : privacy@spo.go.kr (www.spo.go.kr)</li>
        <li>▶ 경찰청 사이버안전국</li>
        <li>– 전화 : (국번없이) 182</li>
        <li>– 홈페이지 : cyberbureau.pulice.go.k</li>
        </ul>
        <p>&nbsp;</p>
        <h4>제9조(개인정보처리방침의 변경)</h4>
        <ul className="dot">
        <li>본 개인정보처리방침은 회사의 필요에 의하여 변경될 수 있고, 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 회사 웹사이트의 공지사항을 통해 사전 고지할 것입니다. 다만, 개인정보의 수집·이용 목적, 제3자 제공 대상 등 중요한 사항이 추가, 삭제 및 수정되는 경우에는 30일 전에 사전 고지하고, 30일이 경과된 후에 시행됩니다.</li>
        <li>회사는 개인정보의 수집 및 이용, 제3자 제공 등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령에 따라 이용자의 별도 동의가 필요한 사항과 관련된 내용이 추가, 변경되는 경우에는 관련 법령에 따라 이용자로부터 별도의 동의를 받습니다.</li>
        <li>회사는 개인정보처리방침에 다음과 같이 버전번호, 변경공고일자 및 그 시행일자 등을 부여하여 개정여부를 쉽게 알 수 있도록 하고 있습니다.</li>
        </ul>
        <ul className="nodot">
        <li>〮 개인정보처리방침 버전번호: v1.0</li>
        <li>〮 변경 개인정보처리방침 시행일자: 2021 년 11 월 19 일</li>
        </ul>
	</ContentWrapper>
        )
    }

    
}

const CCPA = ({ ...props }) => {
    return (
        <ContentWrapper>
            <Head
                title={'violeds | CCPA Privacy Notice'}
                description={"CCPA Privacy Notice"}
                keyword={''}
                lang={props.currentLocale}
            />
		<h3><strong>CCPA Privacy Notice</strong></h3>
<p><strong>&nbsp;</strong></p>
<p>This <strong>Privacy Notice for California Residents</strong> supplements the information contained in our VIOLEDS Privacy Policy and applies solely to all visitors, users and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this notice.</p>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Information We Collect</strong></h4>
</li>
</ul>
<p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“personal information”). In particular, we have collected the following categories of personal information from customers within the last twelve (12) months:</p>
<p>&nbsp;</p>
<table>
<tbody>
<tr>
<td><strong>Category</strong></td>
<td><strong>Examples</strong></td>
<td><strong>Collected</strong></td>
</tr>
<tr>
<td>A.&nbsp;&nbsp;&nbsp; Identifiers</td>
<td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver’s license number, passport number, or other similar identifiers.</td>
<td>YES<p></p>
<p>(Email address, user name, IP address)</p></td>
</tr>
<tr>
<td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
<td>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
<td>YES<p></p>
<p>(Name, delivery address, employment, telephone number)</p></td>
</tr>
<tr>
<td>C. Protected classification characteristics under California or federal law.</td>
<td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
<td>No</td>
</tr>
<tr>
<td>F. Internet or other similar network activity.</td>
<td>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.</td>
<td>Yes</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><strong>Personal information does not include:</strong></p>
<ul>
<li>Publicly available information from government records.</li>
<li>De-identified or aggregated consumer information.</li>
<li>Information excluded from the CCPA’s scope, like:
<ul>
<li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
<li>Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.</li>
</ul>
</li>
</ul>
<p>We obtained the categories of personal information listed above from the following categories of sources:</p>
<ul>
<li>Directly from you. For example, from forms you complete on our website.</li>
<li>Indirectly from you. For example, from observing your actions on our website.</li>
</ul>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Use of Personal Information</strong></h4>
</li>
</ul>
<p>We may use or disclose the personal information we collect for one more of the following business purposes:</p>
<ul>
<li>To allow you to create an account on the Website so you can engage and participate in review board, chat room and forums on the Website.</li>
<li>To alert you to special offers, updated information and other new products or services from SVC, or other third parties, or to forward promotional materials to you.</li>
<li>To fulfill the terms of a promotion.</li>
<li>To ensure the Website is relevant to your needs.</li>
<li>To help us create and publish content most relevant to you.</li>
<li>To notify you about a material change to this Privacy Policy or the Terms of Use Agreement, if necessary.</li>
<li>To allow you access to limited-entry areas of the Website.</li>
<li>To contact you in response to sign up forms such as Contact Us or other inquiry.</li>
</ul>
<p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Sharing Personal Information</strong></h4>
</li>
</ul>
<p>We may disclose your personal information to a third party for a business purpose.&nbsp; When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
<p>&nbsp;</p>
<p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
<p>&nbsp;</p>
<p>Category A:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Identifiers</p>
<p>Category B:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; California Customer Records personal information categories.</p>
<p>Category F:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Internet or other similar network activity.</p>
<p>&nbsp;</p>
<p>We disclose your personal information for a business purpose to the following category of third party:</p>
<ul>
<li>Service Providers</li>
</ul>
<p>In the preceding twelve (12) months, we have not sold any personal information.</p>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Your Rights and Choices</strong></h4>
</li>
</ul>
<p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
<p>&nbsp;</p>
<p><strong><em><u>Access to Specific Information and Data Portability Rights</u></em></strong></p>
<p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
<ul>
<li>The categories of personal information we collected about you.</li>
<li>The categories of sources for the personal information we collected about you.</li>
<li>Our business or commercial purpose for collecting or selling that personal information.</li>
<li>The categories of third parties with whom we share that personal information.</li>
<li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
<li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
<ul>
<li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
<li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<p><strong><em><u>Deletion Request Rights</u></em></strong></p>
<p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
<p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
<ul>
<li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
<li>Debug products to identify and repair errors that impair existing intended functionality.</li>
<li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
<li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 ).</li>
<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</li>
<li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
<li>Comply with a legal obligation.</li>
<li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
</ul>
<p>&nbsp;</p>
<p><strong><em><u>Exercising Access, Data Portability, and Deletion Rights</u></em></strong></p>
<p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by sending us a message on our website. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information.</p>
<p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
<ul>
<li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
<li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
</ul>
<p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
<p>&nbsp;</p>
<p><strong><em><u>Response Timing and Format</u></em></strong></p>
<p>We endeavor to respond to a verifiable consumer request within 45 days of its receipt.&nbsp; If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.&nbsp; We will deliver our written response by mail or electronically, at your option.&nbsp; Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt.&nbsp; The response we provide will also explain the reasons we cannot comply with a request, if applicable.&nbsp; For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
<p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.&nbsp; If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Non-Discrimination</strong></h4>
</li>
</ul>
<p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
<ul>
<li>Deny you goods or services.</li>
<li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
<li>Provide you a different level or quality of goods or services.</li>
<li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
</ul>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Changes to Our Privacy Notices</strong></h4>
</li>
</ul>
<p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will notify you by email or through a notice on our website homepage.</p>
<p>&nbsp;</p>
<ul>
<li>
<h4><strong>Contact Information</strong></h4>
</li>
</ul>
<p>If you have any questions or comments about this notice, our Privacy Statement, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</p>
<ul>
<li>Email: <a href="mailto:abc@seoulsemicon.com">abc@seoulsemicon.com</a></li>
<li>Postal Address: Attn: VIOLEDS, Marketing Task</li>
</ul>
<p>Seoul Viosys Co., Ltd.</p>
<p>65-16, Sandan-ro 163beon-gil,</p>
<p>Danwon-gu, Ansan-si, Gyeonggi-do</p>
<p>Korea&nbsp;&nbsp;&nbsp; 15429</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>Last Updated : November 19, 2021</p>

	</ContentWrapper>
    )
}

const Cookie = ({ ...props }) => {

    return (
        <ContentWrapper>
            <Head
                title={'violeds | Cookie Policy'}
                description={"Cookie Policy"}
                keyword={''}
                lang={props.currentLocale}
            />
<h3>Cookie Policy</h3>
<p>&nbsp;</p>
<h4>Seoul Viosys may collect collective and impersonal information through cookies.</h4>
<p>Cookies are small data files that are placed on the hard drive of your computer, tablet, smartphones or other device when you visit a website and other tracking tools on our VIOLEDS website (<a href="https://violeds.com/en">https://violeds.com/en</a>, our “Website”) and digital platforms. Some cookies are functional and enable additional functionality when a user accesses the Website (e.g. allows users to save user preferences or language suggestions). Other cookies are non-functional and allow the website and third parties to tailor messages and advertising relevant to a user’s interests. When we refer to cookies, this includes other similar technologies such as web beacons.</p>
<h4>The items of cookies to be collected and the purpose of such collection are as follows:</h4>
<table>
<tbody>
<tr>
<td><strong>Category</strong></td>
<td><strong>Reasons for using cookies and additional information</strong></td>
</tr>
<tr>
<td><strong>Strictly Necessary Cookies</strong></td>
<td>These cookies are essential to enable you to use the Website effectively and therefore cannot be turned off. Without these cookies, the services available to you on our Website cannot be provided. These cookies do not gather information about you that could be used for marketing or remembering where you have been on the internet.</td>
</tr>
<tr>
<td><strong>Functional Cookies</strong></td>
<td>These cookies allow the Website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced features. For instance, we may be able to provide you with news or updates relevant to the services you use. These cookies can also be used to remember changes you have made to text size, font and other parts of web commenting on a blog.</td>
</tr>
<tr>
<td><strong>Analytical Cookies</strong></td>
<td>They allow us to recognize and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way our Website works, for example, by ensuring that users are finding what they are looking for easily. These cookies do not collect information that can identify you. The information that these cookies collect is only used to improve how the Website works.<p></p>
<p>The Website uses Google Analytics cookies. Information collected by Google Analytics cookies will be transmitted to and stored by Google on servers in the United States of America in accordance with its privacy practices. To see an overview of privacy at Google and how this applies to Google Analytics, visit https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk. You may opt out of tracking by Google Analytics by visiting https://tools.google.com/dlpage/gaoptout.</p></td>
</tr>
<tr>
<td><strong>Targeting / Re-marketing Cookies</strong></td>
<td>Third parties, such as Google and Facebook, may use cookies and other similar technologies like pixels to collect or receive information from the Website and elsewhere on the internet and use that information to provide you with targeted ads. These cookies may also be used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign.</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<h4>The cookies that are placed on your browser by visiting our Website are listed in the table below:</h4>
<table>
<tbody>
<tr>
<td><strong>Name</strong></td>
<td><strong>Type</strong></td>
<td><strong>Function of Cookie</strong></td>
<td><strong>Retention Period</strong></td>
</tr>
<tr>
<td>spzone</td>
<td>1<sup>st</sup>&nbsp;Party</td>
<td>This cookie helps the Website to remember your language settings from your last visit.</td>
<td>Need to manually disable cookies to turn off</td>
</tr>
<tr>
<td>spcookies</td>
<td>1<sup>st</sup>&nbsp;Party</td>
<td>This cookie is used to remember your cookie consent preferences.</td>
<td>Need to manually disable cookies to turn off</td>
</tr>
<tr>
<td>JSESSIONID</td>
<td>1<sup>st</sup>&nbsp;Party</td>
<td>&nbsp;A generic technical cookie used for storing user session identifier in web applications</td>
<td>Expires after the session</td>
</tr>
<tr>
<td>_ga</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Google Analytics cookie<p></p>
<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the Website.</p>
<p>Privacy Policy:&nbsp;<a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk">https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk</a></p></td>
<td>Expires in 730 days</td>
</tr>
<tr>
<td>_gid</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Google Analytics cookie<p></p>
<p>Registers a unique ID that is used to generate statistical data on how the visitor uses the Website.</p>
<p>Privacy Policy:&nbsp;<a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk">https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk</a></p></td>
<td>Expires in 1 day</td>
</tr>
<tr>
<td>_gat_gtag_UA-137815452-3</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Google Analytics cookie<p></p>
<p>This cookie stops too many users from accessing the Website</p>
<p>Privacy Policy:&nbsp;<a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk">https://policies.google.com/technologies/partner-sites?hl=en-GB&amp;gl=uk</a></p></td>
<td>Only valid during current browser session and it will be deleted when you close the browser</td>
</tr>
<tr>
<td>Test_cookie</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Google<p></p>
<p>This cookie is used to check if the user’s browser supports cookies.</p>
<p>Privacy Policy:&nbsp;<a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p></td>
<td>Only valid during current browser session and it will be deleted when you close browser</td>
</tr>
<tr>
<td>fr</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Facebook<p></p>
<p>Facebook’s primary advertising cookie, use to deliver, measure, and improve the relevancy of ads.</p>
<p>Privacy policy:&nbsp;<a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a></p>
<p>Cookie policy</p>
<p><a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a></p></td>
<td>Expires in 90 days</td>
</tr>
<tr>
<td>_fbp</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Facebook<p></p>
<p>Facebook Pixel advertising first-party cookie</p>
<p>Privacy policy:&nbsp;<a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a></p>
<p>Cookie policy</p>
<p><a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a></p></td>
<td>Expires in 90 days</td>
</tr>
<tr>
<td>IDE</td>
<td>3<sup>rd</sup>&nbsp;Party</td>
<td>Google<p></p>
<p>This cookie is used to register and report the Web site user’s action s after viewing or clicking one of the advertiser’s ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.</p>
<p>Privacy policy:</p>
<p><a href="https://policies.google.com/technologies/types">https://policies.google.com/technologies/types</a></p></td>
<td>Expires in 730 days</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>We do not currently honor any “do-not-track” signal sent by browsers. However, you may opt-out of targeted advertising from National Advertising Initiative members by visiting http://www.networkadvertising.org/choices/. &nbsp;You may also set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but if you do, you may not be able to take advantage of the personalized features enjoyed by other users to our Website. Please check your browser to determine where these types of cookies are stored and how they may be deleted. Please see below for how to check your browser setting.</p>
<ul>
<li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=en</a></li>
<li>Internet Explorer: <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
<li>Safari: <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">https://support.apple.com/en-us/HT201265</a></li>
<li>Firefox: <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a></li>
<li>Opera: <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" rel="noopener noreferrer">https://help.opera.com/en/latest/web-preferences/</a></li>
<li>Edge: <a href="https://support.microsoft.com/en-ca/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/en-ca/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a></li>
</ul>
<p>&nbsp;</p>
<p>Last Updated: November 19, 2021</p>
</ContentWrapper>
    )
}

const Policy = ({ ...props }) => {

    return (
        <Wrapper>
            <Inner>
                
                {props.location.pathname === `${props.currentLocale}/policy/privacy` && (
                    <Privacy {...props} />
                )}
                {props.location.pathname === `${props.currentLocale}/policy/ccpa` && (
                    <CCPA {...props} />
                )}
                {props.location.pathname === `${props.currentLocale}/policy/cookie` && (
                    <Cookie {...props} />
                )}
            </Inner>
        </Wrapper>
    )

}


const Wrapper = styled.div`
    width: 100%;
`

const Inner = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 140px 24px 60px 24px;

` 

const ContentWrapper = styled.div`
    h3 {
        font-size: 24px;
        font-weight: bold;
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
    }

    p {
        color: #797979;
        line-height: 25px;
    }

    ul {
        color: #797979;
    }

    ul.dot {
        list-style-type: disc;
        padding-left: 20px;
    }

    ul.nodot {
        padding-left: 20px;
    }

    li {
        line-height: 25px;
    }

    table {
        color: #797979;
        border-bottom: 1px solid rgba(0,0,0,0.08);

        p {
            margin: 6px 0;
        }
    }

    td {
        border-top: 1px solid rgba(0,0,0,0.08);
        padding: 8px 14px;
    }

    strong {
        font-weight: bold;
    }
  
`

export default withRouter(Policy)