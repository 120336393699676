import app01 from '../images/applications/application01@3x.png'
import app02 from '../images/applications/application02@3x.png'
import app03 from '../images/applications/application03@3x.png'
import app04 from '../images/applications/application04@3x.png'
import app05 from '../images/applications/application05@3x.png'
import app06 from '../images/applications/application06@3x.png'
import app07 from '../images/applications/application07@3x.png'
import app08 from '../images/applications/application08@3x.png'
import app09 from '../images/applications/application09@3x.png'
import app10 from '../images/applications/application10@3x.png'
import app11 from '../images/applications/application11@3x.png'
import app12 from '../images/applications/application12@3x.png'
import app13 from '../images/applications/application13@3x.png'
import app14 from '../images/applications/application14@3x.png'
import app15 from '../images/applications/application15@3x.png'
import app16 from '../images/applications/application16@3x.png'
import app17 from '../images/applications/application17@3x.png'
import app18 from '../images/applications/application18@3x.png'
import app19 from '../images/applications/application19@3x.png'
import app20 from '../images/applications/application20@3x.png'
import app21 from '../images/applications/application21@3x.png'
import app22 from '../images/applications/application22@3x.png'

import item01 from '../images/main/applications/round_on01@2x.png'
import item02 from '../images/main/applications/round_on02@2x.png'
import item03 from '../images/main/applications/round_on03@2x.png'
import item04 from '../images/main/applications/round_on04@2x.png'
import item05 from '../images/main/applications/round_on05@2x.png'
import item06 from '../images/main/applications/round_on06@2x.png'
import item07 from '../images/main/applications/round_on07@2x.png'
import item08 from '../images/main/applications/round_on08@2x.png'
import item09 from '../images/main/applications/round_on09@2x.png'
import item10 from '../images/main/applications/round_on10@2x.png'
import item11 from '../images/main/applications/round_on11@2x.png'
import item12 from '../images/main/applications/round_on12@2x.png'
import item13 from '../images/main/applications/round_on13@2x.png'
import item14 from '../images/main/applications/round_on14@2x.png'
import item15 from '../images/main/applications/round_on15@2x.png'
import item16 from '../images/main/applications/round_on16@2x.png'
import item17 from '../images/main/applications/round_on17@2x.png'
import item18 from '../images/main/applications/round_on18@2x.png'
import item19 from '../images/main/applications/round_on19@2x.png'
import item20 from '../images/main/applications/round_on20@2x.png'
import item21 from '../images/main/applications/round_on21@2x.png'
import item22 from '../images/main/applications/round_on22@2x.png'

import item01_off from '../images/main/applications/round01_off.png'
import item02_off from '../images/main/applications/round02_off.png'
import item03_off from '../images/main/applications/round03_off.png'
import item04_off from '../images/main/applications/round04_off.png'
import item05_off from '../images/main/applications/round05_off.png'
import item06_off from '../images/main/applications/round06_off.png'
import item07_off from '../images/main/applications/round07_off.png'
import item08_off from '../images/main/applications/round08_off.png'
import item09_off from '../images/main/applications/round09_off.png'
import item10_off from '../images/main/applications/round10_off.png'
import item11_off from '../images/main/applications/round11_off.png'
import item12_off from '../images/main/applications/round12_off.png'
import item13_off from '../images/main/applications/round13_off.png'
import item14_off from '../images/main/applications/round14_off.png'
import item15_off from '../images/main/applications/round15_off.png'
import item16_off from '../images/main/applications/round16_off.png'
import item17_off from '../images/main/applications/round17_off.png'
import item18_off from '../images/main/applications/round18_off.png'
import item19_off from '../images/main/applications/round19_off.png'
import item20_off from '../images/main/applications/round20_off.png'
import item21_off from '../images/main/applications/round21_off.png'
import item22_off from '../images/main/applications/round22_off.png'

const ApplicationItems = {
    header: {
        all: {
            title: 'violeds | APPLICATIONS - 전체',
            desc: 'Violeds의 만남은 앞으로도 계속됩니다. 점점 뻗어나가는 Violeds의 적용사례를 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, applications, 적용사례, 성공사례'
        },
        sterilization: {
            title: 'violeds | APPLICATIONS - 살균',
            desc: 'Violeds의 만남은 앞으로도 계속됩니다. 점점 뻗어나가는 Violeds의 적용사례를 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, applications, 적용사례, 성공사례, 살균'
        },
        deodorization: {
            title: 'violeds | APPLICATIONS - 탈취',
            desc: 'Violeds의 만남은 앞으로도 계속됩니다. 점점 뻗어나가는 Violeds의 적용사례를 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, applications, 적용사례, 성공사례, 탈취'
        },
        insectAttraction: {
            title: 'violeds | APPLICATIONS - 포충',
            desc: 'Violeds의 만남은 앞으로도 계속됩니다. 점점 뻗어나가는 Violeds의 적용사례를 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, applications, 적용사례, 성공사례, 포충'
        },
        curing: {
            title: 'violeds | APPLICATIONS - 경화',
            desc: 'Violeds의 만남은 앞으로도 계속됩니다. 점점 뻗어나가는 Violeds의 적용사례를 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, applications, 적용사례, 성공사례, 경화'
        },
    },
    type: ['전체', '살균', '탈취', '포충', '경화'],
    techBtn: {
        pc: ['살균 기술정보', '탈취 기술정보', '포충 기술정보', '경화 기술정보'],
        mo: ['살균', '탈취', '포충', '경화']
    },
    data: [{
        keyword: 'refrigerator',
        type: ['살균', '탈취'],
        alt: '냉장고',
        img: app16,
        mainImg: {
            on: item16,
            off: item16_off
        },
        title: '냉장고',
        desc: '냉장고 내부에서는 음식물 표면의 오염물질로 인해 식중독을 일으키는 세균인 대장균, 황색포도상구균이 발생됩니다. 바이오레즈는 냉장고 내부에 부유하거나 벽면에 붙은 균을 99.9% 살균, 탈취해 음식물을 오랜 기간 보관할 수 있도록 합니다.',
        goApplications: '/applications?product=refrigerator#refrigerator',
        // detail: [{
        //     title: '델타변이 1초만에 살균하는 바이오레즈(Violeds)기술,델타변이 확산 방지 솔루션 제시',
        //     desc: '울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를 울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를',
        //     date: '2021. 08. 17',
        //     url: '1'
        // }, {
        //     title: '델타변이 1초만에 살균하는 바이오레즈(Violeds)기술,델타변이 확산 방지 솔루션 제시',
        //     desc: '울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를 울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를',
        //     date: '2021. 08. 17',
        //     url: '2'
        // }, {
        //     title: '델타변이 1초만에 살균하는 바이오레즈(Violeds)기술,델타변이 확산 방지 솔루션 제시',
        //     desc: '울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를 울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를',
        //     date: '2021. 08. 17',
        //     url: '3'
        // }, {
        //     title: '델타변이 1초만에 살균하는 바이오레즈(Violeds)기술,델타변이 확산 방지 솔루션 제시',
        //     desc: '울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를 울바이오시스의 바이오 연구팀은 전 연세대학교 정건섭 교수의 자문과 미국 UCSB(University of California, Santa Barbara) 대학의 노벨물리학수상자를',
        //     date: '2021. 08. 17',
        //     url: '4'
        // }]
    }, {
        keyword: 'airConditioner',
        type: ['살균'],
        alt: '에어컨',
        img: app22,
        mainImg: {
            on: item22,
            off: item22_off
        },
        title: '에어컨',
        desc: '에어컨을 가동하며 생기는 습기는 필터와 팬에 대장균과 황색포도상구균, 표피포도상구균 등 유해세균을 발생시킵니다. 세균이 번식할 수 있는 필터와 팬에 바이오레즈의 UVC를 조사하여 유해 세균을 살균합니다.',
        goApplications: '/applications?product=airConditioner#airConditioner'
    }, {
        keyword: 'washingMachine',
        type: ['살균'],
        alt: '세탁기',
        img: app02,
        mainImg: {
            on: item02,
            off: item13_off
        },
        title: '세탁기',
        desc: '세탁 후 세탁조 외통에 남아있는 습기는 악취와 각종 세균, 곰팡이들을 번식하게 하는 원인을 만듭니다. 바이오레즈의 살균 기술로 세탁조 외통의 세균을 사멸시켜 세탁조를 항상 깨끗한 상태로 유지합니다.',
        goApplications: '/applications?product=washingMachine#washingMachine'
    }, {
        keyword: 'airpurifier',
        type: ['살균', '탈취'],
        alt: '공기청정기',
        img: app01,
        mainImg: {
            on: item01,
            off: item01_off
        },
        title: '공기청정기',
        desc: '비말에 붙어 떠다니는 바이러스는 5mm 크기로 필터를 통해 걸러져 필터가 감염원에 오염될 수 있습니다. 바이오레즈는 공기청정기 필터에 적용되어 강력한 살균 및 탈취 효과를 나타냅니다.',
        goApplications: '/applications?product=airpurifier#airpurifier'
    }, {
        keyword: 'waterPurifier',
        type: ['살균'],
        alt: '정수기',
        img: app06,
        mainImg: {
            on: item06,
            off: item02_off
        },
        title: '정수기',
        desc: '고여있는 물로 인해 세균이나 물때가 발생될 수 있는 정수기 내부의 유해세균을 살균합니다. 정수기 내부의 코크, 직수관, 아이스룸, 정수통등을 UV 빛으로 살균하여 정수기 내부 위생상태를 깨끗하게 유지합니다.',
        goApplications: '/applications?product=waterPurifier#waterPurifier'
    }, {
        keyword: 'dishwasher',
        type: ['살균', '탈취'],
        alt: '식기세척기',
        img: app18,
        mainImg: {
            on: item18,
            off: item18_off
        },
        title: '식기세척기',
        desc: '식기 세척 후 잔여 음식물들은 거름망으로 걸러지게 됩니다. 거름망 세척 이후에도 잔여 음식물에서 비롯된 세균은 남게 됩니다. 잔존하는 세균 증식을 억제하기 위해 바이오레즈의 살균 기능으로 악취를 방지할 수 있습니다.',
        goApplications: '/applications?product=dishwasher#dishwasher'
    }, {
        keyword: 'vacuumCleaner',
        type: ['살균'],
        alt: '청소기',
        img: app19,
        mainImg: {
            on: item19,
            off: item19_off
        },
        title: '청소기',
        desc: '온갖 먼지와 쓰레기가 보관되는 청소기 먼지봉투 내부는 세균들이 번식하기 가장 좋은 환경입니다. 바이오레즈는 먼지 표면의 세균을 살균하여 청소기 먼지봉투 내부를 위생적으로 관리할 수 있습니다.',
        goApplications: '/applications?product=vacuumCleaner#vacuumCleaner'
    }, {
        keyword: 'steamCloset',
        type: ['탈취'],
        alt: '의류 관리기',
        img: app20,
        mainImg: {
            on: item20,
            off: item20_off
        },
        title: '의류관리기',
        desc: '의류에 깊게 베여있는 냄새는 의류 관리기의 1차 스팀으로 제거하기 어려울 수 있습니다. 바이오레즈가 적용된 UVA+ 광촉매 필터를 통해 남아있는 냄새까지 한 번 더 강력하게 탈취합니다.',
        goApplications: '/applications?product=steamCloset#steamCloset'
    }, {
        keyword: 'humidifier',
        type: ['살균'],
        alt: '가습기',
        img: app21,
        mainImg: {
            on: item21,
            off: item21_off
        },
        title: '가습기',
        desc: '가습기 내부는 항상 물이 담겨 있어 세균이 증식됩니다. 바이오레즈는 물통 내부에 UVC를 조사하여 생물막(Biofime) 생성을 억제해 내부에 증식하는 세균을 살균하여 가습기 관리를 용이하게 합니다.',
        goApplications: '/applications?product=humidifier#humidifier'
    }, {
        keyword: 'homeAppliance',
        type: [],
        alt: '가전제품',
        img: app17,
        mainImg: {
            on: item17,
            off: item17_off
        },
        title: '가전제품',
        desc: '생활 속 다양한 가전제품에 바이오레즈의 기술력으로 이루어진 살균, 탈취, 포충 기능을 제공하여 감염에 대한 걱정 없는 일상생활을 즐기실 수 있습니다.',
        goApplications: '/applications?product=homeAppliance#homeAppliance'
    }, {
        keyword: 'portableAirpurifier',
        type: ['살균'],
        alt: '이동식 공기청정기',
        img: app03,
        mainImg: {
            on: item03,
            off: item03_off
        },
        title: '이동식 공기청정기',
        desc: '비말에 붙어 떠다니는 바이러스는 5mm 크기로 필터를 통해 걸러져 필터가 감염원에 오염될 수 있습니다. 바이오레즈는 공기청정기 필터에 적용되어 강력한 살균 효과를 제공하며 휴대하면서도 사용이 가능합니다.',
        goApplications: '/applications?product=portableAirpurifier#portableAirpurifier'
    }, {
        keyword: 'disinfectingSurfaces',
        type: ['살균'],
        alt: '표면 살균기',
        img: app05,
        mainImg: {
            on: item05,
            off: item07_off
        },
        title: '표면 살균기',
        desc: '마스크를 포함하여 다양한 생활용품들의 표면에 묻어있는 세균과 바이러스는 인체에 치명적인 영향을 미칩니다. 바이오레즈는 99.9%의 살균력을 보이며 바이러스와 각종 유해 세균이 인체로 감염되는 것을 예방합니다.',
        goApplications: '/applications?product=disinfectingSurfaces#disinfectingSurfaces'
    }, {
        keyword: 'bidet',
        type: ['살균'],
        alt: '비데',
        img: app08,
        mainImg: {
            on: item08,
            off: item05_off
        },
        title: '비데',
        desc: '각종 세균이 번식하기 쉬운 변기 내부에 설치되는 비데는 특히 오염에 취약합니다. 직접 닦기 힘든 비데 노즐, 변좌 등에 바이오레즈 UV LED를 적용하여 각종 세균을 살균할 수 있어 항상 쾌적한 화장실 환경을 만듭니다.',
        goApplications: '/applications?product=bidet#bidet'
    }, {
        keyword: 'insectTrap',
        type: ['포충'],
        alt: '포충기',
        img: app09,
        mainImg: {
            on: item09,
            off: item06_off
        },
        title: '포충기',
        desc: '치명적인 바이러스를 옮기는 모기와 같은 해충은 퇴치가 필요합니다. 바이오레즈가 가진 곤충을 유인할 수 있는 최적화된 UV-A 파장을 활용하여 모기, 파리와 같은 해충을 유인, 포집할 수 있습니다.',
        goApplications: '/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'printer',
        type: ['경화'],
        alt: '프린터',
        img: app11,
        mainImg: {
            on: item11,
            off: item09_off
        },
        title: '프린터',
        desc: '특수용지 프린팅의 경우 UV 잉크를 UV 빛에 노출시켜 순간 경화시키는 방법을 사용합니다. 침투성이 낮은 특수용지에 빠르게 잉크를 건조할 수 있으며 인쇄 품질을 높이고 선명한 컬러 구현이 가능합니다.',
        goApplications: '/applications?product=printer#printer'
    }, {
        keyword: 'industrialCuring',
        type: ['경화'],
        alt: '산업용경화기',
        img: app12,
        mainImg: {
            on: item12,
            off: item11_off
        },
        title: '산업용경화기',
        desc: '산업용 제품 생산 시 빠르게 건조해 제품의 경도를 높일 수 있습니다. 바이오레즈의 기술은 출력 품질, 속도, 안정성이 뛰어나며 다양한 소재에 활용할 수 있어 효율적인 작업환경을 제공합니다.',
        goApplications: '/applications?product=industrialCuring#industrialCuring'
    }, {
        keyword: 'nailCuring',
        type: ['경화'],
        alt: '네일큐어 기기',
        img: app04,
        mainImg: {
            on: item04,
            off: item10_off
        },
        title: '네일큐어 기기',
        desc: '매니큐어를 빠르게 건조하고 경도를 높일 수 있습니다. 쉽고 빠른 경화 작용으로 네일아트에 효율적이며 장기간 손톱관리에 용이하여 손톱 건강을 지킬 수 있습니다.',
        goApplications: '/applications?product=nailCuring#nailCuring'
    }, {
        keyword: 'counterfeitDetection',
        type: [],
        alt: '위폐감별',
        img: app10,
        mainImg: {
            on: item10,
            off: item08_off
        },
        title: '위폐감별',
        desc: '위조지폐 여부를 판별하는 여러 기관 등에서 사용합니다. 화폐에 특수하게 인쇄된 표식을 UV 빛으로 조사하여 위조 여부를 손쉽게 판별할 수 있습니다.',
        goApplications: '/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'medicalDevice',
        type: [],
        alt: '의료기기',
        img: app14,
        mainImg: {
            on: item14,
            off: item14_off
        },
        title: '의료기기',
        desc: '다양한 의료 분야에서 사용되는 의료기기들에도 바이오레즈가 활용됩니다. 백반증 피부치료기, 임플란트 기기, 태닝 기기 등에 사용되며 안전하고 확실한 의료환경을 제공합니다.',
        goApplications: '/applications?product=medicalDevice#medicalDevice'
    }, {
        keyword: 'blackLightBulb',
        type: [],
        alt: '블랙라이트',
        img: app15,
        mainImg: {
            on: item15,
            off: item15_off
        },
        title: '블랙라이트',
        desc: '바이오레즈 기술력은 LED가 있는 곳에서 다양하게 활용됩니다. 무대조명(Entertaining)  연구실 검사기기(비파괴 검사, 이물 및 파티클 검사)에 사용되어 여러 분야에서 항상 최적의 환경을 조성합니다.',
        goApplications: '/applications?product=blackLightBulb#blackLightBulb'
    }]
}

export default ApplicationItems