import styled from 'styled-components';
import React, { useState, useEffect, useRef } from "react";
import { Progress } from 'antd'
import CloseBtn from '../images/vr/close_btn.png'
import SkipIc from '../images/vr/video_skip_ic.png'
import PopupIc from '../images/vr/video_popup_ic.png'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

const VrVideo = ({ onClosePopup, item, onShowPopupDetail, ...props}) => {

    const { t } = useTranslation('ApplicationItem_vr');

    const videoRef = useRef();

    // step 1: 살균하기 팝업, step 2: 영상 두번째 재생, step 3: 영상 종료
    const [videoStep, setVideoStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingTime, setLoadingTime] = useState(0)
    const [videoTime, setVideoTime] = useState(true);
    const [playSecondVideo, setPlaySecondVideo] = useState(false);
    const [appItem, setAppItem] = useState(() => {
        let _item = item.substring(item.indexOf('_') + 1)
        if (_item.includes('_')) {
            _item = _item.substring(_item.indexOf('_') + 1)
        }
        return _item
    })

    useEffect(() => {
        if (loading) {
            videoRef.current.play();
        }
    }, [loading])

    useEffect(() => {
        if (videoStep !== 0) clearInterval(videoTime)

        if (videoStep === 1) { // 4초 후 두번째 영상 자동 실행
            setPlaySecondVideo(setTimeout(() => {
                setVideoStep(2)
                videoRef.current.play();
            }, 4000))
        }

    }, [videoStep])

    const onVideoTimer = () => {
        setVideoTime(setInterval(() => {
            // console.log(videoRef.current.currentTime, t(`${appItem}.skipSecond`))
            if (videoRef.current.currentTime > t(`product.${appItem}.skipSecond`)) {
                videoRef.current.pause();
                setVideoStep(1)
            }
        }, 500))
    }

    const onSkipVideo = () => {
        if (videoStep === 0) {
            videoRef.current.pause();
            videoRef.current.currentTime = t(`product.${appItem}.skipSecond`) + 0.1;
            setTimeout(() => {
                setVideoStep(1)
            }, 1000)
        } else videoRef.current.currentTime = videoRef.current.duration
    }

    const onClickCloseBtn = () => {
        clearTimeout(playSecondVideo); 
        clearInterval(videoTime); 
        onClosePopup();
    }

    return (
        <Wrapper>
            <VideoPopup>

                <LoadingPop loading={loading}>
                    <LoadingTxt>LOADING</LoadingTxt>
                    <Progress percent={loadingTime} showInfo={false} trailColor={'white'}></Progress>
                </LoadingPop>

                {loading && <ClosePopup src={CloseBtn} onClick={() => onClickCloseBtn()} />}
                <Video 
                ref={videoRef} 
                src={`/videos/${item}.mp4`} 
                onPlay={e => {
                    if (videoStep === 0) onVideoTimer()
                }}
                onEnded={e => {
                    setVideoStep(3);
                }}
                onLoadStart={e => {
                    setLoadingTime(e.timeStamp / 10000 * 100);
                }}
                onProgress={e => {
                    // console.log('progress', e.timeStamp)
                    if (loadingTime < 100) {
                        setLoadingTime(e.timeStamp / 10000 * 100);
                    }
                    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                        setTimeout(() => {
                            setLoadingTime(100);

                            setTimeout(() => {
                                setLoading(true)
                            }, 300)
                        }, 800)
                    }
                }}
                onLoadedData={e => {
                    setLoadingTime(100);

                    setTimeout(() => {
                        setLoading(true)
                    }, 300)
                }}
                loading={loading}
                playsInline muted />

                {(loading && videoStep !== 3 && videoStep !== 1 ) &&
                    <SkipArea>
                        <SkipButton onClick={onSkipVideo}>
                            <SkipIcImage src={SkipIc} />
                            SKIP
                        </SkipButton>
                        <SkipButton onClick={() => {
                            // videoRef.current.pause();
                            clearTimeout(playSecondVideo); 
                            clearInterval(videoTime)
                            onShowPopupDetail(appItem)
                        }}>
                            <PopupIcImage src={PopupIc} />
                            {t('common.detail')}
                        </SkipButton>
                    </SkipArea>
                }

                {videoStep === 1 &&
                    <PopupNextStep show={videoStep === 1}>
                        <ItemTitle>{t(`product.${appItem}.name`)}</ItemTitle>
                        <ItemDesc>{t(`product.${appItem}.videoPopupDesc`)}</ItemDesc>
                        <NextVideoBtn onClick={() => {
                            setVideoStep(2)
                            setTimeout(() => {
                                videoRef.current.play();
                            }, 500)
                        }}>{t(`product.${appItem}.videoPlayWord`)}</NextVideoBtn>
                    </PopupNextStep>
                }

                {videoStep === 3 && 
                    <PopupBtn show={videoStep === 3} onClick={() => {
                        clearInterval(videoTime);
                        onShowPopupDetail(appItem)}}>
                        {t('common.detail')}
                    </PopupBtn>
                }
            </VideoPopup>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
`

const VideoPopup = styled.div`
    // opacity: ${props => props.item ? 1 : 0};
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    max-width: 1300px;
    transform: translate(-50%, -50%);
    // transition: all .38s .23s;
    
    &:hover {
        img:last-child {
            opacity: 1;
        }
    }

    @media screen and (max-width: 900px) {
        top: 54%;
        width: auto;
        height: 85%;
    }
`

const ClosePopup = styled.img`
    position: absolute;
    top: 13px;
    right: 13px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 50;

    @media screen and (max-width: 900px) {
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
    }
`

const Video = styled.video`
    width: 100%;
    opacity: ${props => props.loading ? 1 : 0};
    border-radius: 14px;

    @media screen and (max-width: 900px) {
        width: auto;
        height: 100%;
    }
`

const LoadingPop = styled.div`
    opacity: ${props => props.loading ? 0 : 1};
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    padding: 40px 60px;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center;
    transition: all .3s;
    z-index: ${props => props.loading ? -1 : 10};

    .ant-progress-outer {
        padding: 8px;
        border: 1px solid #fff;
        border-radius: 40px;
        line-height: 0;
    }
    .ant-progress-success-bg, .ant-progress-bg {
        background-color: #8f0a7c;
        height: 4px !important;
    }
`

const LoadingTxt = styled.div`
    padding: 0 7px 15px;
    font-size: 38px;
    font-weight: bold;
    color: #fff;

    @media screen and (max-width: 900px) {
        font-size: 30px;
    }
`

const PopupNextStep = styled.div`
    opacity: ${props => props.show ? 1 : 0};
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    padding: 40px;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, .6);
    text-align: center;
    transition: all .3s .23s;
    z-index: ${props => props.show ? 10 : -1};

    @media screen and (max-width: 900px) {
        width: 70%;
        padding: 27px 40px;
    }
`

const ItemTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #fff;

    @media screen and (max-width: 900px) {
        font-size: 20px;
    }
`

const ItemDesc = styled.div`
    padding: 18px 0;
    font-size: 16px;
    color: #fff;
    white-space: pre-line;
    word-break: keep-all;

    @media screen and (max-width: 900px) {
        padding: 14px 0;
        font-size: 12px;
    }
`

const NextVideoBtn = styled.div`
    display: inline-block;
    padding: 7px 40px 8px;
    border-radius: 40px;
    background-color: #a81993;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        background-color: #DE10C1;
    }

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
`

const PopupBtn = styled(NextVideoBtn)`
    opacity: ${props => props.show ? 1 : 0};
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s .23s;
`

const FinishButton = styled.button`
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8f0a7c;
    box-shadow: 0 0 5px #8f0a7c;
    border-radius: 25px;
    transition: .8s;
    
`

const SkipArea = styled.div`
    // display: none;
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: auto;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);

    @media screen and (max-width: 900px) {
        bottom: 8px;
        width: 100%;
        height: 30px;
    }
`

const SkipButton = styled.a`
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);
    padding: 0 15px;
    transition: opacity .3s;
    &:hover {
        color: #fff;
        opacity: 0.8;
    }

    @media screen and (max-width: 900px) {
        font-size: 12px;
    }
`

const SkipIcImage = styled.img`
    margin-right: 4px;
`

const PopupIcImage = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 4px;
`

export default withRouter(VrVideo)