import styled, { createGlobalStyle } from 'styled-components';
import React, { useState, useEffect } from 'react'
import { 
    Route, 
    Switch,
    withRouter
} from 'react-router-dom'
import { notification, Button } from 'antd'
import {
    InfoCircleFilled
} from '@ant-design/icons'
import GNB from './components/GNB'
import Main from './pages/Main'
import Media from './pages/Media'
import Tech from './pages/Tech'
import MediaDetail from './pages/MediaDetail'
import ShowRoom from './pages/ShowRoom';
import Company from './pages/Company';
import Applications from './pages/Applications';
import ApplicationsDetail from './pages/ApplicationsDetail';
import Footer from './components/Footer';
import Inquiry from './components/Inquiry'
import Vrpano from './pages/Vrpano';
import Policy from './pages/Policy'
import GNB_VR from './components_vr/GNB_VR';
import StoreMap from './pages/StoreMap';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
    body {
        font-family: 'Noto Sans KR', sans-serif;
        padding: 0;
        margin: 0;
    }
    a {
        text-decoration: none;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .ant-notification-notice {
        width: 508px;
    }
`

const App = ({ ...props }) => {
    
    let locale = ''
    if (props.location.pathname.indexOf('/en') >= 0) {
        locale = '/en'
    } else {
        if (props.location.pathname.indexOf('/jp') >= 0) {
            locale = '/jp'
        }
    }
    
    const [currentLocale, setCurrentLocale] = useState(locale)

    const closing = () => {

        
        notification.close('gdpr')
    }

    const accept = () => {
        
        window.sessionStorage.setItem('vl_gdpr', 'true')
    }

    const onGdprAcceptClick = async (e) => {
        accept()
        closing()
    }

    
    
    useEffect(() => {
        const gdpr = () => {
            if (currentLocale === '/en') {
                if (window.sessionStorage.getItem('vl_gdpr') === null || window.sessionStorage.getItem('vl_gdpr') === undefined) {
                    notification.info({
                        key: 'gdpr',
                        message: null,
                        description: <MessageArea>We use cookies on our website to improve your experience, show optimized content, and for analytics. By agreeing, you accept the use of cookies in accordance with our <a href="/en/policy/cookie" target="_blank">cookie policy</a></MessageArea>,
                        placement: 'bottomLeft',
                        duration: 0,
                        icon: <InfoCircleFilled style={{color:'#8f0a7c'}} />,
                        btn: <AcceptButton onClick={onGdprAcceptClick} type={'primary'} size={'default'}>I ACCEPT</AcceptButton>,
                        onClose: () => {
                            window.sessionStorage.setItem('vl_gdpr', 'false')
                        }
                    })
                } else if (window.sessionStorage.getItem('vl_gdpr') === 'true') {
                    accept()
                }
                
            }
        }
        gdpr()
    }, [])
    
    return (
        <>
            <GlobalStyle />
            
            {props.location.pathname.indexOf('/showroom/') >= 0 ? (
                <GNB_VR currentLocale={currentLocale} />
            ) : (
                <GNB currentLocale={currentLocale} />
            )}
            <Switch>
                <Route exact path={['/', '/en', '/jp']}>
                    <Main currentLocale={currentLocale} />
                </Route>
                <Route path={['/company', '/en/company', '/jp/company']}>
                    <Company currentLocale={currentLocale} />
                </Route>
                <Route path={[
                    '/tech', 
                    '/tech/:tab', 
                    '/en/tech', 
                    '/en/tech/:tab',
                    '/jp/tech', 
                    '/jp/tech/:tab'
                    ]}>
                    <Tech currentLocale={currentLocale} />
                </Route>
                <Route exact path={[
                    '/applications',
                    '/applications/sterilization',
                    '/applications/deodorization',
                    '/applications/insect-attraction',
                    '/applications/curing',
                    '/en/applications',
                    '/en/applications/sterilization',
                    '/en/applications/deodorization',
                    '/en/applications/insect-attraction',
                    '/jp/applications/curing',
                    '/jp/applications',
                    '/jp/applications/sterilization',
                    '/jp/applications/deodorization',
                    '/jp/applications/insect-attraction',
                    '/jp/applications/curing'
                    ]}>
                    <Applications currentLocale={currentLocale} />
                </Route>
                <Route path={[
                    '/applications/:pid',
                    '/en/applications/:pid',
                    '/jp/applications/:pid'
                ]}>
                    <ApplicationsDetail currentLocale={currentLocale} />
                </Route>
                <Route exact path={[
                    '/media',
                    '/media/influencer',
                    '/media/social',
                    '/media/news',
                    '/en/media',
                    '/en/media/influencer',
                    '/en/media/social',
                    '/en/media/news',
                    '/jp/media',
                    '/jp/media/influencer',
                    '/jp/media/social',
                    '/jp/media/news'
                    ]}>
                    <Media currentLocale={currentLocale} />
                </Route>
                <Route path={['/media/:pid', '/en/media/:pid', '/jp/media/:pid']}>
                    <MediaDetail currentLocale={currentLocale} />
                </Route>
                <Route exact path={['/showroom', '/en/showroom', '/jp/showroom']}>
                    <ShowRoom currentLocale={currentLocale} />
                </Route>
                <Route exact path={['/showroom/:id', '/en/showroom/:id', '/jp/showroom/:id']}>
                    <Vrpano currentLocale={currentLocale} />
                </Route>
                <Route path={['/policy/privacy', '/en/policy/privacy', '/en/policy/ccpa', '/en/policy/cookie', '/jp/policy/privacy']}>
                    <Policy currentLocale={currentLocale} />
                </Route>
                <Route path={['/store-map']}>
                    <StoreMap currentLocale={currentLocale} />
                </Route>
            </Switch>
            
            {props.location.pathname.indexOf('/showroom') === -1 &&
            <>
                <Footer currentLocale={currentLocale} />
            </>
            }
            <Inquiry currentLocale={currentLocale} />
        </>
    )
}

const AcceptButton = styled(Button)`
    border: none;
    
    background: #8f0a7c;
    font-weight: bold;

    &:active {
        
        background: #8f0a7c;
    }

    &:focus {
        
        background: #E20CC3;
    }

    &:hover {
        
        background: #E20CC3;
        box-shadow: 0 0 5px #E20CC3;
    }
`

const MessageArea = styled.div`
    padding-bottom: 6px;
    a {
        color: #8f0a7c;
    }
`

export default withRouter(App)