import MediaMore01 from '../../images/main/main_media_more_01.png'
import MediaMore02 from '../../images/main/main_media_more_02.png'
import MediaMore03 from '../../images/main/main_media_more_03.png'
import MediaMoreMobile01 from '../../images/main/main_media_more_mobile_01.png'
import MediaMoreMobile02 from '../../images/main/main_media_more_mobile_02.png'
import MediaMoreMobile03 from '../../images/main/main_media_more_mobile_03.png'

import ApplicationItems from './ApplicationItems'

export default {
    alt: {
        home: {
            uvled: [
                '99.9% Sterilization',
                'Zero Toxic Chemicals',
                'Long Lifetime of 50,000 hrs',
                'Low Energy Consumption'
            ],
            tech: [
                'Sterilization',
                'Air Purification',
                'Insect Attraction',
                'Curing'
            ],
            application: {
                refrigerator: 'Refrigerators',
                airConditioner: 'Air Conditioners',
                washingMachine: 'Washing Machines',
                airpurifier: 'Air Purifiers',
                waterPurifier: 'Water Purifiers',
                dishwasher: 'Dishwashers',
                vacuumCleaner: 'Vacuum Cleaners',
                steamCloset: 'Steam Closets',
                humidifier: 'Humidifiers',
                homeAppliance: 'Home Appliances',
                portableAirpurifier: 'Portable Air Purifiers',
                disinfectingSurfaces: 'Disinfecting Surfaces',
                bidet: 'Bidets',
                insectTrap: 'Insect Trap',
                printer: 'Printers',
                industrialCuring: 'Industrial Curing',
                nailCuring: 'Nail Curing',
                counterfeitDetection: 'Counterfeit Detection',
                medicalDevice: 'Medical Devices',
                blackLightBulb: 'Black Light Bulbs'
            },
            media: [
                'SOCIAL CONTENTS',
                'INFLUENCER',
                'PRESS'
            ],
            inquiry: 'INQUIRY TO BUY'
        }
    },
    brandReview: {
        section1: {
            title: {
                pc: 'Bacterial Disinfection',
                mo: 'Bacterial\nDisinfection'
            },
            desc: {
                pc: 'UVC/A destroys the structural integrity of DNA and RNA in\nmicroorganisms. Viruses are destroyed as their metabolism\nis deactivated and all replication activity ceases.',
                mo: 'UVC/A destroys the structural integrity of DNA and RNA in microorganisms.\nViruses are destroyed as their metabolism is deactivated and all replication activity ceases.'
            }
        },
        section2: {
            title: 'UV LED',
            items: [{
                title: '99.9% Sterilization',
                desc: 'Successfully sterilizes 99.9% of E. coli, an important indicator bacterium for contamination, and Staphylococcus aureus, the bacterium responsible for food poisoning.'
            }, {
                title: 'Zero Toxic Chemicals',
                desc: 'The technology contains zero mercury and toxic chemicals, making it non-toxic to the human body and safe to use.'
            }, {
                title: 'Long Lifetime of 50,000 hrs',
                desc: 'Long lifetime of 50,000 hrs according to L50 standards allows for reduced replacement costs.'
            }, {
                title: 'Low Energy Consumption',
                desc: 'The UV LED\'s low energy consumption reduces energy costs.'
            }]
        },
        section3: {
            title: 'Applications',
            desc: {
                pc: 'Violeds technology has applications in sterilization, air purification,\ninsect attraction, and curing by installing UV LED modules in various\nspaces and products. In addition, it is used across industries as a \nUV LED technology that covers the entire UV spectrum.',
                mo: 'Violeds technology has applications in sterilization, air purification, insect attraction, and curing by installing UV LED modules in various spaces and products.\nIn addition, it is used across industries as a UV LED technology that covers the entire UV spectrum.'
            }
        }
    },
    technology: {
        title: 'TECHNOLOGY',
        desc: 'The collaboration between Seoul Viosys and SETi in the development of UV LED clean technology enables\ndiverse applications in products for the sterilization, purification, and deodorization of water, air, and surfaces.\nThe technology can be used for multiple purposes, from everyday goods to life science instruments for treatment and diagnosis.\nMoreover, this advanced technology surpasses the simple combination of a photocatalyst, and features a patented\noptical integration level and beam angle for the integrated light source.',
        items: [{
            title: 'Sterilization',
            desc: 'We provide UV LED PKG and sterilization modules for the sterilization of surfaces, water, and air.',
            buttonLabel: 'LEARN MORE',
            href: '/en/tech/sterilization'
        }, {
            title: 'Air Purification',
            desc: 'Unparalleled photocatalytic technology provides superior results in eliminating harmful substances in the air.',
            buttonLabel: 'LEARN MORE',
            href: '/en/tech/deodorization'
        }, {
            title: 'Insect Attraction',
            desc: 'We have the solution to attracting and trapping flying pests and insects by controlling the UV wavelength, intensity, and type of emission.',
            buttonLabel: 'LEARN MORE',
            href: '/en/tech/insect-attraction'
        }, {
            title: 'Curing',
            desc: 'We provide the best curing solution as the world\'s only producer of UV LEDS that cover the entire UV spectrum.',
            buttonLabel: 'LEARN MORE',
            href: '/en/tech/curing'
        }]
    },
    applicationField: {
        title: {
            pc: 'UV APPLICATION FIELD',
            mo: 'UV APPLICATION\nFIELD'
        },
        buttonLabel: 'LEARN MORE',
        items: ApplicationItems
    },
    media: {
        title: 'MEDIA',
        buttons: [{
            title: 'SOCIAL CONTENTS',
            desc: 'MORE >',
            pc: MediaMore03,
            mobile: MediaMoreMobile03,
            link: '/en/media/social'
        }, {
            title: 'INFLUENCER',
            desc: 'MORE >',
            pc: MediaMore02,
            mobile: MediaMoreMobile02,
            link: '/en/media/influencer'
        }, {
            title: 'PRESS',
            desc: 'MORE >',
            pc: MediaMore01,
            mobile: MediaMoreMobile01,
            link: '/en/media/news'
        }],
        api: '/media/en/list'
    }
}