import styled, { keyframes } from 'styled-components'
import React, { useState, useEffect } from 'react'
import markOn from '../images/vr/markOn.png'

const MapMark = ({ name, url, top, left, width, height, MarkTop, MarkLeft, onHoverMark, onNotHoverMark, show }) => {

    return (
        <MapMarkBtnWrap
        href={url}
        width={width}
        height={height}
        top={top} 
        left={left} 
        onMouseEnter={onHoverMark}
        onMouseLeave={onNotHoverMark} >
            {show &&
                <MarkWrap MarkTop={MarkTop} MarkLeft={MarkLeft}>
                    <MarkName>{name}</MarkName>
                    <Mark />
                </MarkWrap>
            }
        </MapMarkBtnWrap>
    )
}

const MapMarkBtnWrap = styled.a`
    position: absolute;
    top: ${props => props.top}%;
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    height: ${props => props.height}%;
    display: block;
    transform: rotate(-24deg);
    z-index: 10;
`

const MarkWrap = styled.div`
    position: absolute;
    bottom: ${props => props.MarkTop}%;
    left: ${props => props.MarkLeft}%;
    width: 26px;
    height: 33px;
    transform: translateX(-50%) rotate(24deg);
    text-align: center;
`

const MarkMove = keyframes`
    0%{
        margin-top: 0;
    }
    100%{
        margin-top: 6px;
    }
`

const Mark = styled.div`
    width: 26px;
    height: 33px;
    margin: auto;
    background: url(${markOn}) no-repeat center / contain;
    animation: ${MarkMove} .8s ease-in infinite alternate;
`

const MarkName = styled.div`
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 18px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
    font-weight: bold;
    line-height: 1.2;
    pointer-events: none;
    white-space: nowrap;
`


export default MapMark