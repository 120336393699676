import app01 from '../../images/vr/applications/app01.png'
import app02 from '../../images/vr/applications/app02.png'
import app03 from '../../images/vr/applications/app03.png'
import app04 from '../../images/vr/applications/app04.png'
import app05 from '../../images/vr/applications/app05.png'
import app06 from '../../images/vr/applications/app06.png'
import app07 from '../../images/vr/applications/app07.png'
import app08 from '../../images/vr/applications/app08.png'
import app09 from '../../images/vr/applications/app09.png'
import app10 from '../../images/vr/applications/app10.png'
import CMAFBADK3A from '../../images/vr/applications/product/CMA-FBA-DK3A.png'
import CMAFBCAR4A from '../../images/vr/applications/product/CMA-FBC-AR4A.png'
import CMAFBMCO4A from '../../images/vr/applications/product/CMA-FBM-CO4A.png'
import CMDFSCCO1A from '../../images/vr/applications/product/CMD-FSC-CO1A.png'
import CMDFSCCOGA from '../../images/vr/applications/product/CMD-FSC-COGA.png'
import CMDT5CCO1A from '../../images/vr/applications/product/CMD-T5C-CO1A.png'
import CMFARA02 from '../../images/vr/applications/product/CMF-AR-A02.png'
import CMFARA03 from '../../images/vr/applications/product/CMF-AR-A03.png'
import CMWFCCCO1B from '../../images/vr/applications/product/CMW-FCC-CO1B.png'
import CUD7GF1A from '../../images/vr/applications/product/CUD7GF1A.png'
import CUD7GF1B from '../../images/vr/applications/product/CUD7GF1B.png'
import CUN66A1B from '../../images/vr/applications/product/CUN66A1B.png'

const ApplicationItem = {
    common: {
        detail: '製品詳細を見る',
        btnInfo: '* 上のボタンをクリックして詳細機能を確認してください。',
        showProduct: '使用されたvioleds製品を見る',
        violedsProduct: 'VIOLEDS 製品',
        moduleInfo: '* こちらの商品は、カスタムモジュールの製作が可能です。'
    },
    product: {
        refrigerator: {
            name: '冷蔵庫',
            type: ['물 살균', '공기 살균', '탈취'],
            img: app05,
            desc: '家族と子供たちの健康を脅かす冷蔵庫の中の細菌と悪臭をvioleds技術できれいに除去し、冷蔵庫内を快適な状態に保ちます。',
            popupThumb: '/images/vr/thumb01.png',
            videoPopupDesc: '冷蔵庫で保管される食べ物は、低温細菌(リステリア、アオカビ)やキムチの匂い(メチルメチルメチルアミン)、魚の匂い(トリメチルアミン)が発生する可能性があります。',
            videoPlayWord: '滅菌する',
            skipSecond: 33.20,
            slidePosition: [{
                top: '53%',
                left: '7%',
            }, {
                top: '28%',
                left: '14%',
            }, {
                top: '10%',
                left: '40%',
            }],
            slide: [{
                title: '冷蔵庫の製氷機',
                desc: '製氷機内部の氷点下20度の環境で増殖し、食中毒を起こすリステリア菌を含む各種細菌及びウイルスを99.9%除菌します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }, {
                title: '冷蔵庫のウォーターサーバー',
                desc: '浄水された水が蛇口を通り、空気に触れる際に細菌やウイルスに汚染される可能性があります。 Violedsはこの細菌やウィルスを99.9%まで除菌し、きれいな水を提供することができます。',
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '冷蔵庫の内部脱臭',
                desc: '冷蔵庫の中の低温細菌(リステリア、アオカビ)とキムチの匂い(メチルメチルメチルアミン)、魚の匂い(トリメチルアミン)を90%以上除去し、快適な冷蔵庫環境を保ちます。',
                pkg: [{
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMF-AR-A02',
                    img: CMFARA02,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A02.pdf'
                }, {
                    name: 'CMF-AR-A03',
                    img: CMFARA03,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMF-AR-A03.pdf'
                }]
            }],
        }, 
        airConditioner: {
            name: 'エアコン',
            type: ['공기 살균'],
            img: app02,
            desc: '1秒に99.9%のコロナウイルス除菌力を立証したvioleds技術を利用し、室内の空気を循環させて冷たくする際に発生する細菌およびウイルス増殖を抑制します。',
            popupThumb: '/images/vr/thumb02.png',
            videoPopupDesc: 'エアコンの稼動で発生する湿気は熱交換器とファンに大腸菌と黄色ブドウ球菌、表皮ブドウ球菌などの有害細菌を発生させることがあります。',
            videoPlayWord: '滅菌する',
            skipSecond: 22.20,
            slidePosition: [{
                top: '20%',
                left: '50%',
            }, {
                top: '31%',
                left: '37%',
            }],
            slide: [{
                title: 'エアコンの熱交換器',
                desc: 'エアコンの内部に入ってくる空気中の細菌とウイルスが熱交換器の表面に触れた場合、violeds技術を利用して細菌とウイルスをほぼ完璧に撲滅します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: 'エアコンのファン',
                desc: 'エアコンの内部に入ってくる空気の細菌とウイルスがファン表面に触れた場合、violedsの除菌力で99%以上撲滅させ、有害な空気接触を最小限に抑えます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        washingMachine: {
            name: '洗濯機',
            type: ['물 살균', '탈취'],
            img: app01,
            desc: '湿度が高い環境で洗濯をすると細菌とカビが発生しやすくなります。 一度発生したカビや細菌は、洗濯槽クリーナーで完全に除去することは困難です。 しかしvioledsは、発生初期から除菌して増殖を抑え、清潔な状態を保つことをサポートします。',
            popupThumb: '/images/vr/thumb03.png',
            videoPopupDesc: '洗濯後、洗濯槽の外筒に残っている水分や汚れによって悪臭や各種の細菌、カビを繁殖させます。',
            videoPlayWord: '滅菌する',
            skipSecond: 13.50,
            slidePosition: [{
                top: '26%',
                left: '18%',
            }],
            slide: [{
                title: '洗濯槽の外筒',
                desc: 'violeds技術により洗剤のカスや水垢がたまって発生するカビや細菌の増殖を防ぎ、皮膚炎やアレルギーを防ぎます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        }, 
        airpurifier: {
            name: '空気清浄機',
            type: ['공기 살균', '탈취'],
            img: app10,
            desc: '空気中に浮遊する細菌やウイルスは、HEPAフィルターで濾過されますがフィルターに残り除菌されずに増殖します。 デルタ変異株コロナウイルスに対しても1秒で99.9%の除菌力を立証したvioleds技術を利用して、フィルター表面の飛沫とともに流入した細菌·ウイルスを除菌します。',
            popupThumb: '/images/vr/thumb04.png',
            videoPopupDesc: '外部から空気清浄機に流入した空気中のウイルスが、フィルター部位に残って繁殖すると、再び外部に排出される可能性があります。',
            videoPlayWord: '滅菌する',
            skipSecond: 16,
            slidePosition: [{
                top: '7%',
                left: '35%',
            }, {
                top: '51%',
                left: '36%',
            }],
            slide: [{
                title: '空気清浄機のファン',
                desc: '空気清浄機内部に流入する空気中の細菌とウイルスがファン表面に触れた際、violedsの強力な除菌力により有害なウイルスを不活性化します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: '空気清浄機のフィルター',
                desc: '空気清浄機内部のフィルター表面に付着した細菌やウイルスをvioledsの除菌力でもう一度取り除くことで、より綺麗な空気を提供します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        }, 
        waterPurifier: {
            name: '浄水器',
            type: ['물 살균'],
            img: app03,
            desc: '水が直接触れる蛇口からアイスルームまで、多様に除菌できるvioleds技術を利用して残留性物質なしで細菌を99%以上撲滅し、健康な水と氷を提供しています。',
            popupThumb: '/images/vr/thumb05.png',
            videoPopupDesc: '浄水器の内部は常に水が溜まっているため、水中の細菌によって二次汚染が発生することがあります。',
            videoPlayWord: '滅菌する',
            skipSecond: 13.20,
            slidePosition: [{
                top: '27%',
                left: '14%',
            }, {
                top: '46%',
                left: '46%',
            }, {
                top: '24%',
                left: '69%',
            }],
            slide: [{
                title: '浄水器の蛇口',
                desc: '浄水器本体から浄水された水が蛇口を通して出る瞬間、空気に触れると細菌やウイルスに汚染されます。 これをvioleds技術力で99%以上撲滅します。',
                pkg: [{
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '浄水器の配管、流れる水の除菌',
                desc: 'フィルターに溜まっている水中の細菌による二次汚染を防ぎ、フィルターで濾過されない細菌やウイルスを99.9%近く除菌してきれいな水を提供します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-COGA',
                    img: CMDFSCCOGA,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-COGA.pdf'
                }]
            }, {
                title: 'アイスルーム',
                desc: 'アイスルーム内部の氷点下20度の環境で増殖し、食中毒を起こすリステリア菌を含む各種細菌及びウイルスを99.9%除菌します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        dishwasher: {
            name: '食器洗浄機',
            type: ['물 살균', '탈취'],
            img: app06,
            desc: 'violedsは、食器洗浄機に使用される水を除菌して無駄な水を減らし、洗浄された食器の二次汚染を防ぎ、また、洗浄中に溜まった食べ物の腐敗を防ぐことで、不快な匂いの発生を防ぎます。',
            popupThumb: '/images/vr/thumb06.png',
            videoPopupDesc: '食器洗い後、フィルターに残っている飲食物や湿気は細菌が繁殖しやすい環境です。',
            videoPlayWord: '滅菌する',
            skipSecond: 20.20,
            slidePosition: [{
                top: '30%',
                left: '20%',
            }, {
                top: '68%',
                left: '40%',
            }],
            slide: [{
                title: '食器 保管台',
                desc: 'violeds技術は、食洗機内部のウイルスや細菌の繁殖を防ぎ、洗浄された食器の清潔な状態を保ちます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }, {
                title: '排水フィルター',
                desc: 'violeds技術を利用して洗浄中に溜まった生ごみの腐敗を防ぎ、食器洗浄機内部の不快な匂いが発生しないようにします。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }]
        },
        vacuumCleaner: {
            name: '掃除機',
            type: ['물 살균'],
            img: app08,
            desc: 'violeds技術を利用して溜まったほこりの中のウイルスや細菌を除菌することで、掃除後に一度きれいになった空間をまた汚染させる二次感染を防止します。',
            popupThumb: '/images/vr/thumb07.png',
            videoPopupDesc: '掃除機内部のゴミ袋は、ほこりが溜まり続け、床に直接触れるブラシ/ローラーはホコリに直接触れるため細菌繁殖および簡単に汚染する可能性があります。',
            videoPlayWord: '滅菌する',
            skipSecond: 17,
            slidePosition: [{
                top: '65%',
                left: '24%',
            }, {
                top: '71%',
                left: '59%',
            }],
            slide: [{
                title: '掃除機内のゴミ袋',
                desc: 'violeds除菌処理技術により、ほこりがたまるゴミ袋内のウイルスや細菌の増殖を99.9%まで抑制し、二次汚染を防止できます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMD-FSC-CO1A',
                    img: CMDFSCCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-FSC-CO1A.pdf'
                }]
            }, {
                title: '掃除機ヘッドブラシ/ローラー',
                desc: '床と直接接触するブラシ/ローラーの表面を除菌して二次汚染を予防し、床面の細菌やウイルスまで除去することで快適な環境を保ちます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUD7GF1A',
                    img: CUD7GF1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1A.pdf'
                }],
                module: [{
                    name: 'CMA-FBC-AR4A',
                    img: CMAFBCAR4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBC-AR4A.pdf'
                }]
            }]
        },
        steamCloset: {
            name: '衣類管理機',
            type: ['탈취', '공기 살균'],
            img: app07,
            desc: 'violedsはスチームではなくせない衣類の奥深くに染み込んでいる不快な匂いまで分解して快適な衣類の状態を整えます。',
            popupThumb: '/images/vr/thumb08.png',
            videoPopupDesc: '屋外での活動で衣類に付着した細菌は匂いを誘発します。 この細菌は衣類管理機のスチームだけでは除去しにくい場合があります。',
            videoPlayWord: '滅菌する',
            skipSecond: 23.6,
            slidePosition: [{
                top: '4%',
                left: '36%',
            }],
            slide: [{
                title: 'クローゼットの上部',
                desc: '衣類の中の不快な匂いであるタバコの匂い(ピリジン)、汗の匂い(イソバレリックアシッド)及び肉の匂い(バレルアルデヒド)を99.9%除去して、清らかな服の状態を保ちます。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }, {
                    name: 'CUN66A1B',
                    img: CUN66A1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUN66A1B.pdf'
                }],
                module: [{
                    name: 'CMA-FBM-CO4A',
                    img: CMAFBMCO4A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBM-CO4A.pdf'
                }, {
                    name: 'CMA-FBA-DK3A',
                    img: CMAFBADK3A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMA-FBA-DK3A.pdf'
                }]
            }]
        },
        humidifier: {
            name: '加湿器',
            type: ['물 살균'],
            img: app09,
            desc: 'きれいに浄水された加湿器の水でも、1日以上空気に露出した場合は、細菌に汚染されることがあり、給水タンクの水が流れる際に、一緒に流れる恐れがあります。 violeds技術は、細菌の繁殖を抑え、給水タンクを清潔な状態に保つのに役立ちます。',
            popupThumb: '/images/vr/thumb09.png',
            videoPopupDesc: '加湿器内部は湿っているので、生物膜(バイオフィルム)が発生しやすい環境です。これにより細菌が繁殖することがあります。',
            videoPlayWord: '滅菌する',
            skipSecond: 13.15,
            slidePosition: [{
                top: '38%',
                left: '65%',
            }],
            slide: [{
                title: '給水タンク',
                desc: '加湿器の給水タンクに溜まっている水を化学物質を使用しないvioleds技術により、細菌およびウイルスを99.9%除菌し、清潔で安全な加湿環境を構築するとともに、バイオフィルム生成を抑制することで洗浄管理が楽になります。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMW-FCC-CO1B',
                    img: CMWFCCCO1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMW-FCC-CO1B.pdf'
                }]
            }]
        }, 
        bidet: {
            name: '温水洗浄便座',
            type: ['물 살균'],
            img: app04,
            desc: 'トイレの主な汚染原因である便器とその中で汚染に最も脆弱な洗浄ノズルをvioledsで除菌し、不安なく快適にトイレ使用ができます。',
            popupThumb: '/images/vr/thumb10.png',
            videoPopupDesc: '洗浄ノズルは各種、有害細菌が繁殖しやすい便座に取り付けされており、また、掃除が難しいです。',
            videoPlayWord: '滅菌する',
            skipSecond: 16,
            slidePosition: [{
                top: '53%',
                left: '45%',
            }],
            slide: [{
                title: '温水洗浄ノズル',
                desc: 'violedsの除菌技術は、直接拭くのが困難な温水洗浄ノズルにvioledsを照射し、各種細菌を99%近く除菌します。',
                pkg: [{
                    name: 'CUD7GF1B',
                    img: CUD7GF1B,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CUD7GF1B.pdf'
                }],
                module: [{
                    name: 'CMD-T5C-CO1A',
                    img: CMDT5CCO1A,
                    detail: 'http://www.seoulviosys.com/jp/product/new.asp',
                    datasheet: 'https://violeds.com/res/datasheet/CMD-T5C-CO1A.pdf'
                }]
            }]
        }
    }
}

export default ApplicationItem