import MediaMore01 from '../../images/main/main_media_more_01.png'
import MediaMore02 from '../../images/main/main_media_more_02.png'
import MediaMore03 from '../../images/main/main_media_more_03.png'
import MediaMoreMobile01 from '../../images/main/main_media_more_mobile_01.png'
import MediaMoreMobile02 from '../../images/main/main_media_more_mobile_02.png'
import MediaMoreMobile03 from '../../images/main/main_media_more_mobile_03.png'

import ApplicationItems from './ApplicationItems'

export default {
    alt: {
        home: {
            uvled: [
                '99.9% 除菌力',
                '有害化学物質 Zero',
                '50,000時間の長い寿命',
                '低い消費電力'
            ],
            tech: [
                '除菌',
                '空気清浄',
                '補虫',
                '硬化'
            ],
            application: {
                refrigerator: '冷蔵庫',
                airConditioner: 'エアコン',
                washingMachine: '洗濯機',
                airpurifier: '空気清浄機',
                waterPurifier: 'ウォーターサーバー',
                dishwasher: '食器洗浄機',
                vacuumCleaner: '掃除機',
                steamCloset: '衣類管理機',
                humidifier: '加湿器',
                homeAppliance: '家電製品',
                portableAirpurifier: '移動式空気清浄機',
                disinfectingSurfaces: '表面除菌機',
                bidet: 'ウォシュレット',
                insectTrap: '捕虫器',
                printer: 'プリンター',
                industrialCuring: '産業用硬化機',
                nailCuring: 'ネイル硬化機',
                counterfeitDetection: '偽札鑑別機',
                medicalDevice: '医療機器',
                blackLightBulb: 'ブラックライト'
            },
            media: [
                'ソーシャルコンテンツ',
                'インフルエンサー',
                'プレスリリース'
            ],
            inquiry: 'INQUIRY TO BUY'
        }
    },
    brandReview: {
        section1: {
            title: {
                pc: 'Bacterial Disinfection',
                mo: 'Bacterial\nDisinfection'
            },
            desc: {
                pc: 'UVC/Aは細菌のDNAおよびRNA構造を破壊します。\nDNA及びRNAが破壊された細菌は、新陳代謝機能が不活性化し、\n増殖力を失って死滅します。',
                mo: 'UVC/Aは細菌のDNAおよびRNA構造を破壊します。\nDNA及びRNAが破壊された細菌は、新陳代謝機能が不活性化し、\n増殖力を失って死滅します。'
            }
        },
        section2: {
            title: 'UV LED',
            items: [{
                title: '99.9% 除菌力',
                desc: '代表的な汚染指標細菌である大腸菌と代表的な食中毒菌である黄色ブドウ球菌の除菌において99.9%の除菌力を発揮します。'
            }, {
                title: '有害化学物質',
                desc: '水銀や有害化学物質を使用しない技術です。ばく露しない限り、人体に無害ですので、安心して使用できます。'
            }, {
                title: '50,000hrの長い寿命',
                desc: 'L50基準、50,000時間の長寿命ですので、製品の交換費用を節減できます。'
            }, {
                title: '低い消費電力',
                desc: 'UV LEDは消費電力低いので、電気代を節減できます'
            }]
        },
        section3: {
            title: 'Applications',
            desc: {
                pc: 'violeds技術は、様々な空間及び製品にUV LEDモジュールを設置し、\n除菌、空気浄化、捕虫、硬化に活用されています。\nまた、幅広いUV波長領域帯に対応できるUV技術を持っていますので、\n産業全般にわたって使用されています。',
                mo: 'violeds技術は、様々な空間及び製品にUV LEDモジュールを設置し、\n除菌、空気浄化、捕虫、硬化に活用されています。\nまた、幅広いUV波長領域帯に対応できるUV技術を持っていますので、\n産業全般にわたって使用されています。'
            }
        }
    },
    technology: {
        title: 'TECHNOLOGY',
        desc: 'ソウルバイオシスとSETi社が共同開発したUV LED技術です。水、空気、表面などの除菌·浄化、消臭のための応用製品に活用できます。\n生活用品や治療、診断目的の生命科学応用機器など幅広く適用可能であり、単純な光触媒結合ではなく、光源一体の光集積度、指向角関連特許を活用した先端技術です。',
        items: [{
            title: '除菌',
            desc: '表面/水/空気除菌のための\nUV LED PKGと除菌モジュール\n(module)を提供しています。',
            buttonLabel: '詳細を見る',
            href: '/jp/tech/sterilization'
        }, {
            title: '空気清浄',
            desc: '独自の光触媒技術を利用して\n空気中の有害物質除去に\n優れた効果を発揮します。',
            buttonLabel: '詳細を見る',
            href: '/jp/tech/deodorization'
        }, {
            title: '捕虫',
            desc: 'UV波長の強さと放射状を調節し、\n飛行性害虫を誘引する\nソリューションを提供します',
            buttonLabel: '詳細を見る',
            href: '/jp/tech/insect-attraction'
        }, {
            title: '硬化',
            desc: '全波長領域のUV LEDを\n生産する世界唯一の会社として、\n最適な硬化ソリューションを提供します。',
            buttonLabel: '詳細を見る',
            href: '/jp/tech/curing'
        }]
    },
    applicationField: {
        title: {
            pc: 'UV APPLICATION FIELD',
            mo: 'UV APPLICATION\nFIELD'
        },
        buttonLabel: '詳細を見る',
        items: ApplicationItems
    },
    media: {
        title: 'PRESS',
        buttons: [{
            title: 'ソーシャルコンテンツ',
            desc: 'MORE >',
            pc: MediaMore03,
            mobile: MediaMoreMobile03,
            link: '/jp/media/social'
        }, {
            title: 'インフルエンサー',
            desc: 'MORE >',
            pc: MediaMore02,
            mobile: MediaMoreMobile02,
            link: '/jp/media/influencer'
        }, {
            title: 'プレスリリース',
            desc: 'MORE >',
            pc: MediaMore01,
            mobile: MediaMoreMobile01,
            link: '/jp/media/news'
        }],
        api: '/media/jp/list'
    }
}