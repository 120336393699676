/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { createScope, map, transformProxies } from './helpers'
import { Fade } from 'react-reveal'
import RoundSlide from '../components/RoundSlide'
import MainMedia from '../components/MainMedia'
import Indicator from '../components/Indicator'
import Head from '../components/Head'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=615ff8923be8a5ac89fead82").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
  { loading: fetch("https://cdnjs.cloudflare.com/ajax/libs/scrollify/1.0.21/jquery.scrollify.min.js").then(body => body.text()), isAsync: false },
  // { loading: Promise.resolve("$(function(){$.scrollify({section:\".af-class-section\",before:function(){$(\"#fullpage\").on(\"scroll touchmove mousewheel\",function(o){return o.preventDefault(),o.stopPropagation(),!1})},after:function(idx){ setTimeout(function(){$(\"#fullpage\").off(\"scroll touchmove mousewheel\")},500)}})});"), isAsync: false },
  { loading: Promise.resolve("$(function(){});"), isAsync: false },
]

let Controller


class IndexView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      block1Show: false,
      block2Show: false,
      block3Show: false,
      block4Show: false,
      block5Show: false,
      block6Show: false,
      current: -1
    }
  }

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  handleScroll = () => {
    let point = window.scrollY + window.innerHeight
    let block1Point = document.getElementById('anim-block1').getBoundingClientRect().top
    let block2Point = document.getElementById('anim-block2').getBoundingClientRect().top
    let block3Point = document.getElementById('anim-block3').getBoundingClientRect().top
    let block4Point = document.getElementById('anim-block4').getBoundingClientRect().top
    let block5Point = document.getElementById('anim-block5').getBoundingClientRect().top
    let block6Point = document.getElementById('anim-block6').getBoundingClientRect().top
    if (point >= block1Point) { this.setState({ block1Show: true }) }
    if (point >= block2Point) { this.setState({ block2Show: true }) }
    if (point >= block3Point) { this.setState({ block3Show: true }) }
    if (point >= block4Point) { this.setState({ block4Show: true }) }
    if (point >= block5Point) { this.setState({ block5Show: true }) }
    if (point >= block6Point) { this.setState({ block6Show: true }) }

    let topPoint = window.scrollY
    let screenHeight = window.innerHeight
    if (topPoint <= screenHeight) {
      this.setState({ current: -1 })
    } else if (topPoint > screenHeight && topPoint <= screenHeight * 9) {
      this.setState({ current: 0 })
    } else if (topPoint > screenHeight * 9 && topPoint <= screenHeight * 10) {
      this.setState({ current: 1 })
    } else if (topPoint > screenHeight * 10 && topPoint <= screenHeight * 11) {
      this.setState({ current: 2 })
    } else if (topPoint > screenHeight * 11 && topPoint <= screenHeight * 12) {
      this.setState({ current: 3 })
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6162cf1ce2968454f8ef00c6'
    htmlEl.dataset['wfSite'] = '615ff8923be8a5ac89fead82'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
    
    window.addEventListener('scroll', this.handleScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false)
  }

  // On Indicator Click
  onIndicatorClick = async (hash) => {
    await this.setState({ current: hash })
    await this.onScrollTop(`#${hash}`)
    window.location.href = `${this.props.currentLocale}#${hash}`
    
  }

  // Scroll Top
  onScrollTop = (id) => {
    $("html, body").animate({
      "scrollTop" : $(id).offset().top
    }, 400, "swing")
  }

  t = this.props.t

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

    }

    const alt = this.t('alt.home', { returnObjects: true })

    return (
      <span>
        {this.props.currentLocale && (
          <Head
            title={'violeds | Main'}
            description={'Violeds technology is the leading innovator in various fields within the next-generation UV market, including sterilization, deodorization, insect attraction and curing.'}
            keyword={'Seoul Viosys,seoul semiconductor,violeds,Violeds,violeds,led,LED,sterilization,deodorization,insect attraction,curing,air purifier,air conditioner,water purifier,air humidifier'}
            lang={this.props.currentLocale}
          />
        )}
        
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/violeds.webflow.css);
          @import url(/css/roundSlide.css);
        ` }} />
        
        <span className="af-view">
          <div className="af-class-body">
            <div id="fullpage" className="af-class-wrapper">
              <div className="af-class-section">
                <div id="topSection" className="af-class-content1">
                  <div className="af-class-scrollcontent">
                    <div className="af-class-videoarea">
                      <video className="pc" src="images/hero_video.mp4" loop autoPlay muted playsInline />
                      <video className="mb" src="images/hero_video_m.mp4" loop autoPlay muted playsInline />
                    </div>
                    <div className="af-class-titlewrapper">
                      
                        <div className="af-class-content">
                          <a href={`${this.props.currentLocale}/showroom`} target="_blank" className="af-class-maingatebutton w-inline-block">
                            <div className="af-class-text-block-3">VIRTUAL SHOWROOM</div>
                          </a>
                        </div>
                      
                    </div>
                  </div>
                  <div id='BrandReview' className="af-class-scrollanim1">
                    <div className="af-class-animarea1">
                      <div className="af-class-animwrapper">
                        <div data-w-id="2d021e2e-a9de-ddc1-4f3c-f199532a48a4" data-animation-type="lottie" data-src="documents/seq1.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="9.175842135436138" data-duration={0} className="af-class-anim1" />
                        <div className="af-class-vnt" />
                      </div>
                        
                        <div id="anim-block1" className="af-class-contentblock1">
                          <Fade bottom duration={1300} delay={0} when={this.state.block1Show}>
                            <h1 className="af-class-heading af-class-lottie1heading">{this.t('brandReview.section1.title.pc')}</h1>
                            <h1 className="af-class-heading af-class-lottie1heading mb">{this.t('brandReview.section1.title.mo')}</h1>
                          </Fade> 
                          <Fade bottom duration={1300} delay={300} when={this.state.block1Show}>
                            <div className="af-class-text-block-5 af-class-lottie1desc">{this.t('brandReview.section1.desc.pc')}</div>
                            <div className="af-class-text-block-5 af-class-lottie1desc mb">{this.t('brandReview.section1.desc.mo')}</div>
                          </Fade>
                          
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='UVLED' className="af-class-section">
                <div id="topSection" className="af-class-content1">
                  <div className="af-class-scrollanim2">
                    <div className="af-class-contentblock2">
                      <Fade bottom duration={1300} delay={0} when={this.state.block2Show}>
                        <h1 className="af-class-heading-2" id="anim-block2">{this.t('brandReview.section2.title')}</h1>
                      </Fade>
                      
                      <ul role="list" className="af-class-content1list w-list-unstyled">
                        <li className="af-class-list-item-5">
                          <Fade bottom duration={1300} delay={200} when={this.state.block2Show}>
                          <img src="images/main_uvled01.png" loading="lazy" alt={alt.uvled[0]} className="af-class-image-2" />
                          </Fade>
                          <Fade bottom duration={1300} delay={500} when={this.state.block2Show}>
                          <h4 className="af-class-heading-3">{this.t('brandReview.section2', { returnObjects: true }).items[0].title}</h4>
                          </Fade>
                          <Fade bottom duration={1300} delay={700} when={this.state.block2Show}>
                          <p className="af-class-paragraph">{this.t('brandReview.section2', { returnObjects: true }).items[0].desc}</p>
                          </Fade>
                        </li>
                        <li className="af-class-list-item-6">
                        <Fade bottom duration={1300} delay={400} when={this.state.block2Show}>
                          <img src="images/main_uvled02.png" loading="lazy" alt={alt.uvled[1]} className="af-class-image-3" />
                          </Fade>
                          <Fade bottom duration={1300} delay={700} when={this.state.block2Show}>
                          <h4 className="af-class-heading-3">{this.t('brandReview.section2', { returnObjects: true }).items[1].title}</h4>
                          </Fade>
                          <Fade bottom duration={1300} delay={900} when={this.state.block2Show}>
                          <p className="af-class-paragraph">{this.t('brandReview.section2', { returnObjects: true }).items[1].desc}</p>
                          </Fade>
                        </li>
                        <li className="af-class-list-item-7">
                        <Fade bottom duration={1300} delay={600} when={this.state.block2Show}>
                          <img src="images/main_uvled03.png" loading="lazy" alt={alt.uvled[2]} className="af-class-image-4" />
                          </Fade>
                          <Fade bottom duration={1300} delay={900} when={this.state.block2Show}>
                          <h4 className="af-class-heading-3">{this.t('brandReview.section2', { returnObjects: true }).items[2].title}</h4>
                          </Fade>
                          <Fade bottom duration={1300} delay={1100} when={this.state.block2Show}>
                          <p className="af-class-paragraph">{this.t('brandReview.section2', { returnObjects: true }).items[2].desc}</p>
                          </Fade>
                        </li>
                        <li className="af-class-list-item-8">
                        <Fade bottom duration={1300} delay={900} when={this.state.block2Show}>
                          <img src="images/main_uvled04.png" loading="lazy" alt={alt.uvled[3]} className="af-class-image-5" />
                          </Fade>
                          <Fade bottom duration={1300} delay={1100} when={this.state.block2Show}>
                          <h4 className="af-class-heading-3">{this.t('brandReview.section2', { returnObjects: true }).items[3].title}</h4>
                          </Fade>
                          <Fade bottom duration={1300} delay={1500} when={this.state.block2Show}>
                          <p className="af-class-paragraph">{this.t('brandReview.section2', { returnObjects: true }).items[3].desc}</p>
                          </Fade>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Application' className="af-class-section">
                <div id="topSection" className="af-class-content1">
                  <div className="af-class-scrollanim3">
                    <div className="af-class-amimarea">
                      <div className="af-class-contentblock3" id="anim-block3">
                        <Fade bottom duration={1300} delay={100} when={this.state.block3Show}>
                          <h1 className="af-class-heading-4">{this.t('brandReview.section3.title')}</h1>
                        </Fade>
                        <Fade bottom duration={1300} delay={400} when={this.state.block3Show}>
                          <div className="af-class-text-block-6">{this.t('brandReview.section3.desc.pc')}</div>
                          <div className="af-class-text-block-6 mb">{this.t('brandReview.section3.desc.mo')}</div>
                        </Fade>
                      </div>
                      <div className="af-class-div-block-8">
                        <div data-w-id="706c4186-c0a3-f3aa-a3b7-cdef96c5a4ff" data-animation-type="lottie" data-src="documents/seq2.json" data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer="svg" data-default-duration="9.175842135436138" data-duration={0} className="af-class-anim2" />
                        <div className="af-class-vnt2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='Technology' className="af-class-section">
                <div className="af-class-content2">
                  <Fade bottom duration={1300} delay={100} when={this.state.block4Show}>
                    <h2 className="af-class-heading-5" id="anim-block4">{this.t('technology.title')}</h2>
                  </Fade>
                  <Fade bottom duration={1300} delay={300} when={this.state.block4Show}>
                    <div className="af-class-text-block-7">{this.t('technology.desc')}</div>
                  </Fade>
                  
                  <ul role="list" className="af-class-list-2 w-list-unstyled">
                    <li className="af-class-list-item-9">
                      <Fade bottom duration={1300} delay={400} when={this.state.block4Show}>
                      <img src="images/main_tech01.png" loading="lazy" alt={alt.tech[0]} className="af-class-image-6" />
                      </Fade>
                      <Fade bottom duration={1300} delay={600} when={this.state.block4Show}>
                      <h3 className="af-class-heading-6">{this.t('technology', { returnObjects: true }).items[0].title}</h3>
                      </Fade>
                      <Fade bottom duration={1300} delay={900} when={this.state.block4Show}>
                      <div className="af-class-text-block-8">{this.t('technology', { returnObjects: true }).items[0].desc}</div>
                      </Fade>
                      <Fade bottom duration={1300} delay={1100} when={this.state.block4Show}>
                      <a href={this.t('technology', { returnObjects: true }).items[0].href} className="af-class-link-block-6 w-inline-block">
                        <div className="af-class-text-block-9">{this.t('technology', { returnObjects: true }).items[0].buttonLabel}</div>
                      </a>
                      </Fade>
                    </li>
                    <li className="af-class-list-item-10">
                      <Fade bottom duration={1300} delay={600} when={this.state.block4Show}>  
                      <img src="images/main_tech02.png" loading="lazy" alt={alt.tech[1]} className="af-class-image-6" />
                      </Fade> 
                      <Fade bottom duration={1300} delay={800} when={this.state.block4Show}> 
                      <h3 className="af-class-heading-6">{this.t('technology', { returnObjects: true }).items[1].title}</h3>
                      </Fade> 
                      <Fade bottom duration={1300} delay={1100} when={this.state.block4Show}> 
                      <div className="af-class-text-block-8">{this.t('technology', { returnObjects: true }).items[1].desc}</div>
                      </Fade> 
                      <Fade bottom duration={1300} delay={1300} when={this.state.block4Show}> 
                      <a href={this.t('technology', { returnObjects: true }).items[1].href} className="af-class-link-block-6 w-inline-block">
                        <div className="af-class-text-block-9">{this.t('technology', { returnObjects: true }).items[1].buttonLabel}</div>
                      </a>
                      </Fade> 
                    </li>
                    <li className="af-class-list-item-11">
                      <Fade bottom duration={1300} delay={800} when={this.state.block4Show}>
                      <img src="images/main_tech03.png" loading="lazy" alt={alt.tech[2]} className="af-class-image-6" />
                      </Fade>
                      <Fade bottom duration={1300} delay={1000} when={this.state.block4Show}>
                      <h3 className="af-class-heading-6">{this.t('technology', { returnObjects: true }).items[2].title}</h3>
                      </Fade>
                      <Fade bottom duration={1300} delay={1300} when={this.state.block4Show}>
                      <div className="af-class-text-block-8">{this.t('technology', { returnObjects: true }).items[2].desc}</div>
                      </Fade>
                      <Fade bottom duration={1300} delay={1500} when={this.state.block4Show}>
                      <a href={this.t('technology', { returnObjects: true }).items[2].href} className="af-class-link-block-6 w-inline-block">
                        <div className="af-class-text-block-9">{this.t('technology', { returnObjects: true }).items[2].buttonLabel}</div>
                      </a>
                      </Fade>
                    </li>
                    <li className="af-class-list-item-12">
                      <Fade bottom duration={1300} delay={1000} when={this.state.block4Show}>
                      <img src="images/main_tech04.png" loading="lazy" alt={alt.tech[3]} className="af-class-image-6" />
                      </Fade>
                      <Fade bottom duration={1300} delay={1200} when={this.state.block4Show}>
                      <h3 className="af-class-heading-6">{this.t('technology', { returnObjects: true }).items[3].title}</h3>
                      </Fade>
                      <Fade bottom duration={1300} delay={1500} when={this.state.block4Show}>
                      <div className="af-class-text-block-8">{this.t('technology', { returnObjects: true }).items[3].desc}</div>
                      </Fade>
                      <Fade bottom duration={1300} delay={1700} when={this.state.block4Show}>
                      <a href={this.t('technology', { returnObjects: true }).items[3].href} className="af-class-link-block-6 w-inline-block">
                        <div className="af-class-text-block-9">{this.t('technology', { returnObjects: true }).items[3].buttonLabel}</div>
                      </a>
                      </Fade>
                    </li>
                  </ul>
                </div>
              </div>
              <div id='UVApplicationField' className="af-class-section">
                <div className="af-class-content3" id="anim-block5">
                  <RoundSlide />
                </div>
              </div>
              <div id='Media' className="af-class-section af-class-pg-page">
                <div className="af-class-content4" id="anim-block6">
                  <div className="af-class-div-block-7">
                    <MainMedia currentLocale={this.props.currentLocale} />
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
        
        <Indicator
          onCircleClick={idx => {this.onIndicatorClick(idx)}}
          current={this.state.current} />
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */