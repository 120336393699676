import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Arrow from '../../images/tech/tech_arrow_right.png'

//-------------------------
// Page
//-------------------------
const TechProduct = ({ data }) => {
    const caution = data.caution
    const readmore = data.readmore
    const contents = data.contents
    const [hoverIdx, setHoverIdx] = useState(-1)
    // const [winWidth, setWinWidth] = useState(null)

    //-------------------------
    // Function
    //-------------------------
    // Mouse Hover
    const handleMouseHover = (p_idx, idx) => {
        const result = calcIdx(p_idx, idx)
        setHoverIdx(result)
    }

    // Idx 계산
    const calcIdx = (p_idx, idx) => {
        return p_idx * 10 + idx
    }

    // clickReadmore
    const clickReadmore = (link) => {
        // window.location.href = link
        window.open(link)
    }

    // Size
    const handleSideSize = () => {
        if (typeof window !== 'undefined') {
            // setWinWidth(window.innerWidth)
        }
    }

    //-------------------------
    // Use Effect
    //-------------------------
    useEffect(() => {
        handleSideSize()
        window.addEventListener('resize', handleSideSize)

        return () => {
            window.addEventListener('resize', handleSideSize)
        }
    }, [])

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Caution>{caution}</Caution>
            {(contents && contents.length) ? contents.map((products, p_idx) => {
                return (
                    <Wrap key={p_idx}>
                        <Title>{products.title}</Title>

                        <Box length={(products.items && products.items.length) ? products.items.length : 0}>
                            {/* <Switch>
                                <Case condition={(products.items.length === 1 || products.items.length === 2) && winWidth > 767}>
                                    <Product />
                                </Case>
                            </Switch> */}

                            {/* Product */}
                            {(products.items && products.items.length) ? products.items.map((item, idx) => {
                                return (
                                    <Product key={idx}>
                                        <ImageWrap img={item}
                                            onMouseEnter={e => handleMouseHover(p_idx, idx)}
                                            onMouseLeave={e => handleMouseHover(0, -1)} >
                                            {(item.image ? <Image src={item.image} alt={item.alt} /> : <div></div>)}

                                            {/* Hover 이미지 */}
                                            {hoverIdx === calcIdx(p_idx, idx) &&
                                                <ImageHover
                                                    id={'tech_prodcut'}
                                                    onClick={e=> clickReadmore(item.link) }>
                                                    <HoverTitle>{readmore}</HoverTitle>
                                                    <HoverImage
                                                        src={Arrow}
                                                        alt={idx} />
                                                </ImageHover>
                                            }
                                        </ImageWrap>
                                        <Name>{item.name}</Name>
                                        <Desc>{item.desc}</Desc>
                                    </Product>
                                )
                            }) : ( <div></div> )}
                            
                            {/* <Switch>
                                <Case condition={products.items.length === 1 && winWidth > 767}>
                                    <Product />
                                </Case>
                            </Switch> */}
                        </Box>
                    </Wrap>
                )
            }) : ( <div></div> )}
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`

//-------------------------
// Product
//-------------------------
const Wrap = styled.div`
    display: relative;
    width: 100%;
    border-radius: 12px;
    background-color: #fafafa;
    margin: 17px 0;
    padding: 12px;

    &:last-child {
        margin-bottom: 0px;
    }

    @media (max-width: 374px) {
        padding: 0px;
    }
`
const Caution = styled.div`
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #222;

    @media (max-width: 767px) {
        text-align: center;
        margin-top: 7px;
    }

    @media (max-width: 567px) {
        font-size: 10px;
    }

    @media (max-width: 374px) {
        margin-top: 24px;
    }
`
const Title = styled.div`
    position: absolute;
    padding: 8px 24px;
    border-radius: 8px;
    background-color: #222;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;

    @media (max-width: 767px) {
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 8px;
    }

    @media (max-width: 567px) {
        text-align: center;
        font-size: 10px;
        padding: 8px 14px;
    }

    @media (max-width: 374px) {
        padding: 8px 10px;
    }

    @media (max-width: 270px) {
        width: 74px;
        padding: 7px 5px;
    }

    @media (max-width: 175px) {
        width: 74px;
    }
`

//-------------------------
// Box
//-------------------------
const Box = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 80px 15px;
    border-radius: 12px;
    ${props=>props.length === 2 && 'padding: 30px 150px 15px;'}

    @media (max-width: 767px) {
        flex-direction: column;
        padding: 30px 20px 15px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    @media (max-width: 374px) {
        padding: 30px 0 15px;
    }
`
const Product = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: 20px 10px;
    min-width: 180px;

    @media (max-width: 767px) {
        &:first-child {
            margin-top: 15px;
        }
    }

    @media (max-width: 374px) {
        min-width: 0;
        padding: 7px 0;
    }
`
const ImageWrap = styled.div`
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: solid 1px #029a45;
    background-color: #fff;

    @media (max-width: 374px) {
        width: 80px;
        height: 80px;
    }
`
const Image = styled.img`
    width: 100px;
    height: 100px;

    @media (max-width: 374px) {
        width: 50px;
        height: 50px;
    }
`
const Name = styled.span`
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #222;
`
const Desc = styled.span`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #aaa;
`

//-------------------------
// Hover
//-------------------------
const ImageHover = styled.div`
    display: flex;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 152px;
    height: 152px;
    border-radius: 76px;
    opacity: 0.8;
    background-color: #222;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    @media (max-width: 374px) {
        left: 1px;
        top: 1px;
        width: 76px;
        height: 76px;
        border-radius: 38px;
    }
`

const HoverTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @media (max-width: 374px) {
        font-size: 10px;
    }
`
const HoverImage = styled.img`
    width: 24px;
    height: 20px;

    @media (max-width: 374px) {
        width: 12px;
        height: 10px;
    }
`

export default TechProduct