import app01 from '../../images/applications/application01@3x.png'
import app02 from '../../images/applications/application02@3x.png'
import app03 from '../../images/applications/application03@3x.png'
import app04 from '../../images/applications/application04@3x.png'
import app05 from '../../images/applications/application05@3x.png'
import app06 from '../../images/applications/application06@3x.png'
import app07 from '../../images/applications/application07@3x.png'
import app08 from '../../images/applications/application08@3x.png'
import app09 from '../../images/applications/application09@3x.png'
import app10 from '../../images/applications/application10@3x.png'
import app11 from '../../images/applications/application11@3x.png'
import app12 from '../../images/applications/application12@3x.png'
import app13 from '../../images/applications/application13@3x.png'
import app14 from '../../images/applications/application14@3x.png'
import app15 from '../../images/applications/application15@3x.png'
import app16 from '../../images/applications/application16@3x.png'
import app17 from '../../images/applications/application17@3x.png'
import app18 from '../../images/applications/application18@3x.png'
import app19 from '../../images/applications/application19@3x.png'
import app20 from '../../images/applications/application20@3x.png'
import app21 from '../../images/applications/application21@3x.png'
import app22 from '../../images/applications/application22@3x.png'

import item01 from '../../images/main/applications/round_on01@2x.png'
import item02 from '../../images/main/applications/round_on02@2x.png'
import item03 from '../../images/main/applications/round_on03@2x.png'
import item04 from '../../images/main/applications/round_on04@2x.png'
import item05 from '../../images/main/applications/round_on05@2x.png'
import item06 from '../../images/main/applications/round_on06@2x.png'
import item07 from '../../images/main/applications/round_on07@2x.png'
import item08 from '../../images/main/applications/round_on08@2x.png'
import item09 from '../../images/main/applications/round_on09@2x.png'
import item10 from '../../images/main/applications/round_on10@2x.png'
import item11 from '../../images/main/applications/round_on11@2x.png'
import item12 from '../../images/main/applications/round_on12@2x.png'
import item13 from '../../images/main/applications/round_on13@2x.png'
import item14 from '../../images/main/applications/round_on14@2x.png'
import item15 from '../../images/main/applications/round_on15@2x.png'
import item16 from '../../images/main/applications/round_on16@2x.png'
import item17 from '../../images/main/applications/round_on17@2x.png'
import item18 from '../../images/main/applications/round_on18@2x.png'
import item19 from '../../images/main/applications/round_on19@2x.png'
import item20 from '../../images/main/applications/round_on20@2x.png'
import item21 from '../../images/main/applications/round_on21@2x.png'
import item22 from '../../images/main/applications/round_on22@2x.png'

import item01_off from '../../images/main/applications/round01_off.png'
import item02_off from '../../images/main/applications/round02_off.png'
import item03_off from '../../images/main/applications/round03_off.png'
import item04_off from '../../images/main/applications/round04_off.png'
import item05_off from '../../images/main/applications/round05_off.png'
import item06_off from '../../images/main/applications/round06_off.png'
import item07_off from '../../images/main/applications/round07_off.png'
import item08_off from '../../images/main/applications/round08_off.png'
import item09_off from '../../images/main/applications/round09_off.png'
import item10_off from '../../images/main/applications/round10_off.png'
import item11_off from '../../images/main/applications/round11_off.png'
import item12_off from '../../images/main/applications/round12_off.png'
import item13_off from '../../images/main/applications/round13_off.png'
import item14_off from '../../images/main/applications/round14_off.png'
import item15_off from '../../images/main/applications/round15_off.png'
import item16_off from '../../images/main/applications/round16_off.png'
import item17_off from '../../images/main/applications/round17_off.png'
import item18_off from '../../images/main/applications/round18_off.png'
import item19_off from '../../images/main/applications/round19_off.png'
import item20_off from '../../images/main/applications/round20_off.png'
import item21_off from '../../images/main/applications/round21_off.png'
import item22_off from '../../images/main/applications/round22_off.png'

const ApplicationItems = {
    header: {
        all: {
            title: 'violeds | APPLICATIONS - All',
            desc: 'New developments in Violeds are ongoing. Stay updated with the fast-growing application case studies for Violeds.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis,applications, application case, success case'
        },
        sterilization: {
            title: 'violeds | APPLICATIONS - Sterilization',
            desc: 'New developments in Violeds are ongoing. Stay updated with the fast-growing application case studies for Violeds.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis,applications, application case, success case, sterilization'
        },
        deodorization: {
            title: 'violeds | APPLICATIONS - Deodorization',
            desc: 'New developments in Violeds are ongoing. Stay updated with the fast-growing application case studies for Violeds.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis,applications, application case, success case, deodorization'
        },
        insectAttraction: {
            title: 'violeds | APPLICATIONS - Insect Attraction',
            desc: 'New developments in Violeds are ongoing. Stay updated with the fast-growing application case studies for Violeds.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis,applications, application case, success case, insect attraction'
        },
        curing: {
            title: 'violeds | APPLICATIONS - Curing',
            desc: 'New developments in Violeds are ongoing. Stay updated with the fast-growing application case studies for Violeds.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis,applications, application case, success case, curing'
        },
    },
    type: ['All Applications', 'Sterilization', 'Deodorization', 'Insect Attraction', 'Curing'],
    techBtn: {
        pc: ['Sterilization tech', 'Deodorization tech', 'Insect-attraction tech', 'Curing tech'],
        mo: ['Sterilization', 'Deodorization', 'Insect-attraction', 'Curing']
    },
    data: [{
        keyword: 'refrigerator',
        type: ['살균', '탈취'],
        alt: 'Refrigerators',
        img: app16,
        mainImg: {
            on: item16,
            off: item16_off
        },
        title: 'Refrigerators',
        desc: 'Contaminants on food surfaces in refrigerators lead to the growth of bacteria that cause food poisoning, such as E. coli and Staphylococcus aureus. Violeds sterilizes and deodorizes 99.9% of bacteria that grow in refrigerators, allowing food to be stored for longer periods.',
        goApplications: '/en/applications?product=refrigerator#refrigerator'
    }, {
        keyword: 'airConditioner',
        type: ['살균'],
        alt: 'Air Conditioners',
        img: app22,
        mainImg: {
            on: item22,
            off: item22_off
        },
        title: 'Air Conditioners',
        desc: "The humidity produced while running an air conditioner leads to the growth of harmful bacteria such as E. coli, Staphylococcus aureus, and Staphylococcus epidermidis in the filter and fan. Violeds uses UVC radiation to disinfect the filter and fan from harmful bacteria.",
        goApplications: '/en/applications?product=airConditioner#airConditioner'
    }, {
        keyword: 'washingMachine',
        type: ['살균'],
        alt: 'Washing Machines',
        img: app02,
        mainImg: {
            on: item02,
            off: item13_off
        },
        title: 'Washing Machines',
        desc: 'After a laundry cycle, the residual humidity in the outer tub of a washing machine can lead to unpleasant odors and the growth of various bacteria and molds. Violeds uses disinfection technology to destroy bacteria in the outer tub and keeps the washing machine drum consistently clean.',
        goApplications: '/en/applications?product=washingMachine#washingMachine'
    }, {
        keyword: 'airpurifier',
        type: ['살균', '탈취'],
        alt: 'Air Purifiers',
        img: app01,
        mainImg: {
            on: item01,
            off: item01_off
        },
        title: 'Air Purifiers',
        desc: 'Bacteria attached to floating droplets are 5 mm in diameter and get caught in the filter, exposing the filter to contamination from sources of infection. Using Violeds in air purifier filter applications demonstrates powerful disinfecting and deodorizing effects.',
        goApplications: '/en/applications?product=airpurifier#airpurifier',
    }, {
        keyword: 'waterPurifier',
        type: ['살균'],
        alt: 'Water Purifiers',
        img: app06,
        mainImg: {
            on: item06,
            off: item02_off
        },
        title: 'Water Purifiers',
        desc: 'It disinfects harmful bacteria that grow due to the presence of bacteria or limescale in stagnant water within the water purifier. The interior components of the water purifier can be disinfected with UV light to maintain a clean and hygienic environment.',
        goApplications: '/en/applications?product=waterPurifier#waterPurifier'
    }, {
        keyword: 'dishwasher',
        type: ['살균', '탈취'],
        alt: 'Dishwashers',
        img: app18,
        mainImg: {
            on: item18,
            off: item18_off
        },
        title: 'Dishwashers',
        desc: "After dishes are washed, leftover food is filtered out through the strainer. However, bacteria from food particles remain even after the strainer is washed. Violeds has a bactericidal function to inhibit the growth of any remaining bacteria and prevent odors.",
        goApplications: '/en/applications?product=dishwasher#dishwasher'
    }, {
        keyword: 'vacuumCleaner',
        type: ['살균'],
        alt: 'Vacuum Cleaners',
        img: app19,
        mainImg: {
            on: item19,
            off: item19_off
        },
        title: 'Vacuum Cleaners',
        desc: 'All kinds of dust and waste are stored inside a vacuum cleaner\'s dust bag, making it the most suitable environment for bacterial growth. Violeds disinfects the bacteria on the dust\'s surface, ensuring a hygienic environment inside the dust bag.',
        goApplications: '/en/applications?product=vacuumCleaner#vacuumCleaner'
    }, {
        keyword: 'steamCloset',
        type: ['탈취'],
        alt: 'Steam Closets',
        img: app20,
        mainImg: {
            on: item20,
            off: item20_off
        },
        title: 'Steam Closets',
        desc: 'Deeply embedded odors in fabrics and clothing can be difficult to eliminate with a stage 1 steaming cycle in a steam closet. A UVA+ photocatalytic filter with Violeds powerfully deodorizes any remaining odors.',
        goApplications: '/en/applications?product=steamCloset#steamCloset'
    }, {
        keyword: 'humidifier',
        type: ['살균'],
        alt: 'Humidifiers',
        img: app21,
        mainImg: {
            on: item21,
            off: item21_off
        },
        title: 'Humidifiers',
        desc: 'The inside of a humidifier will always contain stagnant water, which enables bacterial growth. Violeds radiates UVC into the water reservoir to inhibit the formation of biofilm and to disinfect the bacteria growing inside, enabling convenient humidifier maintenance.',
        goApplications: '/en/applications?product=humidifier#humidifier'
    }, {
        keyword: 'homeAppliance',
        type: [],
        alt: 'Home Appliances',
        img: app17,
        mainImg: {
            on: item17,
            off: item17_off
        },
        title: 'Home Appliances',
        desc: 'The disinfection, deodorization, and insect-attracting functions of Violeds technology in everyday home appliances can help us enjoy daily life without worrying about infection.',
        goApplications: '/en/applications?product=homeAppliance#homeAppliance'
    }, {
        keyword: 'portableAirpurifier',
        type: ['살균'],
        alt: 'Portable Air Purifiers',
        img: app03,
        mainImg: {
            on: item03,
            off: item03_off
        },
        title: 'Portable Air Purifiers',
        desc: 'Bacteria attached to floating droplets are 5 mm in diameter and get caught in the filter, exposing the filter to contamination from sources of infection. Using Violeds in air purifier filter applications demonstrates powerful disinfecting and deodorizing effects, while also being portable.',
        goApplications: '/en/applications?product=portableAirpurifier#portableAirpurifier'
    }, {
        keyword: 'disinfectingSurfaces',
        type: ['살균'],
        alt: 'Disinfecting Surfaces',
        img: app05,
        mainImg: {
            on: item05,
            off: item07_off
        },
        title: 'Disinfecting Surfaces',
        desc: 'Bacteria and viruses on the surfaces of everyday goods, such as face masks, can have fatal effects on the human body. Violeds has a 99.9% sterilization effect and prevents viruses and harmful bacteria from infecting the human body.',
        goApplications: '/en/applications?product=disinfectingSurfaces#disinfectingSurfaces'
    }, {
        keyword: 'bidet',
        type: ['살균'],
        alt: 'Bidets',
        img: app08,
        mainImg: {
            on: item08,
            off: item05_off
        },
        title: 'Bidets',
        desc: 'Various bacteria can easily grow in toilet-integrated bidets, which are especially vulnerable to contamination. The use of UV LEDs on hard-to-clean bidet nozzles and toilet seats can disinfect and kill various bacteria to maintain a pleasant bathroom environment.',
        goApplications: '/en/applications?product=bidet#bidet'
    }, {
        keyword: 'insectTrap',
        type: ['포충'],
        alt: 'Insect Trap',
        img: app09,
        mainImg: {
            on: item09,
            off: item06_off
        },
        title: 'Insect Trap',
        desc: "Pests that transmit fatal viruses, such as mosquitoes, must be eliminated.  Violeds utilizes optimal UVA radiation to attract and capture insects and pests, such as mosquitoes and flies.",
        goApplications: '/en/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'printer',
        type: ['경화'],
        alt: 'Printers',
        img: app11,
        mainImg: {
            on: item11,
            off: item09_off
        },
        title: 'Printers',
        desc: 'Special paper printing uses a method in which temporary hardening occurs by exposing UV ink to UV lights. The ink dries quickly on the special paper with low penetrability, enabling a higher printing quality and clear colors.',
        goApplications: '/en/applications?product=printer#printer'
    }, {
        keyword: 'industrialCuring',
        type: ['경화'],
        alt: 'Industrial Curing',
        img: app12,
        mainImg: {
            on: item12,
            off: item11_off
        },
        title: 'Industrial Curing',
        desc: 'During the manufacture of industrial products, Violeds quickly dries products to enhance their hardness. Applicable to various materials, Violeds technology provides an efficient working environment, with outstanding output quality, speed, and safety.',
        goApplications: '/en/applications?product=industrialCuring#industrialCuring'
    }, {
        keyword: 'nailCuring',
        type: ['경화'],
        alt: 'Nail Curing',
        img: app04,
        mainImg: {
            on: item04,
            off: item10_off
        },
        title: 'Nail Curing',
        desc: 'Violeds technology can enhance the hardness of nail polish by allowing it to dry quickly. The quick hardening action is effective in nail art and facilitates long-term nail care to maintain healthy nails.',
        goApplications: '/en/applications?product=nailCuring#nailCuring'
    }, {
        keyword: 'counterfeitDetection',
        type: [],
        alt: 'Counterfeit Detection',
        img: app10,
        mainImg: {
            on: item10,
            off: item08_off
        },
        title: 'Counterfeit Detection',
        desc: 'Violeds technology is used by several institutions to identify counterfeit banknotes. Illuminating UV light on the specially printed identification marks on paper currencies allow for easy detection of forgery.',
        goApplications: '/en/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'medicalDevice',
        type: [],
        alt: 'Medical Devices',
        img: app14,
        mainImg: {
            on: item14,
            off: item14_off
        },
        title: 'Medical Devices',
        desc: 'Violeds is also utilized in medical devices in various medical fields, such as skin treatment devices for vitiligo, implant devices, and tanning machines, providing a safe and reliable medical environment.',
        goApplications: '/en/applications?product=medicalDevice#medicalDevice'
    }, {
        keyword: 'blackLightBulb',
        type: [],
        alt: 'Black Light Bulbs',
        img: app15,
        mainImg: {
            on: item15,
            off: item15_off
        },
        title: 'Black Light Bulbs',
        desc: 'The technological abilities of Violeds have diverse applications in locations that use LEDs. From stage lighting in entertainment to laboratory testing devices (nondestructive testing, substance and particle testing), Violeds always creates an optimal environment across several fields.',
        goApplications: '/en/applications?product=blackLightBulb#blackLightBulb'
    }]
}

export default ApplicationItems