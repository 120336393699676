import styled from 'styled-components';
import React, { useState, useEffect } from 'react'
import moment from 'moment'


const MediaList = ({ label, data, ...props }) => {

    const [on, setOn] = useState(false)

    return (
        <Wrapper onMouseEnter={() => setOn(true)} onMouseLeave={() => setOn(false)}>
            <MediaBox id={'media_item'} on={on} href={props.location.pathname == `${props.currentLocale}/media` ? `${props.currentLocale}/media/${data.mediaIdx}?category=all` : `${props.currentLocale}/media/${data.mediaIdx}`}>
                <Thumbs img={data.thumbnail} />
                <MediaTxt>
                    <MediaTxtTop>
                        <MediaType on={on}>{label}</MediaType>
                        <MediaDate on={on}>{moment(data.createdAt).format('YYYY. MM. DD')}</MediaDate>
                    </MediaTxtTop>
                    <MediaTitle on={on}>{data.title}</MediaTitle>
                    <MediaDesc on={on}>{data.summary}</MediaDesc>
                </MediaTxt>
            </MediaBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const MediaBox = styled.a`
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 28px 32px;
    border: 1px solid #d0d0d0;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: ${props => props.on ? '#000' : '#fff'};
    transition: all .3s;


    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 12px;
    }
`

const Thumbs = styled.div`
    width: 320px;
    height: 180px;
    background: url(${props => props.img}) no-repeat center;
    ${props => props.img == null ? 'background-color: #e6e6e6' : null};
    background-size: contain;
    background-color: #e6e6e6;
    transition: all .3s;

    @media screen and (max-width: 767px) {
        width: 100%;
        height: 0;
        padding-top: calc( 179 / 319 * 100% );
    }
`

const MediaTxt = styled.div`
    width: calc(100% - 320px);
    padding-left: 20px;

    @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
    }
`

const MediaTxtTop = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        padding-top: 12px;
    }
`

const MediaType = styled.div`
    font-size: 14px;
    color: ${props => props.on ? '#fff' : '#8f0a7c'};
    font-weight: 500;
    transition: all .3s;

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`

const MediaDate = styled.div`
    font-size: 14px;
    color: ${props => props.on ? '#fff' : '#aaa'};
    font-weight: bold;
    transition: all .3s;
    
    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
`

const MediaTitle = styled.div`
    padding: 20px 0;
    font-size: 16px;
    color: ${props => props.on ? '#fff' : '#222'};
    font-weight: bold;
    line-height: 1.5;
    transition: all .3s;

    @media screen and (max-width: 767px) {
        padding: 15px 0 12px;
        font-size: 14px;
    }
`

const MediaDesc = styled.div`
    font-size: 14px;
    color: ${props => props.on ? '#fff' : '#222'};
    line-height: 1.57;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; 
    -webkit-box-orient: vertical;
    transition: all .3s;
`

export default MediaList