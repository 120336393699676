import React from 'react'
import styled from 'styled-components'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import TechMain from '../components/TechMain'
import Tabs from '../components/Tabs'
import Banner from '../components/Banner'
import Head from '../components/Head'
import { useTranslation } from 'react-i18next'

//-------------------------
// Page
//-------------------------
const Tech = ({ ...props }) => {
    const { t } = useTranslation('tech')
    let TechHeader = t('main', { returnObjects: true })
    let TechItems = []
    TechItems.push(t('sterilization', { returnObjects: true }))
    TechItems.push(t('deodorization', { returnObjects: true }))
    TechItems.push(t('insectAttraction', { returnObjects: true }))
    TechItems.push(t('curing', { returnObjects: true }))

    const tabItems = [{
        url: `${props.currentLocale}/tech/sterilization`, 
        title: TechHeader.tab[0]
    }, {
        url: `${props.currentLocale}/tech/deodorization`, 
        title: TechHeader.tab[1]
    }, {
        url: `${props.currentLocale}/tech/insect-attraction`, 
        title: TechHeader.tab[2]
    }, {
        url: `${props.currentLocale}/tech/curing`, 
        title: TechHeader.tab[3]
    }]

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Banner type='tech' />
            <Inner>
                <Tabs tabItems={tabItems} type={'tech'} />
                <Switch>
                    <Route path={`${props.currentLocale}/tech/sterilization`}>
                        <Head
                            title={TechHeader.head[0].title}
                            description={TechHeader.head[0].desc}
                            keyword={TechHeader.head[0].keyword}
                            lang={props.currentLocale} />
                        <TechMain data={TechItems[0]} />
                    </Route>
                    <Route path={`${props.currentLocale}/tech/deodorization`}>
                        <Head
                            title={TechHeader.head[1].title}
                            description={TechHeader.head[1].desc}
                            keyword={TechHeader.head[1].keyword}
                            lang={props.currentLocale} />
                        <TechMain data={TechItems[1]} />
                    </Route>
                    <Route path={`${props.currentLocale}/tech/insect-attraction`}>
                        <Head
                            title={TechHeader.head[2].title}
                            description={TechHeader.head[2].desc}
                            keyword={TechHeader.head[2].keyword}
                            lang={props.currentLocale} />
                        <TechMain data={TechItems[2]} />
                    </Route>
                    <Route path={`${props.currentLocale}/tech/curing`}>
                        <Head
                            title={TechHeader.head[3].title}
                            description={TechHeader.head[3].desc}
                            keyword={TechHeader.head[3].keyword}
                            lang={props.currentLocale} />
                        <TechMain data={TechItems[3]} />
                    </Route>
                    <Route path={`${props.currentLocale}/tech`} exact>
                        <Head
                            title={TechHeader.head[0].title}
                            description={TechHeader.head[0].desc}
                            keyword={TechHeader.head[0].keyword} />
                        <Redirect to={`${props.currentLocale}/tech/sterilization`} />
                    </Route>
                    <Route path={"*"}>
                        <Redirect to={`${props.currentLocale}/tech/sterilization`} />
                    </Route>
                </Switch>
            </Inner>
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`
const Inner = styled.div`
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto 140px;

    @media (max-width: 767px) {
        padding: 0 0 30px 0;
    }
`
export default withRouter(Tech)